@charset "UTF-8";

.custom-control-color .custom-control-label-active::after {
    background-color: rgba(0,0,0,.05) !important;
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3e%3cpath fill='none' stroke='%23fff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' d='M9 1L3.5 7L1 4.27273'/%3e%3c/svg%3e") !important
    
}

@media (min-width: 1250px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1250px;
    }
}
*{
    outline: none;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 20 15' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M20 0H0V1.75561H20V0Z' fill='%23111'/%3e%3cpath d='M20 6.5685H0V8.32412H20V6.5685Z' fill='%23111'/%3e%3cpath d='M20 13.2437H0V15H20V13.2437Z' fill='%23111'/%3e%3c/svg%3e");    
}

div.toast-container{
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    right:0px;    
    width: 100vw;
    top:0px;
    z-index:-2;
    padding:15px;
}

div.toast-message{
    background-color: #1f1f1f !important;

    height: 100px;    

    margin-bottom: 10px;
    z-index: 9999;
    border: 1px solid white;
    color:white;
    position: fixed;    
    width: calc(100% - 20px);
    right: 10px;
    bottom: 10px;
    padding:15px;

}

div.toast-message.toast-error{
    background-color:#ff6f61!important;
}

div.toast-message.toast-success{
    background-color:#41d3c0 !important;
}

.card-lg .card-body,
.card-lg .card-footer{
    padding: 1.2rem;
}

div.toast-message *{
    color:white;
}

@media (min-width: 600px){
    div.toast-message{
        width: 400px;
    }
}

@media (min-width: 1250px){
    div#navbarFashionBottomCollapse ul.navbar-nav li.nav-item:not(:first-child){
        border-left: 1px solid #ccc;
    }
}

.bold{
    font-weight: 600;
}

section.home__section:nth-child(even){
    background-color: rgb(255 236 233);
}

section.home__section:nth-child(even) .card-body,
section.home__section:nth-child(even) .card-footer{
    background-color: rgb(255 236 233) !important;
}

.custom-control-img .custom-control-label.active{
    border-bottom: 2px solid black;
    opacity: 1;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.custom-control-size .custom-control-label.disabled{
    text-decoration: line-through;
    background-color: #f5f5f5;
    color:#a6a6a6;
}

.custom-control-size .custom-control-label.active{
    border: 1px solid black;
}

.custom-control-img .custom-control-label {
    width: 40px;
    opacity: .6;
}

.custom-control-images{
    opacity: .6;
}

.custom-control-images.active{
    opacity: 1;
    border-bottom: 1px solid black;


    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}


a.look-product-tag{
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: black;
    opacity: .3;
    padding-left: 10px;
    padding-right: 10px;
}

a.look-product-tag:before {
    content: "";
    position: absolute;
    left: -12px;
    bottom: 0;
    width: 0;
    height: 0;
    border-right: 12px solid black;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
}

a.look-product-tag.active{
    color: black !important;
    background-color: #ff6f61 !important;
}

a.look-product-tag.active:before{
    border-right: 12px solid #ff6f61 !important;
}

.look-product-cover:hover a.look-product-tag{
    opacity: 1;
}

.nowrap{
    white-space: nowrap;
}

.alice-carousel__dots-item:hover, .alice-carousel__dots-item.__active {
    background-color: #ff6f61!important
}

.alice-carousel__dots-item{
    background-color: #fda69d!important
}


.product-page-section{
    padding-top: 3rem !important;
    padding-right: 5rem;
}

@media only screen and (max-width: 768px) {

    .product-page-section{
        padding: 0 !important;   
    }

}

.min-height-container{
    min-height: 900px;
}

.custom-control-label.disabled{
    color:red  !important;
    border: 1px solid red  !important;
    text-decoration-color: red !important;
    opacity: .5;
}

.custom-control-label2{
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.dev-debug{
    font-family: 'Courier New', Courier, monospace;
    background-color:#f5f4f4;
    color: #333;
    padding: 20px;
}

.dev-debug p{
    line-height: 12pt;
    margin: 0px;
}


a.nav-link i{
    font-size: 25px;
}



.navbar-fashion .navbar-form .btn, .navbar-fashion .navbar-form .form-control {
    height: 4rem;
    background-color:white;
    color: #1f1f1f!important;
    border-top:1px solid #1f1f1f!important;
    border-bottom:1px solid #1f1f1f!important;
    border: 0;
}
.navbar-fashion .navbar-form .btn, .navbar-fashion .navbar-form .form-control::placeholder{
    color: #5f5f5f!important;
}

.navbar-fashion .navbar-form .btn:focus, .navbar-fashion .navbar-form .btn:hover {
    background-color: #eee;
}

.navbar-fashion.navbar-dark .nav-link:hover{
    color:#ff6f61;
}

.navbar-fashion.navbar-dark .nav-link:focus{
    color:#f83c2b;
}

.text-gray-300[href]:hover{
    color:#ff6f61!important;
}

.btn-red{
    background-color: #ff6f61!important;
    border-color: #ff6f61!important;
    color:white;
}


@media only screen and (max-width: 768px){

    .half-width-sm{
        width:50%;
        margin: 5px 0px !important;
    }

}

.btn-blue{
    background-color: #41c6d3!important;
    border-color: #41c6d3!important;
    color:white;
}

.btn-blue:hover{
    background-color: white!important;
    border-color: #41c6d3!important;
    color:#41c6d3;
}

.btn-red:hover{
    background-color: white!important;
    border-color: #ff6f61!important;
    color:#ff6f61;
}

.btn-outline-red{
    color:#ff6f61;
    border-color:#ff6f61;
}

.btn-outline-red:hover{
    color:#fff;
    background-color:#ff6f61;
    border-color:#ff6f61
}

.btn-green{
    background-color: #41d3c0 !important;
    border-color: #41d3c0 !important;
    color:white;
}

.btn-green:hover{
    background-color: #5bebd7 !important;
    border-color: #41d3c0 !important;
    color:white;
}

.btn-info{color:#fff;background-color:#3b86ff;border-color:#3b86ff}
.btn-info:hover{color:#fff;background-color:#fff;border-color:#3b86ff}


.alice-carousel__next-btn {
    position: absolute;
    right: 0px;
    width: 80px !important;
    padding: 10px !important;
    background-color: white;
    top: 200px;
}

.alice-carousel__next-btn::before {
    content: "\e930";
}

.alice-carousel__prev-btn {
    position: absolute;
    left: 0px;
    width: 80px !important;
    padding: 10px !important;
    background-color: white;
    top: 200px;
}

.alice-carousel__prev-btn::before {
    content: "\e92f";
}


.login-modal {
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    height: 430px;
}

.customer-finder-modal{
    padding: '10px';
    width: '500px'; 
    height: 'auto';
    min-height: 'auto';
    background-color: 'white';
}

.catalog_modal{
    padding: '10px';
    width: '300px'; 
    height: 'auto';
    min-height: 'auto';
    background-color: 'white';
}

ul.catalog_list{
    list-style: none;
    padding:0px;
    width:100%;
}

ul.catalog_list li{
    height: 50px;
}

ul.catalog_list li input[type="checkbox"]{    
    margin:15px;
}

ul.catalog_list li:hover{
    background-color: rgb(65 211 192 / 41%);
}

@media only screen and (max-width: 768px){
    .login-modal{
        position: fixed;
        width:100%;
        height: 100%;
        top:0px;
        left:0px;
        overflow-y: scroll;
        margin:0px;
        padding:0px;
    }

    .customer-finder-modal,
    .catalog_modal{
        position: fixed;
        width:100%;
        height: 100%;
        top:0px;
        left:0px;
        overflow-y: scroll;
        margin:0px;
        padding:0px;
    }

    .navbar-customer-finder{
        margin-top:1em;
        width: 100%;
    }
}

.btn-sm-rounded{
    padding: 0px;
    height: 35px;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
}