
.carousel-container{
    position: relative;
}

.category-item{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.category-item h1{
    font-size: 1.2rem;
}


.category-item-image{
    border: 1px solid #000000a2;
    border-radius: 100%;
    width: 108px;
    height: 108px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.category-item-image img{
    width: 106px;
    height:106px;
    object-fit: cover;
    border-radius: 100%;
    background-color: rgb(228, 228, 228);
}

.category-item-image::before{
    content: ' ';
    position: absolute;

    border: 3px solid #fff;

    width: 104px;
    height: 104px;
    border-radius: 100%;
}

.category-item-image-hover{
    position: absolute;
    background-color: #000000d5;
    color: white;
    
    width: 106px;
    height: 106px;
    border-radius: 100%;

    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.2s;
}

.category-item-image-hover:hover{
    opacity: 1;
}

.category-item-image-full-hover{
    position: absolute;
    background-color: #000000d5;
    color: white;
    
    width: 374px;
    height: 250px;
    /* object-fit: cover;
    padding: 1rem; */

    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.2s;
}

.category-item-image-full-hover:hover{
    opacity: 1;
}

@media only screen and (max-width:600px)
{
    /* .category-item {
        width: px;
    } */

    .category-item-image {
        width: 64px;
        height: 64px;
    }

    .category-item-image img{
        width: 62px;
        height: 62px;
    }

    .category-item-image::before{
        width: 60px;
        height: 60px;

    }

    .category-item-image-hover{
        width: 62px;
        height: 62px;
    }

    .category-item-image-full-hover{
        width: 224px;
        height: 150px;
    }   

    .category-item h1{
        font-size: 0.9rem;
    }
}



/* BOTÕES NEXT E PREVIOUS CARROSSEL */
.alice-carousel__prev-btn, .alice-carousel__next-btn{
    position: absolute;
    top: 0;
}

.alice-carousel-custom-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 0;

    position: absolute;
    width: calc(100% - 2rem);

    top: calc(50px - 1.5rem);
}

.alice-carousel-custom-buttons button{
    background-color: #ffffff;
    box-shadow: 0px 3px 0px 0px #dddddd;
    border: unset;
    border-radius: 100%;

    width: 3rem;
    height: 3rem;

    font-size: 1.8rem;
    outline: unset;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: background 0.2s;
}

.alice-carousel-custom-buttons button:hover{
    background-color: #f5f5f5;
}


.alice-carousel-custom-buttons button:active{
    background-color: #ebebeb;
}

.alice-carousel-custom-buttons .btn-slider{
    background-color: hsl(0deg 0% 100% / 78%);
    box-shadow: none;
    transition: background 0.3s;
}

.alice-carousel-custom-buttons .btn-slider:hover{
    background-color:rgba(255, 255, 255, 1);
}


.carousel-banner-container{
    padding: 0.5rem;
}

.carousel-banner-container img{
    padding: 0;
    width: 100%;
    max-height: 80vh;
    object-fit:contain;
    /* backgroundColor: "hsla(136, 3%, 25%, 0.75)" */
}

