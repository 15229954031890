@charset "UTF-8";

/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #3e3e3e;
    --gray-dark: #2b2b2b;
    --primary: #ff6f61;
    --secondary: #a6a6a6;
    --success: #28bb74;
    --info: #3b86ff;
    --warning: #ff9736;
    --danger: #ff6f61;
    --light: #f5f5f5;
    --dark: #1f1f1f;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,
::after,
::before {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(17, 17, 17, 0)
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

body {
    margin: 0;
    font-family: Jost;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #111;
    text-align: left;
    background-color: #fff
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .75rem
}

p {
    margin-top: 0;
    margin-bottom: 1.5rem
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 500
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: 600
}

small {
    font-size: 80%
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #ff6f61;
    text-decoration: none;
    background-color: transparent
}

a:hover {
    color: #ff2915;
    text-decoration: none
}

a:not([href]) {
    color: inherit;
    text-decoration: none
}

a:not([href]):hover {
    color: inherit;
    text-decoration: none
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto
}

figure {
    margin: 0 0 1rem
}

img {
    vertical-align: middle;
    border-style: none
}

svg {
    overflow: hidden;
    vertical-align: middle
}

table {
    border-collapse: collapse
}

caption {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    color: #767676;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

select {
    word-wrap: normal
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none !important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .75rem;
    font-weight: 500;
    line-height: 1.2
}

.h1,
h1 {
    font-size: 2.75rem
}

.h2,
h2 {
    font-size: 2.25rem
}

.h3,
h3 {
    font-size: 2rem
}

.h4,
h4 {
    font-size: 1.75rem
}

.h5,
h5 {
    font-size: 1.5rem
}

.h6,
h6 {
    font-size: 1.25rem
}

.lead {
    font-size: 1.125rem;
    font-weight: 400
}

.display-1 {
    font-size: 6.875rem;
    font-weight: 500;
    line-height: 1.2
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 500;
    line-height: 1.2
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 500;
    line-height: 1.2
}

.display-4 {
    font-size: 3.75rem;
    font-weight: 500;
    line-height: 1.2
}

hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border: 0;
    border-top: 1px solid rgba(17, 17, 17, .1)
}

.small,
small {
    font-size: 80%;
    font-weight: 400
}

.mark,
mark {
    padding: .2em;
    background-color: #fcf8e3
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1.5rem;
    font-size: 1.5rem
}

.blockquote-footer {
    display: block;
    font-size: .9375rem;
    color: #767676
}

.blockquote-footer::before {
    content: "\2014\00A0"
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #bababa;
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .75rem;
    line-height: 1
}

.figure-caption {
    font-size: 90%;
    color: #3e3e3e
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #1f1f1f
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 500
}

pre {
    display: block;
    font-size: 87.5%;
    color: #1f1f1f
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px
    }
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {

    .container,
    .container-sm {
        max-width: 540px
    }
}

@media (min-width:768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 720px
    }
}

@media (min-width:992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px
    }
}

@media (min-width:1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.row-cols-1>* {
    flex: 0 0 100%;
    max-width: 100%
}

.row-cols-2>* {
    flex: 0 0 50%;
    max-width: 50%
}

.row-cols-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.row-cols-4>* {
    flex: 0 0 25%;
    max-width: 25%
}

.row-cols-5>* {
    flex: 0 0 20%;
    max-width: 20%
}

.row-cols-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
}

.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%
}

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%
}

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%
}

.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    order: -1
}

.order-last {
    order: 13
}

.order-0 {
    order: 0
}

.order-1 {
    order: 1
}

.order-2 {
    order: 2
}

.order-3 {
    order: 3
}

.order-4 {
    order: 4
}

.order-5 {
    order: 5
}

.order-6 {
    order: 6
}

.order-7 {
    order: 7
}

.order-8 {
    order: 8
}

.order-9 {
    order: 9
}

.order-10 {
    order: 10
}

.order-11 {
    order: 11
}

.order-12 {
    order: 12
}

.offset-1 {
    margin-left: 8.33333%
}

.offset-2 {
    margin-left: 16.66667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333%
}

.offset-5 {
    margin-left: 41.66667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333%
}

.offset-8 {
    margin-left: 66.66667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333%
}

.offset-11 {
    margin-left: 91.66667%
}

@media (min-width:576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-sm-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-sm-first {
        order: -1
    }

    .order-sm-last {
        order: 13
    }

    .order-sm-0 {
        order: 0
    }

    .order-sm-1 {
        order: 1
    }

    .order-sm-2 {
        order: 2
    }

    .order-sm-3 {
        order: 3
    }

    .order-sm-4 {
        order: 4
    }

    .order-sm-5 {
        order: 5
    }

    .order-sm-6 {
        order: 6
    }

    .order-sm-7 {
        order: 7
    }

    .order-sm-8 {
        order: 8
    }

    .order-sm-9 {
        order: 9
    }

    .order-sm-10 {
        order: 10
    }

    .order-sm-11 {
        order: 11
    }

    .order-sm-12 {
        order: 12
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333%
    }

    .offset-sm-2 {
        margin-left: 16.66667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333%
    }

    .offset-sm-5 {
        margin-left: 41.66667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333%
    }

    .offset-sm-8 {
        margin-left: 66.66667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333%
    }

    .offset-sm-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-md-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-md-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-md-first {
        order: -1
    }

    .order-md-last {
        order: 13
    }

    .order-md-0 {
        order: 0
    }

    .order-md-1 {
        order: 1
    }

    .order-md-2 {
        order: 2
    }

    .order-md-3 {
        order: 3
    }

    .order-md-4 {
        order: 4
    }

    .order-md-5 {
        order: 5
    }

    .order-md-6 {
        order: 6
    }

    .order-md-7 {
        order: 7
    }

    .order-md-8 {
        order: 8
    }

    .order-md-9 {
        order: 9
    }

    .order-md-10 {
        order: 10
    }

    .order-md-11 {
        order: 11
    }

    .order-md-12 {
        order: 12
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333%
    }

    .offset-md-2 {
        margin-left: 16.66667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333%
    }

    .offset-md-5 {
        margin-left: 41.66667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333%
    }

    .offset-md-8 {
        margin-left: 66.66667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333%
    }

    .offset-md-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-lg-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-lg-first {
        order: -1
    }

    .order-lg-last {
        order: 13
    }

    .order-lg-0 {
        order: 0
    }

    .order-lg-1 {
        order: 1
    }

    .order-lg-2 {
        order: 2
    }

    .order-lg-3 {
        order: 3
    }

    .order-lg-4 {
        order: 4
    }

    .order-lg-5 {
        order: 5
    }

    .order-lg-6 {
        order: 6
    }

    .order-lg-7 {
        order: 7
    }

    .order-lg-8 {
        order: 8
    }

    .order-lg-9 {
        order: 9
    }

    .order-lg-10 {
        order: 10
    }

    .order-lg-11 {
        order: 11
    }

    .order-lg-12 {
        order: 12
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333%
    }

    .offset-lg-2 {
        margin-left: 16.66667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333%
    }

    .offset-lg-5 {
        margin-left: 41.66667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333%
    }

    .offset-lg-8 {
        margin-left: 66.66667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333%
    }

    .offset-lg-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-xl-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xl-first {
        order: -1
    }

    .order-xl-last {
        order: 13
    }

    .order-xl-0 {
        order: 0
    }

    .order-xl-1 {
        order: 1
    }

    .order-xl-2 {
        order: 2
    }

    .order-xl-3 {
        order: 3
    }

    .order-xl-4 {
        order: 4
    }

    .order-xl-5 {
        order: 5
    }

    .order-xl-6 {
        order: 6
    }

    .order-xl-7 {
        order: 7
    }

    .order-xl-8 {
        order: 8
    }

    .order-xl-9 {
        order: 9
    }

    .order-xl-10 {
        order: 10
    }

    .order-xl-11 {
        order: 11
    }

    .order-xl-12 {
        order: 12
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333%
    }

    .offset-xl-2 {
        margin-left: 16.66667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333%
    }

    .offset-xl-5 {
        margin-left: 41.66667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333%
    }

    .offset-xl-8 {
        margin-left: 66.66667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333%
    }

    .offset-xl-11 {
        margin-left: 91.66667%
    }
}

.table {
    width: 100%;
    margin-bottom: 1.5rem;
    color: #111
}

.table td,
.table th {
    padding: 1.5rem;
    vertical-align: top;
    border-top: 1px solid #e5e5e5
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e5e5e5
}

.table tbody+tbody {
    border-top: 2px solid #e5e5e5
}

.table-sm td,
.table-sm th {
    padding: 1.25rem
}

.table-bordered {
    border: 1px solid #e5e5e5
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #e5e5e5
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px
}

.table-borderless tbody+tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
    border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(17, 17, 17, .05)
}

.table-hover tbody tr:hover {
    color: #111;
    background-color: #f5f5f5
}

.table-primary,
.table-primary>td,
.table-primary>th {
    background-color: #ffd7d3
}

.table-primary tbody+tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
    border-color: #ffb4ad
}

.table-hover .table-primary:hover {
    background-color: #ffc0ba
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #ffc0ba
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
    background-color: #e6e6e6
}

.table-secondary tbody+tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
    border-color: #d1d1d1
}

.table-hover .table-secondary:hover {
    background-color: #d9d9d9
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #d9d9d9
}

.table-success,
.table-success>td,
.table-success>th {
    background-color: #c3ecd8
}

.table-success tbody+tbody,
.table-success td,
.table-success th,
.table-success thead th {
    border-color: #8fdcb7
}

.table-hover .table-success:hover {
    background-color: #b0e6cb
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b0e6cb
}

.table-info,
.table-info>td,
.table-info>th {
    background-color: #c8ddff
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
    border-color: #99c0ff
}

.table-hover .table-info:hover {
    background-color: #afcdff
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #afcdff
}

.table-warning,
.table-warning>td,
.table-warning>th {
    background-color: #ffe2c7
}

.table-warning tbody+tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
    border-color: #ffc996
}

.table-hover .table-warning:hover {
    background-color: #ffd5ae
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffd5ae
}

.table-danger,
.table-danger>td,
.table-danger>th {
    background-color: #ffd7d3
}

.table-danger tbody+tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
    border-color: #ffb4ad
}

.table-hover .table-danger:hover {
    background-color: #ffc0ba
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #ffc0ba
}

.table-light,
.table-light>td,
.table-light>th {
    background-color: #fcfcfc
}

.table-light tbody+tbody,
.table-light td,
.table-light th,
.table-light thead th {
    border-color: #fafafa
}

.table-hover .table-light:hover {
    background-color: #efefef
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #efefef
}

.table-dark,
.table-dark>td,
.table-dark>th {
    background-color: silver
}

.table-dark tbody+tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #8b8b8b
}

.table-hover .table-dark:hover {
    background-color: #b3b3b3
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b3b3b3
}

.table-active,
.table-active>td,
.table-active>th {
    background-color: #f5f5f5
}

.table-hover .table-active:hover {
    background-color: #e8e8e8
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: #e8e8e8
}

.table .thead-dark th {
    color: #fff;
    background-color: #2b2b2b;
    border-color: #3e3e3e
}

.table .thead-light th {
    color: #303030;
    background-color: #d8d8d8;
    border-color: #e5e5e5
}

.table-dark {
    color: #fff;
    background-color: #2b2b2b
}

.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #3e3e3e
}

.table-dark.table-bordered {
    border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, .05)
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, .075)
}

@media (max-width:575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-sm>.table-bordered {
        border: 0
    }
}

@media (max-width:767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-md>.table-bordered {
        border: 0
    }
}

@media (max-width:991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-lg>.table-bordered {
        border: 0
    }
}

@media (max-width:1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-xl>.table-bordered {
        border: 0
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

.table-responsive>.table-bordered {
    border: 0
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.75rem + 2px);
    padding: .875rem 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #111;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e5e5e5;
    border-radius: 0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #111
}

.form-control:focus {
    color: #111;
    background-color: #fff;
    border-color: #1f1f1f;
    outline: 0;
    box-shadow: none
}

.form-control::-webkit-input-placeholder {
    color: #767676;
    opacity: 1
}

.form-control::-moz-placeholder {
    color: #767676;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #767676;
    opacity: 1
}

.form-control::-ms-input-placeholder {
    color: #767676;
    opacity: 1
}

.form-control::placeholder {
    color: #767676;
    opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #d8d8d8;
    opacity: 1
}

select.form-control:focus::-ms-value {
    color: #111;
    background-color: #fff
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    padding-top: calc(.875rem + 1px);
    padding-bottom: calc(.875rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(1.0625rem + 1px);
    padding-bottom: calc(1.0625rem + 1px);
    font-size: 1rem;
    line-height: 1.5
}

.col-form-label-sm {
    padding-top: calc(.8125rem + 1px);
    padding-bottom: calc(.8125rem + 1px);
    font-size: .9375rem;
    line-height: 1.5
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .875rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #111;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    height: calc(1.5em + 1.625rem + 2px);
    padding: .8125rem 1.25rem;
    font-size: .9375rem;
    line-height: 1.5
}

.form-control-lg {
    height: calc(1.5em + 2.125rem + 2px);
    padding: 1.0625rem 1.875rem;
    font-size: 1rem;
    line-height: 1.5
}

select.form-control[multiple],
select.form-control[size] {
    height: auto
}

textarea.form-control {
    height: auto
}

.form-group {
    margin-bottom: 1.5rem
}

.form-text {
    display: block;
    margin-top: .25rem
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px
}

.form-row>.col,
.form-row>[class*=col-] {
    padding-right: 10px;
    padding-left: 10px
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem
}

.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #767676
}

.form-check-label {
    margin-bottom: 0
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #28bb74
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(40, 187, 116, .9)
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: #28bb74;
    padding-right: calc(1.5em + 1.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328bb74' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .4375rem) center;
    background-size: calc(.75em + .875rem) calc(.75em + .875rem)
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #28bb74;
    box-shadow: 0 0 0 0 rgba(40, 187, 116, .25)
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + 1.75rem);
    background-position: top calc(.375em + .4375rem) right calc(.375em + .4375rem)
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
    border-color: #28bb74;
    padding-right: calc(.75em + 4.8125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23525252' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e") no-repeat right 1.5rem center/1rem 1rem, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328bb74' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 3.5rem/calc(.75em + .875rem) calc(.75em + .875rem)
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
    border-color: #28bb74;
    box-shadow: 0 0 0 0 rgba(40, 187, 116, .25)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #28bb74
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
    display: block
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
    color: #28bb74
}

.custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before {
    border-color: #28bb74
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
    border-color: #40d68e;
    background-color: #40d68e
}

.custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0 rgba(40, 187, 116, .25)
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #28bb74
}

.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-file-input:valid~.custom-file-label {
    border-color: #28bb74
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
    border-color: #28bb74;
    box-shadow: 0 0 0 0 rgba(40, 187, 116, .25)
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #ff6f61
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(255, 111, 97, .9)
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #ff6f61;
    padding-right: calc(1.5em + 1.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff6f61' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff6f61' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .4375rem) center;
    background-size: calc(.75em + .875rem) calc(.75em + .875rem)
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #ff6f61;
    box-shadow: 0 0 0 0 rgba(255, 111, 97, .25)
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 1.75rem);
    background-position: top calc(.375em + .4375rem) right calc(.375em + .4375rem)
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
    border-color: #ff6f61;
    padding-right: calc(.75em + 4.8125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23525252' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e") no-repeat right 1.5rem center/1rem 1rem, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff6f61' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff6f61' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 3.5rem/calc(.75em + .875rem) calc(.75em + .875rem)
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
    border-color: #ff6f61;
    box-shadow: 0 0 0 0 rgba(255, 111, 97, .25)
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #ff6f61
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
    color: #ff6f61
}

.custom-control-input.is-invalid~.custom-control-label::before,
.was-validated .custom-control-input:invalid~.custom-control-label::before {
    border-color: #ff6f61
}

.custom-control-input.is-invalid:checked~.custom-control-label::before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
    border-color: #ff9d94;
    background-color: #ff9d94
}

.custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0 rgba(255, 111, 97, .25)
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before {
    border-color: #ff6f61
}

.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: #ff6f61
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
    border-color: #ff6f61;
    box-shadow: 0 0 0 0 rgba(255, 111, 97, .25)
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center
}

.form-inline .form-check {
    width: 100%
}

@media (min-width:576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0
    }

    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-plaintext {
        display: inline-block
    }

    .form-inline .custom-select,
    .form-inline .input-group {
        width: auto
    }

    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0
    }

    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0
    }

    .form-inline .custom-control {
        align-items: center;
        justify-content: center
    }

    .form-inline .custom-control-label {
        margin-bottom: 0
    }
}

.btn {
    display: inline-block;
    font-weight: 500;
    color: #111;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .875rem 1.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #111;
    text-decoration: none
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none
}

.btn.disabled,
.btn:disabled {
    opacity: .65
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: #ff6f61;
    border-color: #ff6f61
}

.btn-primary:hover {
    color: #fff;
    background-color: #ff4c3b;
    border-color: #ff412e
}

.btn-primary.focus,
.btn-primary:focus {
    color: #fff;
    background-color: #ff4c3b;
    border-color: #ff412e;
    box-shadow: 0 0 0 0 rgba(255, 133, 121, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #ff6f61;
    border-color: #ff6f61
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #ff412e;
    border-color: #ff3521
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(255, 133, 121, .5)
}

.btn-secondary {
    color: #fff;
    background-color: #a6a6a6;
    border-color: #a6a6a6
}

.btn-secondary:hover {
    color: #fff;
    background-color: #939393;
    border-color: #8d8d8d
}

.btn-secondary.focus,
.btn-secondary:focus {
    color: #fff;
    background-color: #939393;
    border-color: #8d8d8d;
    box-shadow: 0 0 0 0 rgba(179, 179, 179, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #a6a6a6;
    border-color: #a6a6a6
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #8d8d8d;
    border-color: #868686
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(179, 179, 179, .5)
}

.btn-success {
    color: #fff;
    background-color: #28bb74;
    border-color: #28bb74
}

.btn-success:hover {
    color: #fff;
    background-color: #219b60;
    border-color: #1f915a
}

.btn-success.focus,
.btn-success:focus {
    color: #fff;
    background-color: #219b60;
    border-color: #1f915a;
    box-shadow: 0 0 0 0 rgba(72, 197, 137, .5)
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #28bb74;
    border-color: #28bb74
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1f915a;
    border-color: #1d8653
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(72, 197, 137, .5)
}

.btn-info {
    color: #fff;
    background-color: #3b86ff;
    border-color: #3b86ff
}

.btn-info:hover {
    color: #fff;
    background-color: #156eff;
    border-color: #0867ff
}

.btn-info.focus,
.btn-info:focus {
    color: #fff;
    background-color: #156eff;
    border-color: #0867ff;
    box-shadow: 0 0 0 0 rgba(88, 152, 255, .5)
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #3b86ff;
    border-color: #3b86ff
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #0867ff;
    border-color: #0060fa
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(88, 152, 255, .5)
}

.btn-warning {
    color: #fff;
    background-color: #ff9736;
    border-color: #ff9736
}

.btn-warning:hover {
    color: #fff;
    background-color: #ff8310;
    border-color: #ff7d03
}

.btn-warning.focus,
.btn-warning:focus {
    color: #fff;
    background-color: #ff8310;
    border-color: #ff7d03;
    box-shadow: 0 0 0 0 rgba(255, 167, 84, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #fff;
    background-color: #ff9736;
    border-color: #ff9736
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ff7d03;
    border-color: #f57600
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(255, 167, 84, .5)
}

.btn-danger {
    color: #fff;
    background-color: #ff6f61;
    border-color: #ff6f61
}

.btn-danger:hover {
    color: #fff;
    background-color: #ff4c3b;
    border-color: #ff412e
}

.btn-danger.focus,
.btn-danger:focus {
    color: #fff;
    background-color: #ff4c3b;
    border-color: #ff412e;
    box-shadow: 0 0 0 0 rgba(255, 133, 121, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #ff6f61;
    border-color: #ff6f61
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff412e;
    border-color: #ff3521
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(255, 133, 121, .5)
}

.btn-light {
    color: #1f1f1f;
    background-color: #f5f5f5;
    border-color: #f5f5f5
}

.btn-light:hover {
    color: #1f1f1f;
    background-color: #e2e2e2;
    border-color: #dcdcdc
}

.btn-light.focus,
.btn-light:focus {
    color: #1f1f1f;
    background-color: #e2e2e2;
    border-color: #dcdcdc;
    box-shadow: 0 0 0 0 rgba(213, 213, 213, .5)
}

.btn-light.disabled,
.btn-light:disabled {
    color: #1f1f1f;
    background-color: #f5f5f5;
    border-color: #f5f5f5
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    color: #1f1f1f;
    background-color: #dcdcdc;
    border-color: #d5d5d5
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(213, 213, 213, .5)
}

.btn-dark {
    color: #fff;
    background-color: #1f1f1f;
    border-color: #1f1f1f
}

.btn-dark:hover {
    color: #fff;
    background-color: #0c0c0c;
    border-color: #060606
}

.btn-dark.focus,
.btn-dark:focus {
    color: #fff;
    background-color: #0c0c0c;
    border-color: #060606;
    box-shadow: 0 0 0 0 rgba(65, 65, 65, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #1f1f1f;
    border-color: #1f1f1f
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #060606;
    border-color: #000
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(65, 65, 65, .5)
}

.btn-outline-primary {
    color: #ff6f61;
    border-color: #ff6f61
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #ff6f61;
    border-color: #ff6f61
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 0 rgba(255, 111, 97, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #ff6f61;
    background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #ff6f61;
    border-color: #ff6f61
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(255, 111, 97, .5)
}

.btn-outline-secondary {
    color: #a6a6a6;
    border-color: #a6a6a6
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #a6a6a6;
    border-color: #a6a6a6
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 0 rgba(166, 166, 166, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #a6a6a6;
    background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #a6a6a6;
    border-color: #a6a6a6
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(166, 166, 166, .5)
}

.btn-outline-success {
    color: #28bb74;
    border-color: #28bb74
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #28bb74;
    border-color: #28bb74
}

.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: 0 0 0 0 rgba(40, 187, 116, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #28bb74;
    background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28bb74;
    border-color: #28bb74
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(40, 187, 116, .5)
}

.btn-outline-info {
    color: #3b86ff;
    border-color: #3b86ff
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #3b86ff;
    border-color: #3b86ff
}

.btn-outline-info.focus,
.btn-outline-info:focus {
    box-shadow: 0 0 0 0 rgba(59, 134, 255, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #3b86ff;
    background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #3b86ff;
    border-color: #3b86ff
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(59, 134, 255, .5)
}

.btn-outline-warning {
    color: #ff9736;
    border-color: #ff9736
}

.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9736;
    border-color: #ff9736
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 0 rgba(255, 151, 54, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ff9736;
    background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ff9736;
    border-color: #ff9736
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(255, 151, 54, .5)
}

.btn-outline-danger {
    color: #ff6f61;
    border-color: #ff6f61
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #ff6f61;
    border-color: #ff6f61
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 0 rgba(255, 111, 97, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #ff6f61;
    background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff6f61;
    border-color: #ff6f61
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(255, 111, 97, .5)
}

.btn-outline-light {
    color: #f5f5f5;
    border-color: #f5f5f5
}

.btn-outline-light:hover {
    color: #1f1f1f;
    background-color: #f5f5f5;
    border-color: #f5f5f5
}

.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: 0 0 0 0 rgba(245, 245, 245, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f5f5f5;
    background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
    color: #1f1f1f;
    background-color: #f5f5f5;
    border-color: #f5f5f5
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(245, 245, 245, .5)
}

.btn-outline-dark {
    color: #1f1f1f;
    border-color: #1f1f1f
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #1f1f1f;
    border-color: #1f1f1f
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 0 rgba(31, 31, 31, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #1f1f1f;
    background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #1f1f1f;
    border-color: #1f1f1f
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(31, 31, 31, .5)
}

.btn-link {
    font-weight: 400;
    color: #ff6f61;
    text-decoration: none
}

.btn-link:hover {
    color: #ff2915;
    text-decoration: none
}

.btn-link.focus,
.btn-link:focus {
    text-decoration: none;
    box-shadow: none
}

.btn-link.disabled,
.btn-link:disabled {
    color: #3e3e3e;
    pointer-events: none
}

.btn-group-lg>.btn,
.btn-lg {
    padding: 1.0625rem 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 0
}

.btn-group-sm>.btn,
.btn-sm {
    padding: .8125rem 1.5rem;
    font-size: .9375rem;
    line-height: 1.40625rem;
    border-radius: 0
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

.fade {
    transition: opacity .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: middle;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 200px;
    padding: 0 0;
    margin: 0 0 0;
    font-size: 1rem;
    color: #111;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e5e5e5
}

.dropdown-menu-left {
    right: auto;
    left: 0
}

.dropdown-menu-right {
    right: 0;
    left: auto
}

@media (min-width:576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-right {
        right: 0;
        left: auto
    }
}

@media (min-width:768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-right {
        right: 0;
        left: auto
    }
}

@media (min-width:992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-right {
        right: 0;
        left: auto
    }
}

@media (min-width:1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-right {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: middle;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: middle;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-toggle::after {
    vertical-align: 0
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: middle;
    content: ""
}

.dropleft .dropdown-toggle::after {
    display: none
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: middle;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top] {
    right: auto;
    bottom: auto
}

.dropdown-divider {
    height: 0;
    margin: .75rem 0;
    overflow: hidden;
    border-top: 1px solid #d8d8d8
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .5rem 1.25rem;
    clear: both;
    font-weight: 400;
    color: #111;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #111;
    text-decoration: none;
    background-color: #f5f5f5
}

.dropdown-item.active,
.dropdown-item:active {
    color: #111;
    text-decoration: none;
    background-color: #f5f5f5
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #3e3e3e;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: 0 1.25rem;
    margin-bottom: 0;
    font-size: .9375rem;
    color: #3e3e3e;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: .5rem 1.25rem;
    color: #111
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    flex: 1 1 auto
}

.btn-group-vertical>.btn:hover,
.btn-group>.btn:hover {
    z-index: 1
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
    margin-left: -1px
}

.dropdown-toggle-split {
    padding-right: 1.3125rem;
    padding-left: 1.3125rem
}

.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
    margin-left: 0
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-right: 1.125rem;
    padding-left: 1.125rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-right: 1.5rem;
    padding-left: 1.5rem
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0
}

.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.form-control {
    margin-left: -1px
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
    z-index: 3
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4
}

.input-group>.custom-file {
    display: flex;
    align-items: center
}

.input-group-append,
.input-group-prepend {
    display: flex
}

.input-group-append .btn,
.input-group-prepend .btn {
    position: relative;
    z-index: 2
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
    z-index: 3
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
    margin-left: -1px
}

.input-group-prepend {
    margin-right: -1px
}

.input-group-append {
    margin-left: -1px
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .875rem 1.5rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #767676;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #e5e5e5
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
    margin-top: 0
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
    height: calc(1.5em + 2.125rem + 2px)
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
    padding: 1.0625rem 1.875rem;
    font-size: 1rem;
    line-height: 1.5
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control:not(textarea) {
    height: calc(1.5em + 1.625rem + 2px)
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    padding: .8125rem 1.25rem;
    font-size: .9375rem;
    line-height: 1.5
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 3.5rem
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 2rem
}

.custom-control-inline {
    display: inline-flex;
    margin-right: .5rem
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1.125rem;
    height: 1.3125rem;
    opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #1f1f1f;
    background-color: #1f1f1f
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #1f1f1f
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #e5e5e5;
    border-color: #e5e5e5
}

.custom-control-input:disabled~.custom-control-label,
.custom-control-input[disabled]~.custom-control-label {
    color: #a6a6a6
}

.custom-control-input:disabled~.custom-control-label::before,
.custom-control-input[disabled]~.custom-control-label::before {
    background-color: #e5e5e5
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    color: #767676;
    vertical-align: top
}

.custom-control-label::before {
    position: absolute;
    top: .1875rem;
    left: -2rem;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    pointer-events: none;
    content: "";
    background-color: #e5e5e5;
    border: #525252 solid 0
}

.custom-control-label::after {
    position: absolute;
    top: .1875rem;
    left: -2rem;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    content: "";
    background: no-repeat 50%/10px 8px
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3e%3cpath fill='none' stroke='%23fff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' d='M9 1L3.5 7L1 4.27273'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: #ff6f61;
    background-color: #ff6f61
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(255, 111, 97, .5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(255, 111, 97, .5)
}

.custom-radio .custom-control-label::before {
    border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(255, 111, 97, .5)
}

.custom-switch {
    padding-left: 3rem
}

.custom-switch .custom-control-label::before {
    left: -3rem;
    width: 2.125rem;
    pointer-events: all;
    border-radius: .5625rem
}

.custom-switch .custom-control-label::after {
    top: .1875rem;
    left: -3rem;
    width: .875rem;
    height: .875rem;
    background-color: #525252;
    border-radius: .5625rem;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .custom-switch .custom-control-label::after {
        transition: none
    }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #e5e5e5;
    transform: translateX(1rem)
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(255, 111, 97, .5)
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 1.75rem + 2px);
    padding: .875rem 3.5rem .875rem 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #111;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23525252' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e") no-repeat right 1.5rem center/1rem 1rem;
    border: 1px solid #e5e5e5;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-select:focus {
    border-color: #1f1f1f;
    outline: 0;
    box-shadow: 0 0 0 0 transparent
}

.custom-select:focus::-ms-value {
    color: #111;
    background-color: #fff
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1.5rem;
    background-image: none
}

.custom-select:disabled {
    color: #3e3e3e;
    background-color: #d8d8d8
}

.custom-select::-ms-expand {
    display: none
}

.custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #111
}

.custom-select-sm {
    height: calc(1.5em + 1.625rem + 2px);
    padding-top: .8125rem;
    padding-bottom: .8125rem;
    padding-left: 1.25rem;
    font-size: .9375rem
}

.custom-select-lg {
    height: calc(1.5em + 2.125rem + 2px);
    padding-top: 1.0625rem;
    padding-bottom: 1.0625rem;
    padding-left: 1.875rem;
    font-size: 1rem
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 1.75rem + 2px);
    margin-bottom: 0
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 1.75rem + 2px);
    margin: 0;
    opacity: 0
}

.custom-file-input:focus~.custom-file-label {
    border-color: #1f1f1f;
    box-shadow: none
}

.custom-file-input:disabled~.custom-file-label,
.custom-file-input[disabled]~.custom-file-label {
    background-color: #d8d8d8
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse"
}

.custom-file-input~.custom-file-label[data-browse]::after {
    content: attr(data-browse)
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 1.75rem + 2px);
    padding: .875rem 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    color: #111;
    background-color: #fff;
    border: 1px solid #e5e5e5
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 1.75rem);
    padding: .875rem 1.5rem;
    line-height: 1.5;
    color: #111;
    content: "Browse";
    background-color: #fff;
    border-left: inherit
}

.custom-range {
    width: 100%;
    height: 1rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-range:focus {
    outline: 0
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, none
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, none
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, none
}

.custom-range::-moz-focus-outer {
    border: 0
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #ff6f61;
    border: 0;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #fff
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #bababa;
    border-color: transparent
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #ff6f61;
    border: 0;
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #fff
}

.custom-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #bababa;
    border-color: transparent
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    background-color: #ff6f61;
    border: 0;
    -ms-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none
    }
}

.custom-range::-ms-thumb:active {
    background-color: #fff
}

.custom-range::-ms-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: .5rem
}

.custom-range::-ms-fill-lower {
    background-color: #bababa
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #bababa
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #525252
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #525252
}

.custom-range:disabled::-moz-range-track {
    cursor: default
}

.custom-range:disabled::-ms-thumb {
    background-color: #525252
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {

    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: none
    }
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none
}

.nav-link.disabled {
    color: #3e3e3e;
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    border-bottom: 0 0 1px 0 solid #e5e5e5
}

.nav-tabs .nav-item {
    margin-bottom: 0 0 1px 0
}

.nav-tabs .nav-link {
    border: 0 0 1px 0 solid transparent
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #d8d8d8 #d8d8d8 #e5e5e5
}

.nav-tabs .nav-link.disabled {
    color: #3e3e3e;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #ff6f61;
    background-color: transparent;
    border-color: #bababa #bababa transparent
}

.nav-tabs .dropdown-menu {
    margin-top: 0 0 1px 0
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #ff6f61
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1rem
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    margin-right: 1rem;
    font-size: 1.75rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: .25rem 0;
    font-size: .9375rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%
}

@media (max-width:575.98px) {

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem
    }

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media (max-width:767.98px) {

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem
    }

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media (max-width:991.98px) {

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem
    }

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

@media (max-width:1199.98px) {

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem
    }

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl {
    padding-right: 0;
    padding-left: 0
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl {
    flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand {
    color: #111
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: #111
}

.navbar-light .navbar-nav .nav-link {
    color: #111
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #ff6f61
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(17, 17, 17, .3)
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: #ff6f61
}

.navbar-light .navbar-toggler {
    color: #111;
    border-color: transparent
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 20 15' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M20 0H0V1.75561H20V0Z' fill='%23111'/%3e%3cpath d='M20 6.5685H0V8.32412H20V6.5685Z' fill='%23111'/%3e%3cpath d='M20 13.2437H0V15H20V13.2437Z' fill='%23111'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
    color: #111
}

.navbar-light .navbar-text a {
    color: #ff6f61
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: #ff6f61
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: #fff;
    border-color: transparent
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 20 15' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M20 0H0V1.75561H20V0Z' fill='%23fff'/%3e%3cpath d='M20 6.5685H0V8.32412H20V6.5685Z' fill='%23fff'/%3e%3cpath d='M20 13.2437H0V15H20V13.2437Z' fill='%23fff'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
    color: #fff
}

.navbar-dark .navbar-text a {
    color: #fff
}

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(17, 17, 17, .125)
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.5rem
}

.card-title {
    margin-bottom: .75rem
}

.card-subtitle {
    margin-top: -.375rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 1.5rem
}

.card-header {
    padding: .75rem 1.5rem;
    margin-bottom: 0;
    background-color: rgba(17, 17, 17, .03);
    border-bottom: 0 solid rgba(17, 17, 17, .125)
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0
}

.card-footer {
    padding: .75rem 1.5rem;
    background-color: rgba(17, 17, 17, .03);
    border-top: 0 solid rgba(17, 17, 17, .125)
}

.card-header-tabs {
    margin-right: -.75rem;
    margin-bottom: -.75rem;
    margin-left: -.75rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -.75rem;
    margin-left: -.75rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.5rem
}

.card-img,
.card-img-bottom,
.card-img-top {
    flex-shrink: 0;
    width: 100%
}

.card-deck .card {
    margin-bottom: 1rem
}

@media (min-width:576px) {
    .card-deck {
        display: flex;
        flex-flow: row wrap;
        margin-right: -1rem;
        margin-left: -1rem
    }

    .card-deck .card {
        flex: 1 0 0%;
        margin-right: 1rem;
        margin-bottom: 0;
        margin-left: 1rem
    }
}

.card-group>.card {
    margin-bottom: 1rem
}

@media (min-width:576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }
}

.card-columns .card {
    margin-bottom: .75rem
}

@media (min-width:576px) {
    .card-columns {
        -moz-column-count: 3;
        column-count: 3;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1
    }

    .card-columns .card {
        display: inline-block;
        width: 100%
    }
}

.accordion>.card {
    overflow: hidden
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0
}

.accordion>.card>.card-header {
    margin-bottom: 0
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1.5rem;
    list-style: none;
    background-color: transparent
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: inherit;
    content: ""
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none
}

.breadcrumb-item.active {
    color: inherit
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none
}

.page-link {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-left: -1px;
    line-height: 1.25;
    color: inherit;
    background-color: transparent;
    border: 1px solid #e5e5e5
}

.page-link:hover {
    z-index: 2;
    color: #ff6f61;
    text-decoration: none;
    background-color: transparent;
    border-color: #e5e5e5
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none
}

.page-item:first-child .page-link {
    margin-left: 0
}

.page-item.active .page-link {
    z-index: 3;
    color: #ff6f61;
    background-color: transparent;
    border-color: #ff6f61
}

.page-item.disabled .page-link {
    color: #3e3e3e;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #bababa
}

.pagination-lg .page-link {
    padding: 1rem 1.5rem;
    font-size: 1.125rem;
    line-height: 1.5
}

.pagination-sm .page-link {
    padding: .5rem 1rem;
    font-size: .9375rem;
    line-height: 1.5
}

.badge {
    display: inline-block;
    padding: .5em 1em;
    font-size: .6875em;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .badge {
        transition: none
    }
}

a.badge:focus,
a.badge:hover {
    text-decoration: none
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.badge-pill {
    padding-right: .6em;
    padding-left: .6em
}

.badge-primary {
    color: #fff;
    background-color: #ff6f61
}

a.badge-primary:focus,
a.badge-primary:hover {
    color: #fff;
    background-color: #ff412e
}

a.badge-primary.focus,
a.badge-primary:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 111, 97, .5)
}

.badge-secondary {
    color: #fff;
    background-color: #a6a6a6
}

a.badge-secondary:focus,
a.badge-secondary:hover {
    color: #fff;
    background-color: #8d8d8d
}

a.badge-secondary.focus,
a.badge-secondary:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(166, 166, 166, .5)
}

.badge-success {
    color: #fff;
    background-color: #28bb74
}

a.badge-success:focus,
a.badge-success:hover {
    color: #fff;
    background-color: #1f915a
}

a.badge-success.focus,
a.badge-success:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(40, 187, 116, .5)
}

.badge-info {
    color: #fff;
    background-color: #3b86ff
}

a.badge-info:focus,
a.badge-info:hover {
    color: #fff;
    background-color: #0867ff
}

a.badge-info.focus,
a.badge-info:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(59, 134, 255, .5)
}

.badge-warning {
    color: #fff;
    background-color: #ff9736
}

a.badge-warning:focus,
a.badge-warning:hover {
    color: #fff;
    background-color: #ff7d03
}

a.badge-warning.focus,
a.badge-warning:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 151, 54, .5)
}

.badge-danger {
    color: #fff;
    background-color: #ff6f61
}

a.badge-danger:focus,
a.badge-danger:hover {
    color: #fff;
    background-color: #ff412e
}

a.badge-danger.focus,
a.badge-danger:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 111, 97, .5)
}

.badge-light {
    color: #1f1f1f;
    background-color: #f5f5f5
}

a.badge-light:focus,
a.badge-light:hover {
    color: #1f1f1f;
    background-color: #dcdcdc
}

a.badge-light.focus,
a.badge-light:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(245, 245, 245, .5)
}

.badge-dark {
    color: #fff;
    background-color: #1f1f1f
}

a.badge-dark:focus,
a.badge-dark:hover {
    color: #fff;
    background-color: #060606
}

a.badge-dark.focus,
a.badge-dark:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(31, 31, 31, .5)
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #d8d8d8
}

@media (min-width:576px) {
    .jumbotron {
        padding: 4rem 2rem
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 500
}

.alert-dismissible {
    padding-right: 4rem
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit
}

.alert-primary {
    color: #8d423b;
    background-color: #ffe2df;
    border-color: #ffd7d3
}

.alert-primary hr {
    border-top-color: #ffc0ba
}

.alert-primary .alert-link {
    color: #69312c
}

.alert-secondary {
    color: #5e5e5e;
    background-color: #ededed;
    border-color: #e6e6e6
}

.alert-secondary hr {
    border-top-color: #d9d9d9
}

.alert-secondary .alert-link {
    color: #454545
}

.alert-success {
    color: #1d6944;
    background-color: #d4f1e3;
    border-color: #c3ecd8
}

.alert-success hr {
    border-top-color: #b0e6cb
}

.alert-success .alert-link {
    color: #12412a
}

.alert-info {
    color: #274e8d;
    background-color: #d8e7ff;
    border-color: #c8ddff
}

.alert-info hr {
    border-top-color: #afcdff
}

.alert-info .alert-link {
    color: #1c3865
}

.alert-warning {
    color: #8d5724;
    background-color: #ffead7;
    border-color: #ffe2c7
}

.alert-warning hr {
    border-top-color: #ffd5ae
}

.alert-warning .alert-link {
    color: #643e1a
}

.alert-danger {
    color: #8d423b;
    background-color: #ffe2df;
    border-color: #ffd7d3
}

.alert-danger hr {
    border-top-color: #ffc0ba
}

.alert-danger .alert-link {
    color: #69312c
}

.alert-light {
    color: #888;
    background-color: #fdfdfd;
    border-color: #fcfcfc
}

.alert-light hr {
    border-top-color: #efefef
}

.alert-light .alert-link {
    color: #6f6f6f
}

.alert-dark {
    color: #181818;
    background-color: #d2d2d2;
    border-color: silver
}

.alert-dark hr {
    border-top-color: #b3b3b3
}

.alert-dark .alert-link {
    color: #000
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 2px 0
    }

    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 2px 0
    }

    to {
        background-position: 0 0
    }
}

.progress {
    display: flex;
    height: 2px;
    overflow: hidden;
    font-size: .75rem;
    background-color: #ddd
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #ff6f61;
    transition: width .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: 2px 2px
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none
    }
}

.media {
    display: flex;
    align-items: flex-start
}

.media-body {
    flex: 1
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0
}

.list-group-item-action {
    width: 100%;
    color: #767676;
    text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    z-index: 1;
    color: #111;
    text-decoration: none;
    background-color: transparent
}

.list-group-item-action:active {
    color: #111;
    background-color: transparent
}

.list-group-item {
    position: relative;
    display: block;
    padding: 1.5rem 1.5rem;
    background-color: transparent;
    border: 1px solid #e5e5e5
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #3e3e3e;
    pointer-events: none;
    background-color: transparent
}

.list-group-item.active {
    z-index: 2;
    color: #111;
    background-color: transparent;
    border-color: #e5e5e5
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px
}

.list-group-horizontal {
    flex-direction: row
}

.list-group-horizontal .list-group-item.active {
    margin-top: 0
}

.list-group-horizontal .list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
}

.list-group-horizontal .list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
}

@media (min-width:576px) {
    .list-group-horizontal-sm {
        flex-direction: row
    }

    .list-group-horizontal-sm .list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-sm .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:768px) {
    .list-group-horizontal-md {
        flex-direction: row
    }

    .list-group-horizontal-md .list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-md .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:992px) {
    .list-group-horizontal-lg {
        flex-direction: row
    }

    .list-group-horizontal-lg .list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-lg .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:1200px) {
    .list-group-horizontal-xl {
        flex-direction: row
    }

    .list-group-horizontal-xl .list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xl .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

.list-group-flush .list-group-item {
    border-right-width: 0;
    border-left-width: 0
}

.list-group-flush .list-group-item:first-child {
    border-top-width: 0
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    color: #8d423b;
    background-color: #ffd7d3
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: #8d423b;
    background-color: #ffc0ba
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #8d423b;
    border-color: #8d423b
}

.list-group-item-secondary {
    color: #5e5e5e;
    background-color: #e6e6e6
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: #5e5e5e;
    background-color: #d9d9d9
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #5e5e5e;
    border-color: #5e5e5e
}

.list-group-item-success {
    color: #1d6944;
    background-color: #c3ecd8
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: #1d6944;
    background-color: #b0e6cb
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #1d6944;
    border-color: #1d6944
}

.list-group-item-info {
    color: #274e8d;
    background-color: #c8ddff
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: #274e8d;
    background-color: #afcdff
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #274e8d;
    border-color: #274e8d
}

.list-group-item-warning {
    color: #8d5724;
    background-color: #ffe2c7
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: #8d5724;
    background-color: #ffd5ae
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #8d5724;
    border-color: #8d5724
}

.list-group-item-danger {
    color: #8d423b;
    background-color: #ffd7d3
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: #8d423b;
    background-color: #ffc0ba
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #8d423b;
    border-color: #8d423b
}

.list-group-item-light {
    color: #888;
    background-color: #fcfcfc
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: #888;
    background-color: #efefef
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #888;
    border-color: #888
}

.list-group-item-dark {
    color: #181818;
    background-color: silver
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: #181818;
    background-color: #b3b3b3
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #181818;
    border-color: #181818
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
    color: #a6a6a6;
    text-shadow: none;
    opacity: .5
}

.close:hover {
    color: #a6a6a6;
    text-decoration: none
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
    opacity: .75
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

a.close.disabled {
    pointer-events: none
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: .875rem;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 .25rem .75rem rgba(17, 17, 17, .1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0
}

.toast:not(:last-child) {
    margin-bottom: .75rem
}

.toast.showing {
    opacity: 1
}

.toast.show {
    display: block;
    opacity: 1
}

.toast.hide {
    display: none
}

.toast-header {
    display: flex;
    align-items: center;
    padding: .25rem .75rem;
    color: #3e3e3e;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, .05)
}

.toast-body {
    padding: .75rem
}

.modal-open {
    overflow: hidden
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -50px)
}

@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
    flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""
}

.modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(17, 17, 17, .2);
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #111
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .3
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #e5e5e5
}

.modal-header .close {
    padding: 1.5rem 2rem;
    margin: -1.5rem -2rem -1.5rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 2rem
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 1.75rem;
    border-top: 1px solid #e5e5e5
}

.modal-footer>* {
    margin: .25rem
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width:576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem)
    }

    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem)
    }

    .modal-sm {
        max-width: 300px
    }
}

@media (min-width:992px) {

    .modal-lg,
    .modal-xl {
        max-width: 800px
    }
}

@media (min-width:1200px) {
    .modal-xl {
        max-width: 1140px
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: Jost;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-top .arrow {
    bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #111
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #111
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
    top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #111
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #111
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #111
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: Jost;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .6875rem;
    word-wrap: break-word;
    background-color: #f5f5f5;
    background-clip: padding-box;
    border: 1px solid transparent
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem;
    margin: 0 .3rem
}

.popover .arrow::after,
.popover .arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-auto[x-placement^=top],
.bs-popover-top {
    margin-bottom: .5rem
}

.bs-popover-auto[x-placement^=top]>.arrow,
.bs-popover-top>.arrow {
    bottom: calc(-.5rem - 1px)
}

.bs-popover-auto[x-placement^=top]>.arrow::before,
.bs-popover-top>.arrow::before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(0, 0, 0, .05)
}

.bs-popover-auto[x-placement^=top]>.arrow::after,
.bs-popover-top>.arrow::after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #f5f5f5
}

.bs-popover-auto[x-placement^=right],
.bs-popover-right {
    margin-left: .5rem
}

.bs-popover-auto[x-placement^=right]>.arrow,
.bs-popover-right>.arrow {
    left: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
}

.bs-popover-auto[x-placement^=right]>.arrow::before,
.bs-popover-right>.arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(0, 0, 0, .05)
}

.bs-popover-auto[x-placement^=right]>.arrow::after,
.bs-popover-right>.arrow::after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #f5f5f5
}

.bs-popover-auto[x-placement^=bottom],
.bs-popover-bottom {
    margin-top: .5rem
}

.bs-popover-auto[x-placement^=bottom]>.arrow,
.bs-popover-bottom>.arrow {
    top: calc(-.5rem - 1px)
}

.bs-popover-auto[x-placement^=bottom]>.arrow::before,
.bs-popover-bottom>.arrow::before {
    top: 0;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: rgba(0, 0, 0, .05)
}

.bs-popover-auto[x-placement^=bottom]>.arrow::after,
.bs-popover-bottom>.arrow::after {
    top: 1px;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #f5f5f5
}

.bs-popover-auto[x-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #ededed
}

.bs-popover-auto[x-placement^=left],
.bs-popover-left {
    margin-right: .5rem
}

.bs-popover-auto[x-placement^=left]>.arrow,
.bs-popover-left>.arrow {
    right: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
}

.bs-popover-auto[x-placement^=left]>.arrow::before,
.bs-popover-left>.arrow::before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(0, 0, 0, .05)
}

.bs-popover-auto[x-placement^=left]>.arrow::after,
.bs-popover-left>.arrow::after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #f5f5f5
}

.popover-header {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #ededed;
    border-bottom: 1px solid #e1e1e1
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: .75rem 1rem;
    color: #525252
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
    transform: translateX(100%)
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {

    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {

    .carousel-control-next,
    .carousel-control-prev {
        transition: none
    }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators li {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center
}

@-webkit-keyframes spinner-border {
    to {
        transform: rotate(360deg)
    }
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 2px solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em
}

@-webkit-keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1
    }
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.bg-primary {
    background-color: #ff6f61 !important
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #ff412e !important
}

.bg-secondary {
    background-color: #a6a6a6 !important
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: #8d8d8d !important
}

.bg-success {
    background-color: #28bb74 !important
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #1f915a !important
}

.bg-info {
    background-color: #3b86ff !important
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #0867ff !important
}

.bg-warning {
    background-color: #ff9736 !important
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #ff7d03 !important
}

.bg-danger {
    background-color: #ff6f61 !important
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #ff412e !important
}

.bg-light {
    background-color: #f5f5f5 !important
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: #dcdcdc !important
}

.bg-dark {
    background-color: #1f1f1f !important
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #060606 !important
}

.bg-white {
    background-color: #fff !important
}

.bg-transparent {
    background-color: transparent !important
}

.border {
    border: 1px solid #e5e5e5 !important
}

.border-top {
    border-top: 1px solid #e5e5e5 !important
}

.border-right {
    border-right: 1px solid #e5e5e5 !important
}

.border-bottom {
    border-bottom: 1px solid #e5e5e5 !important
}

.border-left {
    border-left: 1px solid #e5e5e5 !important
}

.border-0 {
    border: 0 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-right-0 {
    border-right: 0 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-left-0 {
    border-left: 0 !important
}

.border-primary {
    border-color: #ff6f61 !important
}

.border-secondary {
    border-color: #a6a6a6 !important
}

.border-success {
    border-color: #28bb74 !important
}

.border-info {
    border-color: #3b86ff !important
}

.border-warning {
    border-color: #ff9736 !important
}

.border-danger {
    border-color: #ff6f61 !important
}

.border-light {
    border-color: #f5f5f5 !important
}

.border-dark {
    border-color: #1f1f1f !important
}

.border-white {
    border-color: #fff !important
}

.rounded-sm {
    border-radius: .2rem !important
}

.rounded {
    border-radius: .25rem !important
}

.rounded-top {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important
}

.rounded-right {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important
}

.rounded-bottom {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important
}

.rounded-left {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important
}

.rounded-lg {
    border-radius: .3rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: 50rem !important
}

.rounded-0 {
    border-radius: 0 !important
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

@media (min-width:576px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width:768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width:992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width:1200px) {
    .d-xl-none {
        display: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }
}

@media print {
    .d-print-none {
        display: none !important
    }

    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
}

.embed-responsive::before {
    display: block;
    content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%
}

.embed-responsive-16by9::before {
    padding-top: 56.25%
}

.embed-responsive-4by3::before {
    padding-top: 75%
}

.embed-responsive-1by1::before {
    padding-top: 100%
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

@media (min-width:576px) {
    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }
}

@media (min-width:768px) {
    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }
}

@media (min-width:992px) {
    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }
}

@media (min-width:1200px) {
    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }
}

.float-left {
    float: left !important
}

.float-right {
    float: right !important
}

.float-none {
    float: none !important
}

@media (min-width:576px) {
    .float-sm-left {
        float: left !important
    }

    .float-sm-right {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }
}

@media (min-width:768px) {
    .float-md-left {
        float: left !important
    }

    .float-md-right {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }
}

@media (min-width:992px) {
    .float-lg-left {
        float: left !important
    }

    .float-lg-right {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }
}

@media (min-width:1200px) {
    .float-xl-left {
        float: left !important
    }

    .float-xl-right {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(17, 17, 17, .075) !important
}

.shadow {
    box-shadow: 0 .25rem .25rem rgba(17, 17, 17, .02) !important
}

.shadow-lg {
    box-shadow: 0 .25rem .25rem rgba(17, 17, 17, .02), 0 .75rem 2.25rem rgba(17, 17, 17, .04) !important
}

.shadow-none {
    box-shadow: none !important
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.w-0 {
    width: 0 !important
}

.w-125 {
    width: 125% !important
}

.w-150 {
    width: 150% !important
}

.w-175 {
    width: 175% !important
}

.w-200 {
    width: 200% !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.h-0 {
    height: 0 !important
}

.h-125 {
    height: 125% !important
}

.h-150 {
    height: 150% !important
}

.h-175 {
    height: 175% !important
}

.h-200 {
    height: 200% !important
}

.mw-100 {
    max-width: 100% !important
}

.mh-100 {
    max-height: 100% !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.vw-100 {
    width: 100vw !important
}

.vh-100 {
    height: 100vh !important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0)
}

.m-0 {
    margin: 0 !important
}

.mt-0,
.my-0 {
    margin-top: 0 !important
}

.mr-0,
.mx-0 {
    margin-right: 0 !important
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important
}

.ml-0,
.mx-0 {
    margin-left: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.mt-1,
.my-1 {
    margin-top: .25rem !important
}

.mr-1,
.mx-1 {
    margin-right: .25rem !important
}

.mb-1,
.my-1 {
    margin-bottom: .25rem !important
}

.ml-1,
.mx-1 {
    margin-left: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.mt-2,
.my-2 {
    margin-top: .5rem !important
}

.mr-2,
.mx-2 {
    margin-right: .5rem !important
}

.mb-2,
.my-2 {
    margin-bottom: .5rem !important
}

.ml-2,
.mx-2 {
    margin-left: .5rem !important
}

.m-3 {
    margin: .75rem !important
}

.mt-3,
.my-3 {
    margin-top: .75rem !important
}

.mr-3,
.mx-3 {
    margin-right: .75rem !important
}

.mb-3,
.my-3 {
    margin-bottom: .75rem !important
}

.ml-3,
.mx-3 {
    margin-left: .75rem !important
}

.m-4 {
    margin: 1rem !important
}

.mt-4,
.my-4 {
    margin-top: 1rem !important
}

.mr-4,
.mx-4 {
    margin-right: 1rem !important
}

.mb-4,
.my-4 {
    margin-bottom: 1rem !important
}

.ml-4,
.mx-4 {
    margin-left: 1rem !important
}

.m-5 {
    margin: 1.25rem !important
}

.mt-5,
.my-5 {
    margin-top: 1.25rem !important
}

.mr-5,
.mx-5 {
    margin-right: 1.25rem !important
}

.mb-5,
.my-5 {
    margin-bottom: 1.25rem !important
}

.ml-5,
.mx-5 {
    margin-left: 1.25rem !important
}

.m-6 {
    margin: 1.5rem !important
}

.mt-6,
.my-6 {
    margin-top: 1.5rem !important
}

.mr-6,
.mx-6 {
    margin-right: 1.5rem !important
}

.mb-6,
.my-6 {
    margin-bottom: 1.5rem !important
}

.ml-6,
.mx-6 {
    margin-left: 1.5rem !important
}

.m-7 {
    margin: 2rem !important
}

.mt-7,
.my-7 {
    margin-top: 2rem !important
}

.mr-7,
.mx-7 {
    margin-right: 2rem !important
}

.mb-7,
.my-7 {
    margin-bottom: 2rem !important
}

.ml-7,
.mx-7 {
    margin-left: 2rem !important
}

.m-8 {
    margin: 2.5rem !important
}

.mt-8,
.my-8 {
    margin-top: 2.5rem !important
}

.mr-8,
.mx-8 {
    margin-right: 2.5rem !important
}

.mb-8,
.my-8 {
    margin-bottom: 2.5rem !important
}

.ml-8,
.mx-8 {
    margin-left: 2.5rem !important
}

.m-9 {
    margin: 3rem !important
}

.mt-9,
.my-9 {
    margin-top: 3rem !important
}

.mr-9,
.mx-9 {
    margin-right: 3rem !important
}

.mb-9,
.my-9 {
    margin-bottom: 3rem !important
}

.ml-9,
.mx-9 {
    margin-left: 3rem !important
}

.m-10 {
    margin: 4rem !important
}

.mt-10,
.my-10 {
    margin-top: 4rem !important
}

.mr-10,
.mx-10 {
    margin-right: 4rem !important
}

.mb-10,
.my-10 {
    margin-bottom: 4rem !important
}

.ml-10,
.mx-10 {
    margin-left: 4rem !important
}

.m-11 {
    margin: 5rem !important
}

.mt-11,
.my-11 {
    margin-top: 5rem !important
}

.mr-11,
.mx-11 {
    margin-right: 5rem !important
}

.mb-11,
.my-11 {
    margin-bottom: 5rem !important
}

.ml-11,
.mx-11 {
    margin-left: 5rem !important
}

.m-12 {
    margin: 6rem !important
}

.mt-12,
.my-12 {
    margin-top: 6rem !important
}

.mr-12,
.mx-12 {
    margin-right: 6rem !important
}

.mb-12,
.my-12 {
    margin-bottom: 6rem !important
}

.ml-12,
.mx-12 {
    margin-left: 6rem !important
}

.m-13 {
    margin: 8rem !important
}

.mt-13,
.my-13 {
    margin-top: 8rem !important
}

.mr-13,
.mx-13 {
    margin-right: 8rem !important
}

.mb-13,
.my-13 {
    margin-bottom: 8rem !important
}

.ml-13,
.mx-13 {
    margin-left: 8rem !important
}

.m-14 {
    margin: 10rem !important
}

.mt-14,
.my-14 {
    margin-top: 10rem !important
}

.mr-14,
.mx-14 {
    margin-right: 10rem !important
}

.mb-14,
.my-14 {
    margin-bottom: 10rem !important
}

.ml-14,
.mx-14 {
    margin-left: 10rem !important
}

.m-15 {
    margin: 12rem !important
}

.mt-15,
.my-15 {
    margin-top: 12rem !important
}

.mr-15,
.mx-15 {
    margin-right: 12rem !important
}

.mb-15,
.my-15 {
    margin-bottom: 12rem !important
}

.ml-15,
.mx-15 {
    margin-left: 12rem !important
}

.p-0 {
    padding: 0 !important
}

.pt-0,
.py-0 {
    padding-top: 0 !important
}

.pr-0,
.px-0 {
    padding-right: 0 !important
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important
}

.pl-0,
.px-0 {
    padding-left: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.pt-1,
.py-1 {
    padding-top: .25rem !important
}

.pr-1,
.px-1 {
    padding-right: .25rem !important
}

.pb-1,
.py-1 {
    padding-bottom: .25rem !important
}

.pl-1,
.px-1 {
    padding-left: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.pt-2,
.py-2 {
    padding-top: .5rem !important
}

.pr-2,
.px-2 {
    padding-right: .5rem !important
}

.pb-2,
.py-2 {
    padding-bottom: .5rem !important
}

.pl-2,
.px-2 {
    padding-left: .5rem !important
}

.p-3 {
    padding: .75rem !important
}

.pt-3,
.py-3 {
    padding-top: .75rem !important
}

.pr-3,
.px-3 {
    padding-right: .75rem !important
}

.pb-3,
.py-3 {
    padding-bottom: .75rem !important
}

.pl-3,
.px-3 {
    padding-left: .75rem !important
}

.p-4 {
    padding: 1rem !important
}

.pt-4,
.py-4 {
    padding-top: 1rem !important
}

.pr-4,
.px-4 {
    padding-right: 1rem !important
}

.pb-4,
.py-4 {
    padding-bottom: 1rem !important
}

.pl-4,
.px-4 {
    padding-left: 1rem !important
}

.p-5 {
    padding: 1.25rem !important
}

.pt-5,
.py-5 {
    padding-top: 1.25rem !important
}

.pr-5,
.px-5 {
    padding-right: 1.25rem !important
}

.pb-5,
.py-5 {
    padding-bottom: 1.25rem !important
}

.pl-5,
.px-5 {
    padding-left: 1.25rem !important
}

.p-6 {
    padding: 1.5rem !important
}

.pt-6,
.py-6 {
    padding-top: 1.5rem !important
}

.pr-6,
.px-6 {
    padding-right: 1.5rem !important
}

.pb-6,
.py-6 {
    padding-bottom: 1.5rem !important
}

.pl-6,
.px-6 {
    padding-left: 1.5rem !important
}

.p-7 {
    padding: 2rem !important
}

.pt-7,
.py-7 {
    padding-top: 2rem !important
}

.pr-7,
.px-7 {
    padding-right: 2rem !important
}

.pb-7,
.py-7 {
    padding-bottom: 2rem !important
}

.pl-7,
.px-7 {
    padding-left: 2rem !important
}

.p-8 {
    padding: 2.5rem !important
}

.pt-8,
.py-8 {
    padding-top: 2.5rem !important
}

.pr-8,
.px-8 {
    padding-right: 2.5rem !important
}

.pb-8,
.py-8 {
    padding-bottom: 2.5rem !important
}

.pl-8,
.px-8 {
    padding-left: 2.5rem !important
}

.p-9 {
    padding: 3rem !important
}

.pt-9,
.py-9 {
    padding-top: 3rem !important
}

.pr-9,
.px-9 {
    padding-right: 3rem !important
}

.pb-9,
.py-9 {
    padding-bottom: 3rem !important
}

.pl-9,
.px-9 {
    padding-left: 3rem !important
}

.p-10 {
    padding: 4rem !important
}

.pt-10,
.py-10 {
    padding-top: 4rem !important
}

.pr-10,
.px-10 {
    padding-right: 4rem !important
}

.pb-10,
.py-10 {
    padding-bottom: 4rem !important
}

.pl-10,
.px-10 {
    padding-left: 4rem !important
}

.p-11 {
    padding: 5rem !important
}

.pt-11,
.py-11 {
    padding-top: 5rem !important
}

.pr-11,
.px-11 {
    padding-right: 5rem !important
}

.pb-11,
.py-11 {
    padding-bottom: 5rem !important
}

.pl-11,
.px-11 {
    padding-left: 5rem !important
}

.p-12 {
    padding: 6rem !important
}

.pt-12,
.py-12 {
    padding-top: 6rem !important
}

.pr-12,
.px-12 {
    padding-right: 6rem !important
}

.pb-12,
.py-12 {
    padding-bottom: 6rem !important
}

.pl-12,
.px-12 {
    padding-left: 6rem !important
}

.p-13 {
    padding: 8rem !important
}

.pt-13,
.py-13 {
    padding-top: 8rem !important
}

.pr-13,
.px-13 {
    padding-right: 8rem !important
}

.pb-13,
.py-13 {
    padding-bottom: 8rem !important
}

.pl-13,
.px-13 {
    padding-left: 8rem !important
}

.p-14 {
    padding: 10rem !important
}

.pt-14,
.py-14 {
    padding-top: 10rem !important
}

.pr-14,
.px-14 {
    padding-right: 10rem !important
}

.pb-14,
.py-14 {
    padding-bottom: 10rem !important
}

.pl-14,
.px-14 {
    padding-left: 10rem !important
}

.p-15 {
    padding: 12rem !important
}

.pt-15,
.py-15 {
    padding-top: 12rem !important
}

.pr-15,
.px-15 {
    padding-right: 12rem !important
}

.pb-15,
.py-15 {
    padding-bottom: 12rem !important
}

.pl-15,
.px-15 {
    padding-left: 12rem !important
}

.m-n1 {
    margin: -.25rem !important
}

.mt-n1,
.my-n1 {
    margin-top: -.25rem !important
}

.mr-n1,
.mx-n1 {
    margin-right: -.25rem !important
}

.mb-n1,
.my-n1 {
    margin-bottom: -.25rem !important
}

.ml-n1,
.mx-n1 {
    margin-left: -.25rem !important
}

.m-n2 {
    margin: -.5rem !important
}

.mt-n2,
.my-n2 {
    margin-top: -.5rem !important
}

.mr-n2,
.mx-n2 {
    margin-right: -.5rem !important
}

.mb-n2,
.my-n2 {
    margin-bottom: -.5rem !important
}

.ml-n2,
.mx-n2 {
    margin-left: -.5rem !important
}

.m-n3 {
    margin: -.75rem !important
}

.mt-n3,
.my-n3 {
    margin-top: -.75rem !important
}

.mr-n3,
.mx-n3 {
    margin-right: -.75rem !important
}

.mb-n3,
.my-n3 {
    margin-bottom: -.75rem !important
}

.ml-n3,
.mx-n3 {
    margin-left: -.75rem !important
}

.m-n4 {
    margin: -1rem !important
}

.mt-n4,
.my-n4 {
    margin-top: -1rem !important
}

.mr-n4,
.mx-n4 {
    margin-right: -1rem !important
}

.mb-n4,
.my-n4 {
    margin-bottom: -1rem !important
}

.ml-n4,
.mx-n4 {
    margin-left: -1rem !important
}

.m-n5 {
    margin: -1.25rem !important
}

.mt-n5,
.my-n5 {
    margin-top: -1.25rem !important
}

.mr-n5,
.mx-n5 {
    margin-right: -1.25rem !important
}

.mb-n5,
.my-n5 {
    margin-bottom: -1.25rem !important
}

.ml-n5,
.mx-n5 {
    margin-left: -1.25rem !important
}

.m-n6 {
    margin: -1.5rem !important
}

.mt-n6,
.my-n6 {
    margin-top: -1.5rem !important
}

.mr-n6,
.mx-n6 {
    margin-right: -1.5rem !important
}

.mb-n6,
.my-n6 {
    margin-bottom: -1.5rem !important
}

.ml-n6,
.mx-n6 {
    margin-left: -1.5rem !important
}

.m-n7 {
    margin: -2rem !important
}

.mt-n7,
.my-n7 {
    margin-top: -2rem !important
}

.mr-n7,
.mx-n7 {
    margin-right: -2rem !important
}

.mb-n7,
.my-n7 {
    margin-bottom: -2rem !important
}

.ml-n7,
.mx-n7 {
    margin-left: -2rem !important
}

.m-n8 {
    margin: -2.5rem !important
}

.mt-n8,
.my-n8 {
    margin-top: -2.5rem !important
}

.mr-n8,
.mx-n8 {
    margin-right: -2.5rem !important
}

.mb-n8,
.my-n8 {
    margin-bottom: -2.5rem !important
}

.ml-n8,
.mx-n8 {
    margin-left: -2.5rem !important
}

.m-n9 {
    margin: -3rem !important
}

.mt-n9,
.my-n9 {
    margin-top: -3rem !important
}

.mr-n9,
.mx-n9 {
    margin-right: -3rem !important
}

.mb-n9,
.my-n9 {
    margin-bottom: -3rem !important
}

.ml-n9,
.mx-n9 {
    margin-left: -3rem !important
}

.m-n10 {
    margin: -4rem !important
}

.mt-n10,
.my-n10 {
    margin-top: -4rem !important
}

.mr-n10,
.mx-n10 {
    margin-right: -4rem !important
}

.mb-n10,
.my-n10 {
    margin-bottom: -4rem !important
}

.ml-n10,
.mx-n10 {
    margin-left: -4rem !important
}

.m-n11 {
    margin: -5rem !important
}

.mt-n11,
.my-n11 {
    margin-top: -5rem !important
}

.mr-n11,
.mx-n11 {
    margin-right: -5rem !important
}

.mb-n11,
.my-n11 {
    margin-bottom: -5rem !important
}

.ml-n11,
.mx-n11 {
    margin-left: -5rem !important
}

.m-n12 {
    margin: -6rem !important
}

.mt-n12,
.my-n12 {
    margin-top: -6rem !important
}

.mr-n12,
.mx-n12 {
    margin-right: -6rem !important
}

.mb-n12,
.my-n12 {
    margin-bottom: -6rem !important
}

.ml-n12,
.mx-n12 {
    margin-left: -6rem !important
}

.m-n13 {
    margin: -8rem !important
}

.mt-n13,
.my-n13 {
    margin-top: -8rem !important
}

.mr-n13,
.mx-n13 {
    margin-right: -8rem !important
}

.mb-n13,
.my-n13 {
    margin-bottom: -8rem !important
}

.ml-n13,
.mx-n13 {
    margin-left: -8rem !important
}

.m-n14 {
    margin: -10rem !important
}

.mt-n14,
.my-n14 {
    margin-top: -10rem !important
}

.mr-n14,
.mx-n14 {
    margin-right: -10rem !important
}

.mb-n14,
.my-n14 {
    margin-bottom: -10rem !important
}

.ml-n14,
.mx-n14 {
    margin-left: -10rem !important
}

.m-n15 {
    margin: -12rem !important
}

.mt-n15,
.my-n15 {
    margin-top: -12rem !important
}

.mr-n15,
.mx-n15 {
    margin-right: -12rem !important
}

.mb-n15,
.my-n15 {
    margin-bottom: -12rem !important
}

.ml-n15,
.mx-n15 {
    margin-left: -12rem !important
}

.m-auto {
    margin: auto !important
}

.mt-auto,
.my-auto {
    margin-top: auto !important
}

.mr-auto,
.mx-auto {
    margin-right: auto !important
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important
}

.ml-auto,
.mx-auto {
    margin-left: auto !important
}

@media (min-width:576px) {
    .m-sm-0 {
        margin: 0 !important
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: .25rem !important
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: .25rem !important
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: .25rem !important
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: .5rem !important
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: .5rem !important
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: .5rem !important
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: .5rem !important
    }

    .m-sm-3 {
        margin: .75rem !important
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: .75rem !important
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: .75rem !important
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: .75rem !important
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: .75rem !important
    }

    .m-sm-4 {
        margin: 1rem !important
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1rem !important
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1rem !important
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1rem !important
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1rem !important
    }

    .m-sm-5 {
        margin: 1.25rem !important
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 1.25rem !important
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 1.25rem !important
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 1.25rem !important
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 1.25rem !important
    }

    .m-sm-6 {
        margin: 1.5rem !important
    }

    .mt-sm-6,
    .my-sm-6 {
        margin-top: 1.5rem !important
    }

    .mr-sm-6,
    .mx-sm-6 {
        margin-right: 1.5rem !important
    }

    .mb-sm-6,
    .my-sm-6 {
        margin-bottom: 1.5rem !important
    }

    .ml-sm-6,
    .mx-sm-6 {
        margin-left: 1.5rem !important
    }

    .m-sm-7 {
        margin: 2rem !important
    }

    .mt-sm-7,
    .my-sm-7 {
        margin-top: 2rem !important
    }

    .mr-sm-7,
    .mx-sm-7 {
        margin-right: 2rem !important
    }

    .mb-sm-7,
    .my-sm-7 {
        margin-bottom: 2rem !important
    }

    .ml-sm-7,
    .mx-sm-7 {
        margin-left: 2rem !important
    }

    .m-sm-8 {
        margin: 2.5rem !important
    }

    .mt-sm-8,
    .my-sm-8 {
        margin-top: 2.5rem !important
    }

    .mr-sm-8,
    .mx-sm-8 {
        margin-right: 2.5rem !important
    }

    .mb-sm-8,
    .my-sm-8 {
        margin-bottom: 2.5rem !important
    }

    .ml-sm-8,
    .mx-sm-8 {
        margin-left: 2.5rem !important
    }

    .m-sm-9 {
        margin: 3rem !important
    }

    .mt-sm-9,
    .my-sm-9 {
        margin-top: 3rem !important
    }

    .mr-sm-9,
    .mx-sm-9 {
        margin-right: 3rem !important
    }

    .mb-sm-9,
    .my-sm-9 {
        margin-bottom: 3rem !important
    }

    .ml-sm-9,
    .mx-sm-9 {
        margin-left: 3rem !important
    }

    .m-sm-10 {
        margin: 4rem !important
    }

    .mt-sm-10,
    .my-sm-10 {
        margin-top: 4rem !important
    }

    .mr-sm-10,
    .mx-sm-10 {
        margin-right: 4rem !important
    }

    .mb-sm-10,
    .my-sm-10 {
        margin-bottom: 4rem !important
    }

    .ml-sm-10,
    .mx-sm-10 {
        margin-left: 4rem !important
    }

    .m-sm-11 {
        margin: 5rem !important
    }

    .mt-sm-11,
    .my-sm-11 {
        margin-top: 5rem !important
    }

    .mr-sm-11,
    .mx-sm-11 {
        margin-right: 5rem !important
    }

    .mb-sm-11,
    .my-sm-11 {
        margin-bottom: 5rem !important
    }

    .ml-sm-11,
    .mx-sm-11 {
        margin-left: 5rem !important
    }

    .m-sm-12 {
        margin: 6rem !important
    }

    .mt-sm-12,
    .my-sm-12 {
        margin-top: 6rem !important
    }

    .mr-sm-12,
    .mx-sm-12 {
        margin-right: 6rem !important
    }

    .mb-sm-12,
    .my-sm-12 {
        margin-bottom: 6rem !important
    }

    .ml-sm-12,
    .mx-sm-12 {
        margin-left: 6rem !important
    }

    .m-sm-13 {
        margin: 8rem !important
    }

    .mt-sm-13,
    .my-sm-13 {
        margin-top: 8rem !important
    }

    .mr-sm-13,
    .mx-sm-13 {
        margin-right: 8rem !important
    }

    .mb-sm-13,
    .my-sm-13 {
        margin-bottom: 8rem !important
    }

    .ml-sm-13,
    .mx-sm-13 {
        margin-left: 8rem !important
    }

    .m-sm-14 {
        margin: 10rem !important
    }

    .mt-sm-14,
    .my-sm-14 {
        margin-top: 10rem !important
    }

    .mr-sm-14,
    .mx-sm-14 {
        margin-right: 10rem !important
    }

    .mb-sm-14,
    .my-sm-14 {
        margin-bottom: 10rem !important
    }

    .ml-sm-14,
    .mx-sm-14 {
        margin-left: 10rem !important
    }

    .m-sm-15 {
        margin: 12rem !important
    }

    .mt-sm-15,
    .my-sm-15 {
        margin-top: 12rem !important
    }

    .mr-sm-15,
    .mx-sm-15 {
        margin-right: 12rem !important
    }

    .mb-sm-15,
    .my-sm-15 {
        margin-bottom: 12rem !important
    }

    .ml-sm-15,
    .mx-sm-15 {
        margin-left: 12rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: .25rem !important
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: .25rem !important
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: .25rem !important
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: .5rem !important
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: .5rem !important
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: .5rem !important
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: .5rem !important
    }

    .p-sm-3 {
        padding: .75rem !important
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: .75rem !important
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: .75rem !important
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: .75rem !important
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: .75rem !important
    }

    .p-sm-4 {
        padding: 1rem !important
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1rem !important
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1rem !important
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1rem !important
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1rem !important
    }

    .p-sm-5 {
        padding: 1.25rem !important
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 1.25rem !important
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 1.25rem !important
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 1.25rem !important
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 1.25rem !important
    }

    .p-sm-6 {
        padding: 1.5rem !important
    }

    .pt-sm-6,
    .py-sm-6 {
        padding-top: 1.5rem !important
    }

    .pr-sm-6,
    .px-sm-6 {
        padding-right: 1.5rem !important
    }

    .pb-sm-6,
    .py-sm-6 {
        padding-bottom: 1.5rem !important
    }

    .pl-sm-6,
    .px-sm-6 {
        padding-left: 1.5rem !important
    }

    .p-sm-7 {
        padding: 2rem !important
    }

    .pt-sm-7,
    .py-sm-7 {
        padding-top: 2rem !important
    }

    .pr-sm-7,
    .px-sm-7 {
        padding-right: 2rem !important
    }

    .pb-sm-7,
    .py-sm-7 {
        padding-bottom: 2rem !important
    }

    .pl-sm-7,
    .px-sm-7 {
        padding-left: 2rem !important
    }

    .p-sm-8 {
        padding: 2.5rem !important
    }

    .pt-sm-8,
    .py-sm-8 {
        padding-top: 2.5rem !important
    }

    .pr-sm-8,
    .px-sm-8 {
        padding-right: 2.5rem !important
    }

    .pb-sm-8,
    .py-sm-8 {
        padding-bottom: 2.5rem !important
    }

    .pl-sm-8,
    .px-sm-8 {
        padding-left: 2.5rem !important
    }

    .p-sm-9 {
        padding: 3rem !important
    }

    .pt-sm-9,
    .py-sm-9 {
        padding-top: 3rem !important
    }

    .pr-sm-9,
    .px-sm-9 {
        padding-right: 3rem !important
    }

    .pb-sm-9,
    .py-sm-9 {
        padding-bottom: 3rem !important
    }

    .pl-sm-9,
    .px-sm-9 {
        padding-left: 3rem !important
    }

    .p-sm-10 {
        padding: 4rem !important
    }

    .pt-sm-10,
    .py-sm-10 {
        padding-top: 4rem !important
    }

    .pr-sm-10,
    .px-sm-10 {
        padding-right: 4rem !important
    }

    .pb-sm-10,
    .py-sm-10 {
        padding-bottom: 4rem !important
    }

    .pl-sm-10,
    .px-sm-10 {
        padding-left: 4rem !important
    }

    .p-sm-11 {
        padding: 5rem !important
    }

    .pt-sm-11,
    .py-sm-11 {
        padding-top: 5rem !important
    }

    .pr-sm-11,
    .px-sm-11 {
        padding-right: 5rem !important
    }

    .pb-sm-11,
    .py-sm-11 {
        padding-bottom: 5rem !important
    }

    .pl-sm-11,
    .px-sm-11 {
        padding-left: 5rem !important
    }

    .p-sm-12 {
        padding: 6rem !important
    }

    .pt-sm-12,
    .py-sm-12 {
        padding-top: 6rem !important
    }

    .pr-sm-12,
    .px-sm-12 {
        padding-right: 6rem !important
    }

    .pb-sm-12,
    .py-sm-12 {
        padding-bottom: 6rem !important
    }

    .pl-sm-12,
    .px-sm-12 {
        padding-left: 6rem !important
    }

    .p-sm-13 {
        padding: 8rem !important
    }

    .pt-sm-13,
    .py-sm-13 {
        padding-top: 8rem !important
    }

    .pr-sm-13,
    .px-sm-13 {
        padding-right: 8rem !important
    }

    .pb-sm-13,
    .py-sm-13 {
        padding-bottom: 8rem !important
    }

    .pl-sm-13,
    .px-sm-13 {
        padding-left: 8rem !important
    }

    .p-sm-14 {
        padding: 10rem !important
    }

    .pt-sm-14,
    .py-sm-14 {
        padding-top: 10rem !important
    }

    .pr-sm-14,
    .px-sm-14 {
        padding-right: 10rem !important
    }

    .pb-sm-14,
    .py-sm-14 {
        padding-bottom: 10rem !important
    }

    .pl-sm-14,
    .px-sm-14 {
        padding-left: 10rem !important
    }

    .p-sm-15 {
        padding: 12rem !important
    }

    .pt-sm-15,
    .py-sm-15 {
        padding-top: 12rem !important
    }

    .pr-sm-15,
    .px-sm-15 {
        padding-right: 12rem !important
    }

    .pb-sm-15,
    .py-sm-15 {
        padding-bottom: 12rem !important
    }

    .pl-sm-15,
    .px-sm-15 {
        padding-left: 12rem !important
    }

    .m-sm-n1 {
        margin: -.25rem !important
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -.25rem !important
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -.25rem !important
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -.25rem !important
    }

    .m-sm-n2 {
        margin: -.5rem !important
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -.5rem !important
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -.5rem !important
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -.5rem !important
    }

    .m-sm-n3 {
        margin: -.75rem !important
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -.75rem !important
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -.75rem !important
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -.75rem !important
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -.75rem !important
    }

    .m-sm-n4 {
        margin: -1rem !important
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1rem !important
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1rem !important
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1rem !important
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1rem !important
    }

    .m-sm-n5 {
        margin: -1.25rem !important
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -1.25rem !important
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -1.25rem !important
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -1.25rem !important
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -1.25rem !important
    }

    .m-sm-n6 {
        margin: -1.5rem !important
    }

    .mt-sm-n6,
    .my-sm-n6 {
        margin-top: -1.5rem !important
    }

    .mr-sm-n6,
    .mx-sm-n6 {
        margin-right: -1.5rem !important
    }

    .mb-sm-n6,
    .my-sm-n6 {
        margin-bottom: -1.5rem !important
    }

    .ml-sm-n6,
    .mx-sm-n6 {
        margin-left: -1.5rem !important
    }

    .m-sm-n7 {
        margin: -2rem !important
    }

    .mt-sm-n7,
    .my-sm-n7 {
        margin-top: -2rem !important
    }

    .mr-sm-n7,
    .mx-sm-n7 {
        margin-right: -2rem !important
    }

    .mb-sm-n7,
    .my-sm-n7 {
        margin-bottom: -2rem !important
    }

    .ml-sm-n7,
    .mx-sm-n7 {
        margin-left: -2rem !important
    }

    .m-sm-n8 {
        margin: -2.5rem !important
    }

    .mt-sm-n8,
    .my-sm-n8 {
        margin-top: -2.5rem !important
    }

    .mr-sm-n8,
    .mx-sm-n8 {
        margin-right: -2.5rem !important
    }

    .mb-sm-n8,
    .my-sm-n8 {
        margin-bottom: -2.5rem !important
    }

    .ml-sm-n8,
    .mx-sm-n8 {
        margin-left: -2.5rem !important
    }

    .m-sm-n9 {
        margin: -3rem !important
    }

    .mt-sm-n9,
    .my-sm-n9 {
        margin-top: -3rem !important
    }

    .mr-sm-n9,
    .mx-sm-n9 {
        margin-right: -3rem !important
    }

    .mb-sm-n9,
    .my-sm-n9 {
        margin-bottom: -3rem !important
    }

    .ml-sm-n9,
    .mx-sm-n9 {
        margin-left: -3rem !important
    }

    .m-sm-n10 {
        margin: -4rem !important
    }

    .mt-sm-n10,
    .my-sm-n10 {
        margin-top: -4rem !important
    }

    .mr-sm-n10,
    .mx-sm-n10 {
        margin-right: -4rem !important
    }

    .mb-sm-n10,
    .my-sm-n10 {
        margin-bottom: -4rem !important
    }

    .ml-sm-n10,
    .mx-sm-n10 {
        margin-left: -4rem !important
    }

    .m-sm-n11 {
        margin: -5rem !important
    }

    .mt-sm-n11,
    .my-sm-n11 {
        margin-top: -5rem !important
    }

    .mr-sm-n11,
    .mx-sm-n11 {
        margin-right: -5rem !important
    }

    .mb-sm-n11,
    .my-sm-n11 {
        margin-bottom: -5rem !important
    }

    .ml-sm-n11,
    .mx-sm-n11 {
        margin-left: -5rem !important
    }

    .m-sm-n12 {
        margin: -6rem !important
    }

    .mt-sm-n12,
    .my-sm-n12 {
        margin-top: -6rem !important
    }

    .mr-sm-n12,
    .mx-sm-n12 {
        margin-right: -6rem !important
    }

    .mb-sm-n12,
    .my-sm-n12 {
        margin-bottom: -6rem !important
    }

    .ml-sm-n12,
    .mx-sm-n12 {
        margin-left: -6rem !important
    }

    .m-sm-n13 {
        margin: -8rem !important
    }

    .mt-sm-n13,
    .my-sm-n13 {
        margin-top: -8rem !important
    }

    .mr-sm-n13,
    .mx-sm-n13 {
        margin-right: -8rem !important
    }

    .mb-sm-n13,
    .my-sm-n13 {
        margin-bottom: -8rem !important
    }

    .ml-sm-n13,
    .mx-sm-n13 {
        margin-left: -8rem !important
    }

    .m-sm-n14 {
        margin: -10rem !important
    }

    .mt-sm-n14,
    .my-sm-n14 {
        margin-top: -10rem !important
    }

    .mr-sm-n14,
    .mx-sm-n14 {
        margin-right: -10rem !important
    }

    .mb-sm-n14,
    .my-sm-n14 {
        margin-bottom: -10rem !important
    }

    .ml-sm-n14,
    .mx-sm-n14 {
        margin-left: -10rem !important
    }

    .m-sm-n15 {
        margin: -12rem !important
    }

    .mt-sm-n15,
    .my-sm-n15 {
        margin-top: -12rem !important
    }

    .mr-sm-n15,
    .mx-sm-n15 {
        margin-right: -12rem !important
    }

    .mb-sm-n15,
    .my-sm-n15 {
        margin-bottom: -12rem !important
    }

    .ml-sm-n15,
    .mx-sm-n15 {
        margin-left: -12rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important
    }
}

@media (min-width:768px) {
    .m-md-0 {
        margin: 0 !important
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: .25rem !important
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: .25rem !important
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: .25rem !important
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: .5rem !important
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: .5rem !important
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: .5rem !important
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: .5rem !important
    }

    .m-md-3 {
        margin: .75rem !important
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: .75rem !important
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: .75rem !important
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: .75rem !important
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: .75rem !important
    }

    .m-md-4 {
        margin: 1rem !important
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1rem !important
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1rem !important
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1rem !important
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1rem !important
    }

    .m-md-5 {
        margin: 1.25rem !important
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 1.25rem !important
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 1.25rem !important
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 1.25rem !important
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 1.25rem !important
    }

    .m-md-6 {
        margin: 1.5rem !important
    }

    .mt-md-6,
    .my-md-6 {
        margin-top: 1.5rem !important
    }

    .mr-md-6,
    .mx-md-6 {
        margin-right: 1.5rem !important
    }

    .mb-md-6,
    .my-md-6 {
        margin-bottom: 1.5rem !important
    }

    .ml-md-6,
    .mx-md-6 {
        margin-left: 1.5rem !important
    }

    .m-md-7 {
        margin: 2rem !important
    }

    .mt-md-7,
    .my-md-7 {
        margin-top: 2rem !important
    }

    .mr-md-7,
    .mx-md-7 {
        margin-right: 2rem !important
    }

    .mb-md-7,
    .my-md-7 {
        margin-bottom: 2rem !important
    }

    .ml-md-7,
    .mx-md-7 {
        margin-left: 2rem !important
    }

    .m-md-8 {
        margin: 2.5rem !important
    }

    .mt-md-8,
    .my-md-8 {
        margin-top: 2.5rem !important
    }

    .mr-md-8,
    .mx-md-8 {
        margin-right: 2.5rem !important
    }

    .mb-md-8,
    .my-md-8 {
        margin-bottom: 2.5rem !important
    }

    .ml-md-8,
    .mx-md-8 {
        margin-left: 2.5rem !important
    }

    .m-md-9 {
        margin: 3rem !important
    }

    .mt-md-9,
    .my-md-9 {
        margin-top: 3rem !important
    }

    .mr-md-9,
    .mx-md-9 {
        margin-right: 3rem !important
    }

    .mb-md-9,
    .my-md-9 {
        margin-bottom: 3rem !important
    }

    .ml-md-9,
    .mx-md-9 {
        margin-left: 3rem !important
    }

    .m-md-10 {
        margin: 4rem !important
    }

    .mt-md-10,
    .my-md-10 {
        margin-top: 4rem !important
    }

    .mr-md-10,
    .mx-md-10 {
        margin-right: 4rem !important
    }

    .mb-md-10,
    .my-md-10 {
        margin-bottom: 4rem !important
    }

    .ml-md-10,
    .mx-md-10 {
        margin-left: 4rem !important
    }

    .m-md-11 {
        margin: 5rem !important
    }

    .mt-md-11,
    .my-md-11 {
        margin-top: 5rem !important
    }

    .mr-md-11,
    .mx-md-11 {
        margin-right: 5rem !important
    }

    .mb-md-11,
    .my-md-11 {
        margin-bottom: 5rem !important
    }

    .ml-md-11,
    .mx-md-11 {
        margin-left: 5rem !important
    }

    .m-md-12 {
        margin: 6rem !important
    }

    .mt-md-12,
    .my-md-12 {
        margin-top: 6rem !important
    }

    .mr-md-12,
    .mx-md-12 {
        margin-right: 6rem !important
    }

    .mb-md-12,
    .my-md-12 {
        margin-bottom: 6rem !important
    }

    .ml-md-12,
    .mx-md-12 {
        margin-left: 6rem !important
    }

    .m-md-13 {
        margin: 8rem !important
    }

    .mt-md-13,
    .my-md-13 {
        margin-top: 8rem !important
    }

    .mr-md-13,
    .mx-md-13 {
        margin-right: 8rem !important
    }

    .mb-md-13,
    .my-md-13 {
        margin-bottom: 8rem !important
    }

    .ml-md-13,
    .mx-md-13 {
        margin-left: 8rem !important
    }

    .m-md-14 {
        margin: 10rem !important
    }

    .mt-md-14,
    .my-md-14 {
        margin-top: 10rem !important
    }

    .mr-md-14,
    .mx-md-14 {
        margin-right: 10rem !important
    }

    .mb-md-14,
    .my-md-14 {
        margin-bottom: 10rem !important
    }

    .ml-md-14,
    .mx-md-14 {
        margin-left: 10rem !important
    }

    .m-md-15 {
        margin: 12rem !important
    }

    .mt-md-15,
    .my-md-15 {
        margin-top: 12rem !important
    }

    .mr-md-15,
    .mx-md-15 {
        margin-right: 12rem !important
    }

    .mb-md-15,
    .my-md-15 {
        margin-bottom: 12rem !important
    }

    .ml-md-15,
    .mx-md-15 {
        margin-left: 12rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: .25rem !important
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: .25rem !important
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: .25rem !important
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: .5rem !important
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: .5rem !important
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: .5rem !important
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: .5rem !important
    }

    .p-md-3 {
        padding: .75rem !important
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: .75rem !important
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: .75rem !important
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: .75rem !important
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: .75rem !important
    }

    .p-md-4 {
        padding: 1rem !important
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1rem !important
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1rem !important
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1rem !important
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1rem !important
    }

    .p-md-5 {
        padding: 1.25rem !important
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 1.25rem !important
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 1.25rem !important
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 1.25rem !important
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 1.25rem !important
    }

    .p-md-6 {
        padding: 1.5rem !important
    }

    .pt-md-6,
    .py-md-6 {
        padding-top: 1.5rem !important
    }

    .pr-md-6,
    .px-md-6 {
        padding-right: 1.5rem !important
    }

    .pb-md-6,
    .py-md-6 {
        padding-bottom: 1.5rem !important
    }

    .pl-md-6,
    .px-md-6 {
        padding-left: 1.5rem !important
    }

    .p-md-7 {
        padding: 2rem !important
    }

    .pt-md-7,
    .py-md-7 {
        padding-top: 2rem !important
    }

    .pr-md-7,
    .px-md-7 {
        padding-right: 2rem !important
    }

    .pb-md-7,
    .py-md-7 {
        padding-bottom: 2rem !important
    }

    .pl-md-7,
    .px-md-7 {
        padding-left: 2rem !important
    }

    .p-md-8 {
        padding: 2.5rem !important
    }

    .pt-md-8,
    .py-md-8 {
        padding-top: 2.5rem !important
    }

    .pr-md-8,
    .px-md-8 {
        padding-right: 2.5rem !important
    }

    .pb-md-8,
    .py-md-8 {
        padding-bottom: 2.5rem !important
    }

    .pl-md-8,
    .px-md-8 {
        padding-left: 2.5rem !important
    }

    .p-md-9 {
        padding: 3rem !important
    }

    .pt-md-9,
    .py-md-9 {
        padding-top: 3rem !important
    }

    .pr-md-9,
    .px-md-9 {
        padding-right: 3rem !important
    }

    .pb-md-9,
    .py-md-9 {
        padding-bottom: 3rem !important
    }

    .pl-md-9,
    .px-md-9 {
        padding-left: 3rem !important
    }

    .p-md-10 {
        padding: 4rem !important
    }

    .pt-md-10,
    .py-md-10 {
        padding-top: 4rem !important
    }

    .pr-md-10,
    .px-md-10 {
        padding-right: 4rem !important
    }

    .pb-md-10,
    .py-md-10 {
        padding-bottom: 4rem !important
    }

    .pl-md-10,
    .px-md-10 {
        padding-left: 4rem !important
    }

    .p-md-11 {
        padding: 5rem !important
    }

    .pt-md-11,
    .py-md-11 {
        padding-top: 5rem !important
    }

    .pr-md-11,
    .px-md-11 {
        padding-right: 5rem !important
    }

    .pb-md-11,
    .py-md-11 {
        padding-bottom: 5rem !important
    }

    .pl-md-11,
    .px-md-11 {
        padding-left: 5rem !important
    }

    .p-md-12 {
        padding: 6rem !important
    }

    .pt-md-12,
    .py-md-12 {
        padding-top: 6rem !important
    }

    .pr-md-12,
    .px-md-12 {
        padding-right: 6rem !important
    }

    .pb-md-12,
    .py-md-12 {
        padding-bottom: 6rem !important
    }

    .pl-md-12,
    .px-md-12 {
        padding-left: 6rem !important
    }

    .p-md-13 {
        padding: 8rem !important
    }

    .pt-md-13,
    .py-md-13 {
        padding-top: 8rem !important
    }

    .pr-md-13,
    .px-md-13 {
        padding-right: 8rem !important
    }

    .pb-md-13,
    .py-md-13 {
        padding-bottom: 8rem !important
    }

    .pl-md-13,
    .px-md-13 {
        padding-left: 8rem !important
    }

    .p-md-14 {
        padding: 10rem !important
    }

    .pt-md-14,
    .py-md-14 {
        padding-top: 10rem !important
    }

    .pr-md-14,
    .px-md-14 {
        padding-right: 10rem !important
    }

    .pb-md-14,
    .py-md-14 {
        padding-bottom: 10rem !important
    }

    .pl-md-14,
    .px-md-14 {
        padding-left: 10rem !important
    }

    .p-md-15 {
        padding: 12rem !important
    }

    .pt-md-15,
    .py-md-15 {
        padding-top: 12rem !important
    }

    .pr-md-15,
    .px-md-15 {
        padding-right: 12rem !important
    }

    .pb-md-15,
    .py-md-15 {
        padding-bottom: 12rem !important
    }

    .pl-md-15,
    .px-md-15 {
        padding-left: 12rem !important
    }

    .m-md-n1 {
        margin: -.25rem !important
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -.25rem !important
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -.25rem !important
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -.25rem !important
    }

    .m-md-n2 {
        margin: -.5rem !important
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -.5rem !important
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -.5rem !important
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -.5rem !important
    }

    .m-md-n3 {
        margin: -.75rem !important
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -.75rem !important
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -.75rem !important
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -.75rem !important
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -.75rem !important
    }

    .m-md-n4 {
        margin: -1rem !important
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1rem !important
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1rem !important
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1rem !important
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1rem !important
    }

    .m-md-n5 {
        margin: -1.25rem !important
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -1.25rem !important
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -1.25rem !important
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -1.25rem !important
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -1.25rem !important
    }

    .m-md-n6 {
        margin: -1.5rem !important
    }

    .mt-md-n6,
    .my-md-n6 {
        margin-top: -1.5rem !important
    }

    .mr-md-n6,
    .mx-md-n6 {
        margin-right: -1.5rem !important
    }

    .mb-md-n6,
    .my-md-n6 {
        margin-bottom: -1.5rem !important
    }

    .ml-md-n6,
    .mx-md-n6 {
        margin-left: -1.5rem !important
    }

    .m-md-n7 {
        margin: -2rem !important
    }

    .mt-md-n7,
    .my-md-n7 {
        margin-top: -2rem !important
    }

    .mr-md-n7,
    .mx-md-n7 {
        margin-right: -2rem !important
    }

    .mb-md-n7,
    .my-md-n7 {
        margin-bottom: -2rem !important
    }

    .ml-md-n7,
    .mx-md-n7 {
        margin-left: -2rem !important
    }

    .m-md-n8 {
        margin: -2.5rem !important
    }

    .mt-md-n8,
    .my-md-n8 {
        margin-top: -2.5rem !important
    }

    .mr-md-n8,
    .mx-md-n8 {
        margin-right: -2.5rem !important
    }

    .mb-md-n8,
    .my-md-n8 {
        margin-bottom: -2.5rem !important
    }

    .ml-md-n8,
    .mx-md-n8 {
        margin-left: -2.5rem !important
    }

    .m-md-n9 {
        margin: -3rem !important
    }

    .mt-md-n9,
    .my-md-n9 {
        margin-top: -3rem !important
    }

    .mr-md-n9,
    .mx-md-n9 {
        margin-right: -3rem !important
    }

    .mb-md-n9,
    .my-md-n9 {
        margin-bottom: -3rem !important
    }

    .ml-md-n9,
    .mx-md-n9 {
        margin-left: -3rem !important
    }

    .m-md-n10 {
        margin: -4rem !important
    }

    .mt-md-n10,
    .my-md-n10 {
        margin-top: -4rem !important
    }

    .mr-md-n10,
    .mx-md-n10 {
        margin-right: -4rem !important
    }

    .mb-md-n10,
    .my-md-n10 {
        margin-bottom: -4rem !important
    }

    .ml-md-n10,
    .mx-md-n10 {
        margin-left: -4rem !important
    }

    .m-md-n11 {
        margin: -5rem !important
    }

    .mt-md-n11,
    .my-md-n11 {
        margin-top: -5rem !important
    }

    .mr-md-n11,
    .mx-md-n11 {
        margin-right: -5rem !important
    }

    .mb-md-n11,
    .my-md-n11 {
        margin-bottom: -5rem !important
    }

    .ml-md-n11,
    .mx-md-n11 {
        margin-left: -5rem !important
    }

    .m-md-n12 {
        margin: -6rem !important
    }

    .mt-md-n12,
    .my-md-n12 {
        margin-top: -6rem !important
    }

    .mr-md-n12,
    .mx-md-n12 {
        margin-right: -6rem !important
    }

    .mb-md-n12,
    .my-md-n12 {
        margin-bottom: -6rem !important
    }

    .ml-md-n12,
    .mx-md-n12 {
        margin-left: -6rem !important
    }

    .m-md-n13 {
        margin: -8rem !important
    }

    .mt-md-n13,
    .my-md-n13 {
        margin-top: -8rem !important
    }

    .mr-md-n13,
    .mx-md-n13 {
        margin-right: -8rem !important
    }

    .mb-md-n13,
    .my-md-n13 {
        margin-bottom: -8rem !important
    }

    .ml-md-n13,
    .mx-md-n13 {
        margin-left: -8rem !important
    }

    .m-md-n14 {
        margin: -10rem !important
    }

    .mt-md-n14,
    .my-md-n14 {
        margin-top: -10rem !important
    }

    .mr-md-n14,
    .mx-md-n14 {
        margin-right: -10rem !important
    }

    .mb-md-n14,
    .my-md-n14 {
        margin-bottom: -10rem !important
    }

    .ml-md-n14,
    .mx-md-n14 {
        margin-left: -10rem !important
    }

    .m-md-n15 {
        margin: -12rem !important
    }

    .mt-md-n15,
    .my-md-n15 {
        margin-top: -12rem !important
    }

    .mr-md-n15,
    .mx-md-n15 {
        margin-right: -12rem !important
    }

    .mb-md-n15,
    .my-md-n15 {
        margin-bottom: -12rem !important
    }

    .ml-md-n15,
    .mx-md-n15 {
        margin-left: -12rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important
    }
}

@media (min-width:992px) {
    .m-lg-0 {
        margin: 0 !important
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: .25rem !important
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: .25rem !important
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: .25rem !important
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: .5rem !important
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: .5rem !important
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: .5rem !important
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: .5rem !important
    }

    .m-lg-3 {
        margin: .75rem !important
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: .75rem !important
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: .75rem !important
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: .75rem !important
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: .75rem !important
    }

    .m-lg-4 {
        margin: 1rem !important
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1rem !important
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1rem !important
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1rem !important
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1rem !important
    }

    .m-lg-5 {
        margin: 1.25rem !important
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 1.25rem !important
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 1.25rem !important
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 1.25rem !important
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 1.25rem !important
    }

    .m-lg-6 {
        margin: 1.5rem !important
    }

    .mt-lg-6,
    .my-lg-6 {
        margin-top: 1.5rem !important
    }

    .mr-lg-6,
    .mx-lg-6 {
        margin-right: 1.5rem !important
    }

    .mb-lg-6,
    .my-lg-6 {
        margin-bottom: 1.5rem !important
    }

    .ml-lg-6,
    .mx-lg-6 {
        margin-left: 1.5rem !important
    }

    .m-lg-7 {
        margin: 2rem !important
    }

    .mt-lg-7,
    .my-lg-7 {
        margin-top: 2rem !important
    }

    .mr-lg-7,
    .mx-lg-7 {
        margin-right: 2rem !important
    }

    .mb-lg-7,
    .my-lg-7 {
        margin-bottom: 2rem !important
    }

    .ml-lg-7,
    .mx-lg-7 {
        margin-left: 2rem !important
    }

    .m-lg-8 {
        margin: 2.5rem !important
    }

    .mt-lg-8,
    .my-lg-8 {
        margin-top: 2.5rem !important
    }

    .mr-lg-8,
    .mx-lg-8 {
        margin-right: 2.5rem !important
    }

    .mb-lg-8,
    .my-lg-8 {
        margin-bottom: 2.5rem !important
    }

    .ml-lg-8,
    .mx-lg-8 {
        margin-left: 2.5rem !important
    }

    .m-lg-9 {
        margin: 3rem !important
    }

    .mt-lg-9,
    .my-lg-9 {
        margin-top: 3rem !important
    }

    .mr-lg-9,
    .mx-lg-9 {
        margin-right: 3rem !important
    }

    .mb-lg-9,
    .my-lg-9 {
        margin-bottom: 3rem !important
    }

    .ml-lg-9,
    .mx-lg-9 {
        margin-left: 3rem !important
    }

    .m-lg-10 {
        margin: 4rem !important
    }

    .mt-lg-10,
    .my-lg-10 {
        margin-top: 4rem !important
    }

    .mr-lg-10,
    .mx-lg-10 {
        margin-right: 4rem !important
    }

    .mb-lg-10,
    .my-lg-10 {
        margin-bottom: 4rem !important
    }

    .ml-lg-10,
    .mx-lg-10 {
        margin-left: 4rem !important
    }

    .m-lg-11 {
        margin: 5rem !important
    }

    .mt-lg-11,
    .my-lg-11 {
        margin-top: 5rem !important
    }

    .mr-lg-11,
    .mx-lg-11 {
        margin-right: 5rem !important
    }

    .mb-lg-11,
    .my-lg-11 {
        margin-bottom: 5rem !important
    }

    .ml-lg-11,
    .mx-lg-11 {
        margin-left: 5rem !important
    }

    .m-lg-12 {
        margin: 6rem !important
    }

    .mt-lg-12,
    .my-lg-12 {
        margin-top: 6rem !important
    }

    .mr-lg-12,
    .mx-lg-12 {
        margin-right: 6rem !important
    }

    .mb-lg-12,
    .my-lg-12 {
        margin-bottom: 6rem !important
    }

    .ml-lg-12,
    .mx-lg-12 {
        margin-left: 6rem !important
    }

    .m-lg-13 {
        margin: 8rem !important
    }

    .mt-lg-13,
    .my-lg-13 {
        margin-top: 8rem !important
    }

    .mr-lg-13,
    .mx-lg-13 {
        margin-right: 8rem !important
    }

    .mb-lg-13,
    .my-lg-13 {
        margin-bottom: 8rem !important
    }

    .ml-lg-13,
    .mx-lg-13 {
        margin-left: 8rem !important
    }

    .m-lg-14 {
        margin: 10rem !important
    }

    .mt-lg-14,
    .my-lg-14 {
        margin-top: 10rem !important
    }

    .mr-lg-14,
    .mx-lg-14 {
        margin-right: 10rem !important
    }

    .mb-lg-14,
    .my-lg-14 {
        margin-bottom: 10rem !important
    }

    .ml-lg-14,
    .mx-lg-14 {
        margin-left: 10rem !important
    }

    .m-lg-15 {
        margin: 12rem !important
    }

    .mt-lg-15,
    .my-lg-15 {
        margin-top: 12rem !important
    }

    .mr-lg-15,
    .mx-lg-15 {
        margin-right: 12rem !important
    }

    .mb-lg-15,
    .my-lg-15 {
        margin-bottom: 12rem !important
    }

    .ml-lg-15,
    .mx-lg-15 {
        margin-left: 12rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: .25rem !important
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: .25rem !important
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: .25rem !important
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: .5rem !important
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: .5rem !important
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: .5rem !important
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: .5rem !important
    }

    .p-lg-3 {
        padding: .75rem !important
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: .75rem !important
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: .75rem !important
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: .75rem !important
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: .75rem !important
    }

    .p-lg-4 {
        padding: 1rem !important
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1rem !important
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1rem !important
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1rem !important
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1rem !important
    }

    .p-lg-5 {
        padding: 1.25rem !important
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 1.25rem !important
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 1.25rem !important
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 1.25rem !important
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 1.25rem !important
    }

    .p-lg-6 {
        padding: 1.5rem !important
    }

    .pt-lg-6,
    .py-lg-6 {
        padding-top: 1.5rem !important
    }

    .pr-lg-6,
    .px-lg-6 {
        padding-right: 1.5rem !important
    }

    .pb-lg-6,
    .py-lg-6 {
        padding-bottom: 1.5rem !important
    }

    .pl-lg-6,
    .px-lg-6 {
        padding-left: 1.5rem !important
    }

    .p-lg-7 {
        padding: 2rem !important
    }

    .pt-lg-7,
    .py-lg-7 {
        padding-top: 2rem !important
    }

    .pr-lg-7,
    .px-lg-7 {
        padding-right: 2rem !important
    }

    .pb-lg-7,
    .py-lg-7 {
        padding-bottom: 2rem !important
    }

    .pl-lg-7,
    .px-lg-7 {
        padding-left: 2rem !important
    }

    .p-lg-8 {
        padding: 2.5rem !important
    }

    .pt-lg-8,
    .py-lg-8 {
        padding-top: 2.5rem !important
    }

    .pr-lg-8,
    .px-lg-8 {
        padding-right: 2.5rem !important
    }

    .pb-lg-8,
    .py-lg-8 {
        padding-bottom: 2.5rem !important
    }

    .pl-lg-8,
    .px-lg-8 {
        padding-left: 2.5rem !important
    }

    .p-lg-9 {
        padding: 3rem !important
    }

    .pt-lg-9,
    .py-lg-9 {
        padding-top: 3rem !important
    }

    .pr-lg-9,
    .px-lg-9 {
        padding-right: 3rem !important
    }

    .pb-lg-9,
    .py-lg-9 {
        padding-bottom: 3rem !important
    }

    .pl-lg-9,
    .px-lg-9 {
        padding-left: 3rem !important
    }

    .p-lg-10 {
        padding: 4rem !important
    }

    .pt-lg-10,
    .py-lg-10 {
        padding-top: 4rem !important
    }

    .pr-lg-10,
    .px-lg-10 {
        padding-right: 4rem !important
    }

    .pb-lg-10,
    .py-lg-10 {
        padding-bottom: 4rem !important
    }

    .pl-lg-10,
    .px-lg-10 {
        padding-left: 4rem !important
    }

    .p-lg-11 {
        padding: 5rem !important
    }

    .pt-lg-11,
    .py-lg-11 {
        padding-top: 5rem !important
    }

    .pr-lg-11,
    .px-lg-11 {
        padding-right: 5rem !important
    }

    .pb-lg-11,
    .py-lg-11 {
        padding-bottom: 5rem !important
    }

    .pl-lg-11,
    .px-lg-11 {
        padding-left: 5rem !important
    }

    .p-lg-12 {
        padding: 6rem !important
    }

    .pt-lg-12,
    .py-lg-12 {
        padding-top: 6rem !important
    }

    .pr-lg-12,
    .px-lg-12 {
        padding-right: 6rem !important
    }

    .pb-lg-12,
    .py-lg-12 {
        padding-bottom: 6rem !important
    }

    .pl-lg-12,
    .px-lg-12 {
        padding-left: 6rem !important
    }

    .p-lg-13 {
        padding: 8rem !important
    }

    .pt-lg-13,
    .py-lg-13 {
        padding-top: 8rem !important
    }

    .pr-lg-13,
    .px-lg-13 {
        padding-right: 8rem !important
    }

    .pb-lg-13,
    .py-lg-13 {
        padding-bottom: 8rem !important
    }

    .pl-lg-13,
    .px-lg-13 {
        padding-left: 8rem !important
    }

    .p-lg-14 {
        padding: 10rem !important
    }

    .pt-lg-14,
    .py-lg-14 {
        padding-top: 10rem !important
    }

    .pr-lg-14,
    .px-lg-14 {
        padding-right: 10rem !important
    }

    .pb-lg-14,
    .py-lg-14 {
        padding-bottom: 10rem !important
    }

    .pl-lg-14,
    .px-lg-14 {
        padding-left: 10rem !important
    }

    .p-lg-15 {
        padding: 12rem !important
    }

    .pt-lg-15,
    .py-lg-15 {
        padding-top: 12rem !important
    }

    .pr-lg-15,
    .px-lg-15 {
        padding-right: 12rem !important
    }

    .pb-lg-15,
    .py-lg-15 {
        padding-bottom: 12rem !important
    }

    .pl-lg-15,
    .px-lg-15 {
        padding-left: 12rem !important
    }

    .m-lg-n1 {
        margin: -.25rem !important
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -.25rem !important
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -.25rem !important
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -.25rem !important
    }

    .m-lg-n2 {
        margin: -.5rem !important
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -.5rem !important
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -.5rem !important
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -.5rem !important
    }

    .m-lg-n3 {
        margin: -.75rem !important
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -.75rem !important
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -.75rem !important
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -.75rem !important
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -.75rem !important
    }

    .m-lg-n4 {
        margin: -1rem !important
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1rem !important
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1rem !important
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1rem !important
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1rem !important
    }

    .m-lg-n5 {
        margin: -1.25rem !important
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -1.25rem !important
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -1.25rem !important
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -1.25rem !important
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -1.25rem !important
    }

    .m-lg-n6 {
        margin: -1.5rem !important
    }

    .mt-lg-n6,
    .my-lg-n6 {
        margin-top: -1.5rem !important
    }

    .mr-lg-n6,
    .mx-lg-n6 {
        margin-right: -1.5rem !important
    }

    .mb-lg-n6,
    .my-lg-n6 {
        margin-bottom: -1.5rem !important
    }

    .ml-lg-n6,
    .mx-lg-n6 {
        margin-left: -1.5rem !important
    }

    .m-lg-n7 {
        margin: -2rem !important
    }

    .mt-lg-n7,
    .my-lg-n7 {
        margin-top: -2rem !important
    }

    .mr-lg-n7,
    .mx-lg-n7 {
        margin-right: -2rem !important
    }

    .mb-lg-n7,
    .my-lg-n7 {
        margin-bottom: -2rem !important
    }

    .ml-lg-n7,
    .mx-lg-n7 {
        margin-left: -2rem !important
    }

    .m-lg-n8 {
        margin: -2.5rem !important
    }

    .mt-lg-n8,
    .my-lg-n8 {
        margin-top: -2.5rem !important
    }

    .mr-lg-n8,
    .mx-lg-n8 {
        margin-right: -2.5rem !important
    }

    .mb-lg-n8,
    .my-lg-n8 {
        margin-bottom: -2.5rem !important
    }

    .ml-lg-n8,
    .mx-lg-n8 {
        margin-left: -2.5rem !important
    }

    .m-lg-n9 {
        margin: -3rem !important
    }

    .mt-lg-n9,
    .my-lg-n9 {
        margin-top: -3rem !important
    }

    .mr-lg-n9,
    .mx-lg-n9 {
        margin-right: -3rem !important
    }

    .mb-lg-n9,
    .my-lg-n9 {
        margin-bottom: -3rem !important
    }

    .ml-lg-n9,
    .mx-lg-n9 {
        margin-left: -3rem !important
    }

    .m-lg-n10 {
        margin: -4rem !important
    }

    .mt-lg-n10,
    .my-lg-n10 {
        margin-top: -4rem !important
    }

    .mr-lg-n10,
    .mx-lg-n10 {
        margin-right: -4rem !important
    }

    .mb-lg-n10,
    .my-lg-n10 {
        margin-bottom: -4rem !important
    }

    .ml-lg-n10,
    .mx-lg-n10 {
        margin-left: -4rem !important
    }

    .m-lg-n11 {
        margin: -5rem !important
    }

    .mt-lg-n11,
    .my-lg-n11 {
        margin-top: -5rem !important
    }

    .mr-lg-n11,
    .mx-lg-n11 {
        margin-right: -5rem !important
    }

    .mb-lg-n11,
    .my-lg-n11 {
        margin-bottom: -5rem !important
    }

    .ml-lg-n11,
    .mx-lg-n11 {
        margin-left: -5rem !important
    }

    .m-lg-n12 {
        margin: -6rem !important
    }

    .mt-lg-n12,
    .my-lg-n12 {
        margin-top: -6rem !important
    }

    .mr-lg-n12,
    .mx-lg-n12 {
        margin-right: -6rem !important
    }

    .mb-lg-n12,
    .my-lg-n12 {
        margin-bottom: -6rem !important
    }

    .ml-lg-n12,
    .mx-lg-n12 {
        margin-left: -6rem !important
    }

    .m-lg-n13 {
        margin: -8rem !important
    }

    .mt-lg-n13,
    .my-lg-n13 {
        margin-top: -8rem !important
    }

    .mr-lg-n13,
    .mx-lg-n13 {
        margin-right: -8rem !important
    }

    .mb-lg-n13,
    .my-lg-n13 {
        margin-bottom: -8rem !important
    }

    .ml-lg-n13,
    .mx-lg-n13 {
        margin-left: -8rem !important
    }

    .m-lg-n14 {
        margin: -10rem !important
    }

    .mt-lg-n14,
    .my-lg-n14 {
        margin-top: -10rem !important
    }

    .mr-lg-n14,
    .mx-lg-n14 {
        margin-right: -10rem !important
    }

    .mb-lg-n14,
    .my-lg-n14 {
        margin-bottom: -10rem !important
    }

    .ml-lg-n14,
    .mx-lg-n14 {
        margin-left: -10rem !important
    }

    .m-lg-n15 {
        margin: -12rem !important
    }

    .mt-lg-n15,
    .my-lg-n15 {
        margin-top: -12rem !important
    }

    .mr-lg-n15,
    .mx-lg-n15 {
        margin-right: -12rem !important
    }

    .mb-lg-n15,
    .my-lg-n15 {
        margin-bottom: -12rem !important
    }

    .ml-lg-n15,
    .mx-lg-n15 {
        margin-left: -12rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important
    }
}

@media (min-width:1200px) {
    .m-xl-0 {
        margin: 0 !important
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: .25rem !important
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: .25rem !important
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: .25rem !important
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: .5rem !important
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: .5rem !important
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: .5rem !important
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: .5rem !important
    }

    .m-xl-3 {
        margin: .75rem !important
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: .75rem !important
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: .75rem !important
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: .75rem !important
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: .75rem !important
    }

    .m-xl-4 {
        margin: 1rem !important
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1rem !important
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1rem !important
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1rem !important
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1rem !important
    }

    .m-xl-5 {
        margin: 1.25rem !important
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 1.25rem !important
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 1.25rem !important
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 1.25rem !important
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 1.25rem !important
    }

    .m-xl-6 {
        margin: 1.5rem !important
    }

    .mt-xl-6,
    .my-xl-6 {
        margin-top: 1.5rem !important
    }

    .mr-xl-6,
    .mx-xl-6 {
        margin-right: 1.5rem !important
    }

    .mb-xl-6,
    .my-xl-6 {
        margin-bottom: 1.5rem !important
    }

    .ml-xl-6,
    .mx-xl-6 {
        margin-left: 1.5rem !important
    }

    .m-xl-7 {
        margin: 2rem !important
    }

    .mt-xl-7,
    .my-xl-7 {
        margin-top: 2rem !important
    }

    .mr-xl-7,
    .mx-xl-7 {
        margin-right: 2rem !important
    }

    .mb-xl-7,
    .my-xl-7 {
        margin-bottom: 2rem !important
    }

    .ml-xl-7,
    .mx-xl-7 {
        margin-left: 2rem !important
    }

    .m-xl-8 {
        margin: 2.5rem !important
    }

    .mt-xl-8,
    .my-xl-8 {
        margin-top: 2.5rem !important
    }

    .mr-xl-8,
    .mx-xl-8 {
        margin-right: 2.5rem !important
    }

    .mb-xl-8,
    .my-xl-8 {
        margin-bottom: 2.5rem !important
    }

    .ml-xl-8,
    .mx-xl-8 {
        margin-left: 2.5rem !important
    }

    .m-xl-9 {
        margin: 3rem !important
    }

    .mt-xl-9,
    .my-xl-9 {
        margin-top: 3rem !important
    }

    .mr-xl-9,
    .mx-xl-9 {
        margin-right: 3rem !important
    }

    .mb-xl-9,
    .my-xl-9 {
        margin-bottom: 3rem !important
    }

    .ml-xl-9,
    .mx-xl-9 {
        margin-left: 3rem !important
    }

    .m-xl-10 {
        margin: 4rem !important
    }

    .mt-xl-10,
    .my-xl-10 {
        margin-top: 4rem !important
    }

    .mr-xl-10,
    .mx-xl-10 {
        margin-right: 4rem !important
    }

    .mb-xl-10,
    .my-xl-10 {
        margin-bottom: 4rem !important
    }

    .ml-xl-10,
    .mx-xl-10 {
        margin-left: 4rem !important
    }

    .m-xl-11 {
        margin: 5rem !important
    }

    .mt-xl-11,
    .my-xl-11 {
        margin-top: 5rem !important
    }

    .mr-xl-11,
    .mx-xl-11 {
        margin-right: 5rem !important
    }

    .mb-xl-11,
    .my-xl-11 {
        margin-bottom: 5rem !important
    }

    .ml-xl-11,
    .mx-xl-11 {
        margin-left: 5rem !important
    }

    .m-xl-12 {
        margin: 6rem !important
    }

    .mt-xl-12,
    .my-xl-12 {
        margin-top: 6rem !important
    }

    .mr-xl-12,
    .mx-xl-12 {
        margin-right: 6rem !important
    }

    .mb-xl-12,
    .my-xl-12 {
        margin-bottom: 6rem !important
    }

    .ml-xl-12,
    .mx-xl-12 {
        margin-left: 6rem !important
    }

    .m-xl-13 {
        margin: 8rem !important
    }

    .mt-xl-13,
    .my-xl-13 {
        margin-top: 8rem !important
    }

    .mr-xl-13,
    .mx-xl-13 {
        margin-right: 8rem !important
    }

    .mb-xl-13,
    .my-xl-13 {
        margin-bottom: 8rem !important
    }

    .ml-xl-13,
    .mx-xl-13 {
        margin-left: 8rem !important
    }

    .m-xl-14 {
        margin: 10rem !important
    }

    .mt-xl-14,
    .my-xl-14 {
        margin-top: 10rem !important
    }

    .mr-xl-14,
    .mx-xl-14 {
        margin-right: 10rem !important
    }

    .mb-xl-14,
    .my-xl-14 {
        margin-bottom: 10rem !important
    }

    .ml-xl-14,
    .mx-xl-14 {
        margin-left: 10rem !important
    }

    .m-xl-15 {
        margin: 12rem !important
    }

    .mt-xl-15,
    .my-xl-15 {
        margin-top: 12rem !important
    }

    .mr-xl-15,
    .mx-xl-15 {
        margin-right: 12rem !important
    }

    .mb-xl-15,
    .my-xl-15 {
        margin-bottom: 12rem !important
    }

    .ml-xl-15,
    .mx-xl-15 {
        margin-left: 12rem !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: .25rem !important
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: .25rem !important
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: .25rem !important
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: .5rem !important
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: .5rem !important
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: .5rem !important
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: .5rem !important
    }

    .p-xl-3 {
        padding: .75rem !important
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: .75rem !important
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: .75rem !important
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: .75rem !important
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: .75rem !important
    }

    .p-xl-4 {
        padding: 1rem !important
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1rem !important
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1rem !important
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1rem !important
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1rem !important
    }

    .p-xl-5 {
        padding: 1.25rem !important
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 1.25rem !important
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 1.25rem !important
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 1.25rem !important
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 1.25rem !important
    }

    .p-xl-6 {
        padding: 1.5rem !important
    }

    .pt-xl-6,
    .py-xl-6 {
        padding-top: 1.5rem !important
    }

    .pr-xl-6,
    .px-xl-6 {
        padding-right: 1.5rem !important
    }

    .pb-xl-6,
    .py-xl-6 {
        padding-bottom: 1.5rem !important
    }

    .pl-xl-6,
    .px-xl-6 {
        padding-left: 1.5rem !important
    }

    .p-xl-7 {
        padding: 2rem !important
    }

    .pt-xl-7,
    .py-xl-7 {
        padding-top: 2rem !important
    }

    .pr-xl-7,
    .px-xl-7 {
        padding-right: 2rem !important
    }

    .pb-xl-7,
    .py-xl-7 {
        padding-bottom: 2rem !important
    }

    .pl-xl-7,
    .px-xl-7 {
        padding-left: 2rem !important
    }

    .p-xl-8 {
        padding: 2.5rem !important
    }

    .pt-xl-8,
    .py-xl-8 {
        padding-top: 2.5rem !important
    }

    .pr-xl-8,
    .px-xl-8 {
        padding-right: 2.5rem !important
    }

    .pb-xl-8,
    .py-xl-8 {
        padding-bottom: 2.5rem !important
    }

    .pl-xl-8,
    .px-xl-8 {
        padding-left: 2.5rem !important
    }

    .p-xl-9 {
        padding: 3rem !important
    }

    .pt-xl-9,
    .py-xl-9 {
        padding-top: 3rem !important
    }

    .pr-xl-9,
    .px-xl-9 {
        padding-right: 3rem !important
    }

    .pb-xl-9,
    .py-xl-9 {
        padding-bottom: 3rem !important
    }

    .pl-xl-9,
    .px-xl-9 {
        padding-left: 3rem !important
    }

    .p-xl-10 {
        padding: 4rem !important
    }

    .pt-xl-10,
    .py-xl-10 {
        padding-top: 4rem !important
    }

    .pr-xl-10,
    .px-xl-10 {
        padding-right: 4rem !important
    }

    .pb-xl-10,
    .py-xl-10 {
        padding-bottom: 4rem !important
    }

    .pl-xl-10,
    .px-xl-10 {
        padding-left: 4rem !important
    }

    .p-xl-11 {
        padding: 5rem !important
    }

    .pt-xl-11,
    .py-xl-11 {
        padding-top: 5rem !important
    }

    .pr-xl-11,
    .px-xl-11 {
        padding-right: 5rem !important
    }

    .pb-xl-11,
    .py-xl-11 {
        padding-bottom: 5rem !important
    }

    .pl-xl-11,
    .px-xl-11 {
        padding-left: 5rem !important
    }

    .p-xl-12 {
        padding: 6rem !important
    }

    .pt-xl-12,
    .py-xl-12 {
        padding-top: 6rem !important
    }

    .pr-xl-12,
    .px-xl-12 {
        padding-right: 6rem !important
    }

    .pb-xl-12,
    .py-xl-12 {
        padding-bottom: 6rem !important
    }

    .pl-xl-12,
    .px-xl-12 {
        padding-left: 6rem !important
    }

    .p-xl-13 {
        padding: 8rem !important
    }

    .pt-xl-13,
    .py-xl-13 {
        padding-top: 8rem !important
    }

    .pr-xl-13,
    .px-xl-13 {
        padding-right: 8rem !important
    }

    .pb-xl-13,
    .py-xl-13 {
        padding-bottom: 8rem !important
    }

    .pl-xl-13,
    .px-xl-13 {
        padding-left: 8rem !important
    }

    .p-xl-14 {
        padding: 10rem !important
    }

    .pt-xl-14,
    .py-xl-14 {
        padding-top: 10rem !important
    }

    .pr-xl-14,
    .px-xl-14 {
        padding-right: 10rem !important
    }

    .pb-xl-14,
    .py-xl-14 {
        padding-bottom: 10rem !important
    }

    .pl-xl-14,
    .px-xl-14 {
        padding-left: 10rem !important
    }

    .p-xl-15 {
        padding: 12rem !important
    }

    .pt-xl-15,
    .py-xl-15 {
        padding-top: 12rem !important
    }

    .pr-xl-15,
    .px-xl-15 {
        padding-right: 12rem !important
    }

    .pb-xl-15,
    .py-xl-15 {
        padding-bottom: 12rem !important
    }

    .pl-xl-15,
    .px-xl-15 {
        padding-left: 12rem !important
    }

    .m-xl-n1 {
        margin: -.25rem !important
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -.25rem !important
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -.25rem !important
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -.25rem !important
    }

    .m-xl-n2 {
        margin: -.5rem !important
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -.5rem !important
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -.5rem !important
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -.5rem !important
    }

    .m-xl-n3 {
        margin: -.75rem !important
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -.75rem !important
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -.75rem !important
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -.75rem !important
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -.75rem !important
    }

    .m-xl-n4 {
        margin: -1rem !important
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1rem !important
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1rem !important
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1rem !important
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1rem !important
    }

    .m-xl-n5 {
        margin: -1.25rem !important
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -1.25rem !important
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -1.25rem !important
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -1.25rem !important
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -1.25rem !important
    }

    .m-xl-n6 {
        margin: -1.5rem !important
    }

    .mt-xl-n6,
    .my-xl-n6 {
        margin-top: -1.5rem !important
    }

    .mr-xl-n6,
    .mx-xl-n6 {
        margin-right: -1.5rem !important
    }

    .mb-xl-n6,
    .my-xl-n6 {
        margin-bottom: -1.5rem !important
    }

    .ml-xl-n6,
    .mx-xl-n6 {
        margin-left: -1.5rem !important
    }

    .m-xl-n7 {
        margin: -2rem !important
    }

    .mt-xl-n7,
    .my-xl-n7 {
        margin-top: -2rem !important
    }

    .mr-xl-n7,
    .mx-xl-n7 {
        margin-right: -2rem !important
    }

    .mb-xl-n7,
    .my-xl-n7 {
        margin-bottom: -2rem !important
    }

    .ml-xl-n7,
    .mx-xl-n7 {
        margin-left: -2rem !important
    }

    .m-xl-n8 {
        margin: -2.5rem !important
    }

    .mt-xl-n8,
    .my-xl-n8 {
        margin-top: -2.5rem !important
    }

    .mr-xl-n8,
    .mx-xl-n8 {
        margin-right: -2.5rem !important
    }

    .mb-xl-n8,
    .my-xl-n8 {
        margin-bottom: -2.5rem !important
    }

    .ml-xl-n8,
    .mx-xl-n8 {
        margin-left: -2.5rem !important
    }

    .m-xl-n9 {
        margin: -3rem !important
    }

    .mt-xl-n9,
    .my-xl-n9 {
        margin-top: -3rem !important
    }

    .mr-xl-n9,
    .mx-xl-n9 {
        margin-right: -3rem !important
    }

    .mb-xl-n9,
    .my-xl-n9 {
        margin-bottom: -3rem !important
    }

    .ml-xl-n9,
    .mx-xl-n9 {
        margin-left: -3rem !important
    }

    .m-xl-n10 {
        margin: -4rem !important
    }

    .mt-xl-n10,
    .my-xl-n10 {
        margin-top: -4rem !important
    }

    .mr-xl-n10,
    .mx-xl-n10 {
        margin-right: -4rem !important
    }

    .mb-xl-n10,
    .my-xl-n10 {
        margin-bottom: -4rem !important
    }

    .ml-xl-n10,
    .mx-xl-n10 {
        margin-left: -4rem !important
    }

    .m-xl-n11 {
        margin: -5rem !important
    }

    .mt-xl-n11,
    .my-xl-n11 {
        margin-top: -5rem !important
    }

    .mr-xl-n11,
    .mx-xl-n11 {
        margin-right: -5rem !important
    }

    .mb-xl-n11,
    .my-xl-n11 {
        margin-bottom: -5rem !important
    }

    .ml-xl-n11,
    .mx-xl-n11 {
        margin-left: -5rem !important
    }

    .m-xl-n12 {
        margin: -6rem !important
    }

    .mt-xl-n12,
    .my-xl-n12 {
        margin-top: -6rem !important
    }

    .mr-xl-n12,
    .mx-xl-n12 {
        margin-right: -6rem !important
    }

    .mb-xl-n12,
    .my-xl-n12 {
        margin-bottom: -6rem !important
    }

    .ml-xl-n12,
    .mx-xl-n12 {
        margin-left: -6rem !important
    }

    .m-xl-n13 {
        margin: -8rem !important
    }

    .mt-xl-n13,
    .my-xl-n13 {
        margin-top: -8rem !important
    }

    .mr-xl-n13,
    .mx-xl-n13 {
        margin-right: -8rem !important
    }

    .mb-xl-n13,
    .my-xl-n13 {
        margin-bottom: -8rem !important
    }

    .ml-xl-n13,
    .mx-xl-n13 {
        margin-left: -8rem !important
    }

    .m-xl-n14 {
        margin: -10rem !important
    }

    .mt-xl-n14,
    .my-xl-n14 {
        margin-top: -10rem !important
    }

    .mr-xl-n14,
    .mx-xl-n14 {
        margin-right: -10rem !important
    }

    .mb-xl-n14,
    .my-xl-n14 {
        margin-bottom: -10rem !important
    }

    .ml-xl-n14,
    .mx-xl-n14 {
        margin-left: -10rem !important
    }

    .m-xl-n15 {
        margin: -12rem !important
    }

    .mt-xl-n15,
    .my-xl-n15 {
        margin-top: -12rem !important
    }

    .mr-xl-n15,
    .mx-xl-n15 {
        margin-right: -12rem !important
    }

    .mb-xl-n15,
    .my-xl-n15 {
        margin-bottom: -12rem !important
    }

    .ml-xl-n15,
    .mx-xl-n15 {
        margin-left: -12rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important
}

.text-justify {
    text-align: justify !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

@media (min-width:576px) {
    .text-sm-left {
        text-align: left !important
    }

    .text-sm-right {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media (min-width:768px) {
    .text-md-left {
        text-align: left !important
    }

    .text-md-right {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media (min-width:992px) {
    .text-lg-left {
        text-align: left !important
    }

    .text-lg-right {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media (min-width:1200px) {
    .text-xl-left {
        text-align: left !important
    }

    .text-xl-right {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-lighter {
    font-weight: lighter !important
}

.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-bold {
    font-weight: 500 !important
}

.font-weight-bolder {
    font-weight: 600 !important
}

.font-italic {
    font-style: italic !important
}

.text-white {
    color: #fff !important
}

.text-primary {
    color: #ff6f61 !important
}

a.text-primary:focus,
a.text-primary:hover {
    color: #ff2915 !important
}

.text-secondary {
    color: #a6a6a6 !important
}

a.text-secondary:focus,
a.text-secondary:hover {
    color: gray !important
}

.text-success {
    color: #28bb74 !important
}

a.text-success:focus,
a.text-success:hover {
    color: #1b7c4d !important
}

.text-info {
    color: #3b86ff !important
}

a.text-info:focus,
a.text-info:hover {
    color: #005bee !important
}

.text-warning {
    color: #ff9736 !important
}

a.text-warning:focus,
a.text-warning:hover {
    color: #e97000 !important
}

.text-danger {
    color: #ff6f61 !important
}

a.text-danger:focus,
a.text-danger:hover {
    color: #ff2915 !important
}

.text-light {
    color: #f5f5f5 !important
}

a.text-light:focus,
a.text-light:hover {
    color: #cfcfcf !important
}

.text-dark {
    color: #1f1f1f !important
}

a.text-dark:focus,
a.text-dark:hover {
    color: #000 !important
}

.text-body {
    color: #111 !important
}

.text-muted {
    color: #767676 !important
}

.text-black-50 {
    color: rgba(17, 17, 17, .5) !important
}

.text-white-50 {
    color: rgba(255, 255, 255, .5) !important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.text-decoration-none {
    text-decoration: none !important
}

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important
}

.text-reset {
    color: inherit !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

@media print {

    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important
    }

    a:not(.btn) {
        text-decoration: underline
    }

    abbr[title]::after {
        content: " (" attr(title) ")"
    }

    pre {
        white-space: pre-wrap !important
    }

    blockquote,
    pre {
        border: 1px solid #525252;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    img,
    tr {
        page-break-inside: avoid
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }

    h2,
    h3 {
        page-break-after: avoid
    }

    @page {
        size: a3
    }

    body {
        min-width: 992px !important
    }

    .container {
        min-width: 992px !important
    }

    .navbar {
        display: none
    }

    .badge {
        border: 1px solid #111
    }

    .table {
        border-collapse: collapse !important
    }

    .table td,
    .table th {
        background-color: #fff !important
    }

    .table-bordered td,
    .table-bordered th {
        border: 1px solid #bababa !important
    }

    .table-dark {
        color: inherit
    }

    .table-dark tbody+tbody,
    .table-dark td,
    .table-dark th,
    .table-dark thead th {
        border-color: #e5e5e5
    }

    .table .thead-dark th {
        color: inherit;
        border-color: #e5e5e5
    }
}

.badge {
    letter-spacing: .02em
}

.badge-white {
    background-color: #fff;
    color: #111
}

.breadcrumb-item+.breadcrumb-item::before {
    font-family: Feather
}

.btn-group-justified {
    display: flex;
    flex-direction: row
}

.btn-group-justified>.btn,
.btn-group-justified>.btn-group {
    width: 100%
}

.btn-group-justified>.btn-group:not(:first-child),
.btn-group-justified>.btn:not(:first-child) {
    margin-left: -1px
}

@media (max-width:-1) {
    .btn-group-justified.btn-group-expand-xs {
        flex-direction: column
    }

    .btn-group-justified.btn-group-expand-xs>.btn-group:not(:first-child),
    .btn-group-justified.btn-group-expand-xs>.btn:not(:first-child) {
        margin-top: -1px;
        margin-left: 0
    }

    .btn-group-justified.btn-group-expand-xs>.btn-group>.dropdown-menu {
        position: relative !important;
        transform: none !important;
        width: 100%
    }
}

@media (max-width:575px) {
    .btn-group-justified.btn-group-expand-sm {
        flex-direction: column
    }

    .btn-group-justified.btn-group-expand-sm>.btn-group:not(:first-child),
    .btn-group-justified.btn-group-expand-sm>.btn:not(:first-child) {
        margin-top: -1px;
        margin-left: 0
    }

    .btn-group-justified.btn-group-expand-sm>.btn-group>.dropdown-menu {
        position: relative !important;
        transform: none !important;
        width: 100%
    }
}

@media (max-width:767px) {
    .btn-group-justified.btn-group-expand-md {
        flex-direction: column
    }

    .btn-group-justified.btn-group-expand-md>.btn-group:not(:first-child),
    .btn-group-justified.btn-group-expand-md>.btn:not(:first-child) {
        margin-top: -1px;
        margin-left: 0
    }

    .btn-group-justified.btn-group-expand-md>.btn-group>.dropdown-menu {
        position: relative !important;
        transform: none !important;
        width: 100%
    }
}

@media (max-width:991px) {
    .btn-group-justified.btn-group-expand-lg {
        flex-direction: column
    }

    .btn-group-justified.btn-group-expand-lg>.btn-group:not(:first-child),
    .btn-group-justified.btn-group-expand-lg>.btn:not(:first-child) {
        margin-top: -1px;
        margin-left: 0
    }

    .btn-group-justified.btn-group-expand-lg>.btn-group>.dropdown-menu {
        position: relative !important;
        transform: none !important;
        width: 100%
    }
}

@media (max-width:1199px) {
    .btn-group-justified.btn-group-expand-xl {
        flex-direction: column
    }

    .btn-group-justified.btn-group-expand-xl>.btn-group:not(:first-child),
    .btn-group-justified.btn-group-expand-xl>.btn:not(:first-child) {
        margin-top: -1px;
        margin-left: 0
    }

    .btn-group-justified.btn-group-expand-xl>.btn-group>.dropdown-menu {
        position: relative !important;
        transform: none !important;
        width: 100%
    }
}

.btn {
    height: calc(1.5rem + 1.75rem + 2px);
    white-space: nowrap
}

.btn[data-toggle=button] {
    cursor: pointer
}

.btn:not([href]):not(button):not(label) {
    pointer-events: none
}

.btn:not([href]):not(button):not(label)>a {
    pointer-events: auto
}

.btn-group-lg>.btn,
.btn-lg {
    height: calc(1.5rem + 2.125rem + 2px)
}

.btn-group-sm>.btn,
.btn-sm {
    height: calc(1.40625rem + 1.625rem + 2px)
}

.btn-xs {
    height: calc(1.40625rem + 1rem + 2px);
    padding: .5rem .75rem;
    font-size: .9375rem
}

.btn-xxs {
    height: calc(1.40625rem + .375rem + 2px);
    padding: .1875rem .75rem;
    font-size: .9375rem
}

.btn-white {
    background-color: #fff;
    border-color: #fff
}

.btn-white:focus,
.btn-white:hover {
    background-color: #f9f9f9;
    border-color: #f9f9f9
}

.btn-white-primary {
    background-color: #fff;
    color: #111
}

.btn-white-primary:focus,
.btn-white-primary:hover {
    background-color: #ff6f61;
    border-color: #ff6f61;
    color: #fff
}

.btn-white-primary[data-toggle=button]:focus,
.btn-white-primary[data-toggle=button]:hover {
    color: #fff
}

.btn-white-primary[data-toggle=button].active {
    background-color: #fff;
    border-color: #fff;
    color: #ff6f61
}

.btn-gray-500 {
    background-color: #525252;
    border-color: #525252;
    color: #fff
}

.btn-gray-500:focus,
.btn-gray-500:hover {
    background-color: #4c4c4c;
    border-color: #4c4c4c;
    color: #fff
}

.btn-link {
    font-weight: 500;
    color: #111
}

.btn-link:focus,
.btn-link:hover {
    color: #ff6f61
}

.btn-link[data-toggle=button].active {
    color: #ff6f61
}

[class*=btn-outline] {
    color: #111
}

.btn-outline-white {
    border-color: #fff;
    color: #fff
}

.btn-outline-white:focus,
.btn-outline-white:hover {
    background-color: #fff;
    border-color: #fff;
    color: #1f1f1f
}

.btn-outline-border {
    background-color: #fff;
    border-color: #e5e5e5;
    color: #111
}

.btn-outline-border:focus,
.btn-outline-border:hover {
    background-color: #fff;
    border-color: #1f1f1f;
    color: #111
}

.btn-outline-border.active {
    border-color: #1f1f1f
}

.btn-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: calc(1.5rem + 1.75rem + 2px);
    padding: 0;
    border-radius: 50%
}

.btn-circle.btn-xxs {
    width: calc(1.40625rem + .375rem + 2px)
}

.btn-circle.btn-xs {
    width: calc(1.40625rem + 1rem + 2px)
}

.btn-circle.btn-sm,
.btn-group-sm>.btn-circle.btn {
    width: calc(1.40625rem + 1.625rem + 2px)
}

.btn-circle.btn-lg,
.btn-group-lg>.btn-circle.btn {
    width: calc(1.5rem + 2.125rem + 2px)
}

.btn-underline {
    border-width: 0 0 1px 0
}

.btn>.fe-arrow-left,
.btn>.fe-arrow-right {
    transition: all .2s ease-in-out;
    transition-property: transform
}

.btn:hover>.fe-arrow-right {
    transform: translateX(.25rem)
}

.btn:hover>.fe-arrow-left {
    transform: translateX(-.25rem)
}

.btn-facebook {
    background-color: #475a96;
    border-color: #475a96;
    color: #fff
}

.btn-facebook:focus,
.btn-facebook:hover {
    background-color: #3a497a;
    border-color: #3a497a;
    color: #fff
}

.btn-twitter {
    background-color: #49b2e8;
    border-color: #49b2e8;
    color: #fff
}

.btn-twitter:focus,
.btn-twitter:hover {
    background-color: #25a3e3;
    border-color: #25a3e3;
    color: #fff
}

.btn-pinterest {
    background-color: #d74047;
    border-color: #d74047;
    color: #fff
}

.btn-pinterest:focus,
.btn-pinterest:hover {
    background-color: #c52930;
    border-color: #c52930;
    color: #fff
}

.btn-linkedin {
    background-color: #0d76b4;
    border-color: #0d76b4;
    color: #fff
}

.btn-linkedin:focus,
.btn-linkedin:hover {
    background-color: #0a5d8e;
    border-color: #0a5d8e;
    color: #fff
}

.btn-hover {
    position: relative
}

.btn-hover>* {
    position: inherit
}

.btn-hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    transition: inherit;
    transition-property: transform
}

.btn-hover:hover::before {
    transform: scale(1.15)
}

.card>* {
    flex-shrink: 0
}

.card .card-footer {
    padding: 1.5rem
}

.card-xl .card-body,
.card-xl .card-footer {
    padding: 2.5rem
}

.card-lg .card-body,
.card-lg .card-footer {
    padding: 2rem
}

.card-sm .card-body,
.card-sm .card-footer {
    padding: 1.25rem
}

.card-body {
    flex: 0 0 auto
}

.card-footer {
    background-color: transparent
}

.card-img-overlay {
    display: flex
}

.card-img-overlay>* {
    flex: 1
}

a.card-img-overlay {
    color: inherit
}

a.card-img-overlay:hover {
    text-decoration: none
}

.card-img-overlay-hover {
    opacity: 0;
    transition: opacity .2s ease-in-out
}

.card:hover .card-img-overlay-hover {
    opacity: 1
}

.card-img {
    position: relative
}

.card-img-hover {
    display: block
}

.card-img-hover>* {
    float: left
}

.card-img-hover .card-img-top {
    transition: opacity .2s ease-in-out
}

.card-img-hover .card-img-back {
    opacity: 0
}

.card-img-hover .card-img-front {
    margin-left: -100%
}

.card-img:hover .card-img-hover .card-img-back {
    opacity: 1
}

.card-img:hover .card-img-hover .card-img-front {
    opacity: 0
}

@media screen and (min-width: 1200px) {
    .card-img-hover .card-img-top {
        height: 350px;
    }

    .card-img-hover .card-img-back {
        height: 350px;
    }

    .card-img:hover .card-img-hover .card-img-back {
        height: 350px;
    }

    .card-img:hover .card-img-hover .card-img-front {
        height: 350px;
    }

}

@media screen and (min-width: 992px) and (max-device-width: 1200px) {
    .card-img-hover .card-img-top {
        height: 250px;
    }

    .card-img-hover .card-img-back {
        height: 250px;
    }

    .card-img:hover .card-img-hover .card-img-back {
        height: 250px;
    }

    .card-img:hover .card-img-hover .card-img-front {
        height: 250px;
    }

}

@media screen and (min-device-width: 768px) and (max-device-width: 992px) {

    .card-img-hover .card-img-top {
        height: 150px;
    }

    .card-img-hover .card-img-back {
        height: 150px;
    }

    .card-img:hover .card-img-hover .card-img-back {
        height: 150px;
    }

    .card-img:hover .card-img-hover .card-img-front {
        height: 150px;
    }

}

@media screen and (max-device-width: 640px) and (orientation: landscape) {
    .card-img-hover .card-img-top {
        height: 100px;
    }

    .card-img-hover .card-img-back {
        height: 100px;
    }

    .card-img:hover .card-img-hover .card-img-back {
        height: 100px;
    }

    .card-img:hover .card-img-hover .card-img-front {
        height: 100px;
    }
}

@media screen and (max-device-width: 640px) and (orientation: portrait) {
    .card-img-hover .card-img-top {
        height: 300px;
    }

    .card-img-hover .card-img-back {
        height: 300px;
    }

    .card-img:hover .card-img-hover .card-img-back {
        height: 300px;
    }

    .card-img:hover .card-img-hover .card-img-front {
        height: 300px;
    }
}

@media screen and (max-device-width: 480px) and (orientation: portrait) {
    .card-img-hover .card-img-top {
        height: 200px;
    }

    .card-img-hover .card-img-back {
        height: 200px;
    }

    .card-img:hover .card-img-hover .card-img-back {
        height: 200px;
    }

    .card-img:hover .card-img-hover .card-img-front {
        height: 200px;
    }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
    .card-img-hover .card-img-top {
        height: 200px;
    }

    .card-img-hover .card-img-back {
        height: 200px;
    }

    .card-img:hover .card-img-hover .card-img-back {
        height: 200px;
    }

    .card-img:hover .card-img-hover .card-img-front {
        height: 200px;
    }
}

@media screen and (max-device-width: 320px) {
    .card-img-hover .card-img-top {
        height: 180px;
    }

    .card-img-hover .card-img-back {
        height: 180px;
    }

    .card-img:hover .card-img-hover .card-img-back {
        height: 180px;
    }

    .card-img:hover .card-img-hover .card-img-front {
        height: 180px;
    }
}


/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
    .card-img-hover .card-img-top {
        height: 200px;
    }

    .card-img-hover .card-img-back {
        height: 200px;
    }

    .card-img:hover .card-img-hover .card-img-back {
        height: 200px;
    }

    .card-img:hover .card-img-hover .card-img-front {
        height: 200px;
    }
}

@media (device-height: 720px) and (device-width: 540px) {
    .card-img-hover .card-img-top {
        height: 300px;
    }

    .card-img-hover .card-img-back {
        height: 300px;
    }

    .card-img:hover .card-img-hover .card-img-back {
        height: 300px;
    }

    .card-img:hover .card-img-hover .card-img-front {
        height: 200px;
    }
}



.card-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden
}

.card-bg~* {
    z-index: 1
}

.card-bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + .25rem);
    height: 100%;
    transition: all .2s ease-in-out;
    transition-property: transform
}

.card:hover>.card-bg>.card-bg-img {
    transform: translateX(-.25rem)
}

.card-circle {
    position: absolute;
    top: 1.25rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    background-color: #ff6f61;
    border-radius: 50%;
    color: #fff
}

.card-circle-lg {
    width: 96px;
    height: 96px
}

.card-circle-sm {
    width: 34px;
    height: 34px
}

.card-circle-left {
    left: 1.25rem
}

.card-circle-right {
    right: 1.25rem
}

.card-circle-top-right {
    top: 0;
    right: 0
}

.card-badge {
    position: absolute;
    top: 1.25rem;
    z-index: 1
}

.card-badge-left {
    left: 0
}

.card-badge-right {
    right: 0
}

.card-price {
    position: absolute;
    top: 0;
    z-index: 1
}

.card-price-left {
    left: 0
}

.card-price-right {
    right: 0
}

.card-price-left+.card-badge-left {
    top: 3.75rem
}

.card-price-right+.card-badge-right {
    top: 3.75rem
}

.card-actions {
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1.25rem
}

.card-actions .card-action {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0;
    transform: translateY(.5rem);
    transition: all .1s ease-in-out;
    transition-property: transform, opacity
}

.card-actions .card-action:nth-child(2) {
    transition-delay: 75ms
}

.card-actions .card-action:nth-child(3) {
    transition-delay: .15s
}

.card-actions .card-action+.card-action {
    margin-left: .5rem
}

.card-img:hover .card-actions .card-action {
    opacity: 1;
    transform: translateY(0)
}

.card-action {
    position: absolute;
    top: 1.25rem;
    z-index: 1
}

.card-action-left {
    left: 1.25rem
}

.card-action-right {
    right: 1.25rem
}

.card-btn {
    position: absolute;
    bottom: 0;
    opacity: 0;
    transition: opacity .2s ease-in-out
}

.card-img:hover .card-btn {
    opacity: 1
}

.card-collapse {
    position: absolute;
    top: 100%;
    width: 100%
}

.card-collapse-parent {
    position: relative;
    transition: transform .35s ease
}

:not(.toast-header):not(.alert)>.close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 1030;
    display: inline-flex
}

.close {
    transition: all .1s ease-in-out;
    transition-property: color
}

.custom-control {
    padding-left: 0
}

.custom-control .custom-control-label {
    color: #767676;
    cursor: pointer
}

.custom-control .custom-control-label::after,
.custom-control .custom-control-label::before {
    top: calc((1em * 1.5 - 1.125rem)/ 2)
}

.custom-control .custom-control-input:checked~.custom-control-label {
    color: #111
}

.custom-control .custom-control-input:disabled~.custom-control-label {
    text-decoration: line-through
}

.custom-checkbox .custom-control-label,
.custom-radio .custom-control-label {
    padding-left: 2rem
}

.custom-checkbox .custom-control-label::after,
.custom-checkbox .custom-control-label::before,
.custom-radio .custom-control-label::after,
.custom-radio .custom-control-label::before {
    left: 0
}

.custom-checkbox .custom-control-label:empty,
.custom-radio .custom-control-label:empty {
    padding-left: 1.125rem
}

.custom-control-color .custom-control-label {
    padding-left: 2.25rem
}

.custom-control-color .custom-control-label::after,
.custom-control-color .custom-control-label::before {
    left: 0;
    top: calc((1em * 1.5 - 1.375rem)/ 2);
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 50%
}

.custom-control-color .custom-control-label::before {
    background-color: currentColor !important;
    color: inherit !important
}

.custom-control-color .custom-control-label:empty {
    padding-left: 1.375rem
}

.custom-control-color .custom-control-input:checked~.custom-control-label::before {
    background-color: currentColor
}

.custom-control-color .custom-control-input:checked~.custom-control-label::after {
    background-color: rgba(0, 0, 0, .05);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3e%3cpath fill='none' stroke='%23fff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' d='M9 1L3.5 7L1 4.27273'/%3e%3c/svg%3e")
}

.custom-control-color .custom-control-input:disabled~.custom-control-label {
    text-decoration: none
}

.custom-control-color .custom-control-input:disabled~.custom-control-label>span {
    color: #a6a6a6 !important;
    text-decoration: line-through
}

.custom-control-text .custom-control-label::after,
.custom-control-text .custom-control-label::before {
    display: none
}

.custom-control-img .custom-control-label {
    width: 70px;
    opacity: .8
}

.custom-control-img .custom-control-label::before {
    display: none
}

.custom-control-img .custom-control-label::after {
    top: auto;
    bottom: 0;
    left: 0;
    display: none;
    width: 100%;
    border-bottom: 2px solid #111
}

.custom-control-img .custom-control-input:checked~.custom-control-label {
    opacity: 1
}

.custom-control-img .custom-control-input:checked~.custom-control-label::after {
    display: block
}

.custom-control-size .custom-control-label {
    min-width: 3rem;
    padding: .5rem;
    text-align: center;
    border: 1px solid #ddd
}

.custom-control-size .custom-control-label::after,
.custom-control-size .custom-control-label::before {
    display: none
}

.custom-control-size .custom-control-input:checked~.custom-control-label {
    border-color: currentColor
}

.custom-control-size .custom-control-input[disabled]~.custom-control-label {
    background-color: #f5f5f5
}

.custom-select-xs {
    height: calc(1.40625rem + 1rem + 2px);
    padding: .5rem 2.75rem .5rem .75rem;
    font-size: .9375rem;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23525252' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e") no-repeat right .75rem center/1rem 1rem
}

.custom-select-xxs {
    height: calc(1.40625rem + .375rem + 2px);
    padding: .1875rem 2.5rem .1875rem .5rem;
    font-size: .9375rem;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpolyline fill='none' stroke='%23525252' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' points='6 9 12 15 18 9'/%3e%3c/svg%3e") no-repeat right .5rem center/1rem 1rem
}

.custom-switch .custom-control-label {
    padding-left: 3rem
}

.custom-switch .custom-control-label::before {
    left: 0;
    border: 1px solid #ff6f61;
    background-color: transparent
}

.custom-switch .custom-control-label::after {
    top: .3125rem;
    left: .125rem;
    background-color: #ff6f61
}

.custom-switch .custom-control-input:focus~.custom-control-label::before {
    border-color: #ff6f61
}

.custom-switch .custom-control-input:active:not(:checked):not(:disabled)~.custom-control-label::before {
    border-color: #ff6f61;
    background-color: transparent
}

.custom-switch .custom-control-input:checked~.custom-control-label::before {
    border-color: #ff6f61;
    background-color: #ff6f61
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff
}

.custom-switch .custom-control-input:disabled~.custom-control-label {
    opacity: .65
}

.custom-switch .custom-control-input:disabled~.custom-control-label::before {
    background-color: transparent
}

.dropdown {
    display: inline-block
}

.dropdown-menu {
    background-clip: border-box
}

.dropdown-menu .card-lg .card-body .dropright {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: -2rem;
    margin-right: -2rem
}

.position-static .dropdown-menu .card-body {
    padding-left: 0;
    padding-right: 0
}

.dropdown-item {
    font-size: .875rem;
    transition-property: color, background-color
}

.dropdown-item+.dropdown-item {
    border-top: 1px solid #e5e5e5
}

.dropdown-menu-lg .dropdown-item {
    padding: .875rem 2rem;
    font-size: .9375rem
}

.dropdown-toggle,
.dropright-toggle {
    overflow: hidden
}

.dropdown-toggle::after,
.dropright-toggle::after {
    float: right;
    margin-left: .255em;
    font-family: Feather;
    border: 0
}

.dropdown-toggle::after {
    content: "\e92e"
}

.dropright-toggle::after {
    content: "\e930"
}

.dropdown-toggle[data-toggle=collapse]::after {
    transition: all .1s ease-in-out;
    transform-origin: center center
}

.dropdown-toggle[data-toggle=collapse][aria-expanded=true]::after {
    transform: rotate(180deg)
}

.form-control {
    background-clip: border-box;
    transition: all .1s ease-in-out;
    transition-property: border
}

.form-group-overflow {
    max-height: 250px;
    overflow: hidden
}

.form-control-xs {
    height: calc(.9375rem * 1.5 + .5rem * 2 + 2px);
    padding: .5rem .75rem;
    font-size: .9375rem
}

.form-control-xxs {
    height: calc(.9375rem * 1.5 + .1875rem * 2 + 2px);
    padding: .1875rem .5rem;
    font-size: .9375rem
}

.form-control-gray-700 {
    background-color: #303030;
    border-color: #303030;
    color: #fff
}

.form-control-gray-700::-webkit-input-placeholder {
    color: #a6a6a6
}

.form-control-gray-700::-moz-placeholder {
    color: #a6a6a6
}

.form-control-gray-700:-ms-input-placeholder {
    color: #a6a6a6
}

.form-control-gray-700::-ms-input-placeholder {
    color: #a6a6a6
}

.form-control-gray-700::placeholder {
    color: #a6a6a6
}

.form-control-gray-700:focus {
    background-color: #303030;
    border-color: #303030;
    color: #fff
}

.form-control-light {
    background-color: transparent;
    border-color: #fff;
    color: #fff
}

.form-control-light::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .75)
}

.form-control-light::-moz-placeholder {
    color: rgba(255, 255, 255, .75)
}

.form-control-light:-ms-input-placeholder {
    color: rgba(255, 255, 255, .75)
}

.form-control-light::-ms-input-placeholder {
    color: rgba(255, 255, 255, .75)
}

.form-control-light::placeholder {
    color: rgba(255, 255, 255, .75)
}

.form-control-light:focus,
.form-control-light:hover {
    background-color: transparent;
    border-color: #fff;
    color: #fff
}

.form-control-dark {
    background-color: rgba(31, 31, 31, .4);
    border-color: transparent;
    color: #fff
}

.form-control-dark::-webkit-input-placeholder {
    color: #fff
}

.form-control-dark::-moz-placeholder {
    color: #fff
}

.form-control-dark:-ms-input-placeholder {
    color: #fff
}

.form-control-dark::-ms-input-placeholder {
    color: #fff
}

.form-control-dark::placeholder {
    color: #fff
}

.form-control-dark:focus,
.form-control-dark:hover {
    background-color: rgba(31, 31, 31, .4);
    border-color: transparent;
    color: #fff
}

.form-control-underline {
    border-width: 0 0 1px 0
}

.container-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center
}

.container-cover>[class*=container],
.container-cover>[class*=container]>.row {
    height: inherit
}

.offset-n1 {
    margin-left: -8.33333%
}

.offset-n2 {
    margin-left: -16.66667%
}

.offset-n3 {
    margin-left: -25%
}

.offset-n4 {
    margin-left: -33.33333%
}

.offset-n5 {
    margin-left: -41.66667%
}

.offset-n6 {
    margin-left: -50%
}

.offset-n7 {
    margin-left: -58.33333%
}

.offset-n8 {
    margin-left: -66.66667%
}

.offset-n9 {
    margin-left: -75%
}

.offset-n10 {
    margin-left: -83.33333%
}

.offset-n11 {
    margin-left: -91.66667%
}

.offset-n12 {
    margin-left: -100%
}

@media (min-width:576px) {
    .offset-sm-n1 {
        margin-left: -8.33333%
    }

    .offset-sm-n2 {
        margin-left: -16.66667%
    }

    .offset-sm-n3 {
        margin-left: -25%
    }

    .offset-sm-n4 {
        margin-left: -33.33333%
    }

    .offset-sm-n5 {
        margin-left: -41.66667%
    }

    .offset-sm-n6 {
        margin-left: -50%
    }

    .offset-sm-n7 {
        margin-left: -58.33333%
    }

    .offset-sm-n8 {
        margin-left: -66.66667%
    }

    .offset-sm-n9 {
        margin-left: -75%
    }

    .offset-sm-n10 {
        margin-left: -83.33333%
    }

    .offset-sm-n11 {
        margin-left: -91.66667%
    }

    .offset-sm-n12 {
        margin-left: -100%
    }
}

@media (min-width:768px) {
    .offset-md-n1 {
        margin-left: -8.33333%
    }

    .offset-md-n2 {
        margin-left: -16.66667%
    }

    .offset-md-n3 {
        margin-left: -25%
    }

    .offset-md-n4 {
        margin-left: -33.33333%
    }

    .offset-md-n5 {
        margin-left: -41.66667%
    }

    .offset-md-n6 {
        margin-left: -50%
    }

    .offset-md-n7 {
        margin-left: -58.33333%
    }

    .offset-md-n8 {
        margin-left: -66.66667%
    }

    .offset-md-n9 {
        margin-left: -75%
    }

    .offset-md-n10 {
        margin-left: -83.33333%
    }

    .offset-md-n11 {
        margin-left: -91.66667%
    }

    .offset-md-n12 {
        margin-left: -100%
    }
}

@media (min-width:992px) {
    .offset-lg-n1 {
        margin-left: -8.33333%
    }

    .offset-lg-n2 {
        margin-left: -16.66667%
    }

    .offset-lg-n3 {
        margin-left: -25%
    }

    .offset-lg-n4 {
        margin-left: -33.33333%
    }

    .offset-lg-n5 {
        margin-left: -41.66667%
    }

    .offset-lg-n6 {
        margin-left: -50%
    }

    .offset-lg-n7 {
        margin-left: -58.33333%
    }

    .offset-lg-n8 {
        margin-left: -66.66667%
    }

    .offset-lg-n9 {
        margin-left: -75%
    }

    .offset-lg-n10 {
        margin-left: -83.33333%
    }

    .offset-lg-n11 {
        margin-left: -91.66667%
    }

    .offset-lg-n12 {
        margin-left: -100%
    }
}

@media (min-width:1200px) {
    .offset-xl-n1 {
        margin-left: -8.33333%
    }

    .offset-xl-n2 {
        margin-left: -16.66667%
    }

    .offset-xl-n3 {
        margin-left: -25%
    }

    .offset-xl-n4 {
        margin-left: -33.33333%
    }

    .offset-xl-n5 {
        margin-left: -41.66667%
    }

    .offset-xl-n6 {
        margin-left: -50%
    }

    .offset-xl-n7 {
        margin-left: -58.33333%
    }

    .offset-xl-n8 {
        margin-left: -66.66667%
    }

    .offset-xl-n9 {
        margin-left: -75%
    }

    .offset-xl-n10 {
        margin-left: -83.33333%
    }

    .offset-xl-n11 {
        margin-left: -91.66667%
    }

    .offset-xl-n12 {
        margin-left: -100%
    }
}

.img-comp {
    position: relative
}

.img-comp-back .img-comp-img {
    max-width: 100%;
    height: auto
}

.img-comp-front {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    max-width: 50%;
    border-right: 2px solid #fff;
    overflow: hidden
}

.img-comp-front .img-comp-img {
    max-height: 100%;
    width: auto
}

.img-comp-handle {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%)
}

.img-comp-input {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0
}

.img-comp-input::-ms-tooltip {
    display: none
}

.input-group-merge .form-control {
    border-right-width: 0
}

.input-group-merge .input-group-append>* {
    border-left-width: 0;
    transition: all .1s ease-in-out;
    transition-property: border
}

.input-group-merge .form-control~.input-group-append>* {
    border-color: #e5e5e5 !important
}

.input-group-merge .form-control:focus~.input-group-append>* {
    border-color: #1f1f1f !important
}

.input-group-merge .form-control-light~.input-group-append>* {
    border-color: #fff !important
}

.input-group-merge .form-control-light:focus~.input-group-append>* {
    border-color: #fff !important
}

.input-group .form-control-sm~.input-group-append .input-group-text {
    padding: .8125rem 1.25rem;
    font-size: .9375rem
}

.list-group-item {
    flex-shrink: 0
}

.list-group-lg .list-group-item {
    padding: 2rem 2rem
}

.list-group-sm .list-group-item {
    padding: 1.25rem 1.25rem
}

.list-group-flush .list-group-item {
    border: 0
}

.list-group-flush .list-group-item+.list-group-item {
    border-top: 1px solid #e5e5e5
}

.list-group-flush-y .list-group-item:first-child {
    padding-top: 0;
    border-top: none
}

.list-group-flush-y .list-group-item:last-child {
    padding-bottom: 0;
    border-bottom: 0
}

.list-group-flush-x .list-group-item {
    padding-left: 0;
    padding-right: 0;
    border-left: none;
    border-right: none
}

.list-group-strong .list-group-item.active {
    font-weight: 500
}

.modal-content>* {
    flex-shrink: 0
}

.modal-body {
    flex-grow: 0
}

.modal-header {
    padding: 1.5rem 2rem
}

.modal-footer {
    justify-content: flex-start;
    padding: 1.5rem 2rem;
    border-width: 0
}

.modal-dialog-vertical {
    height: 100%;
    max-width: 470px;
    margin: 0
}

.modal-dialog-vertical .modal-content {
    height: inherit;
    overflow-y: auto
}

.modal.fade .modal-dialog-vertical {
    transform: translateX(-100%)
}

.modal.show .modal-dialog-vertical {
    transform: translateX(0)
}

.modal.fixed-right {
    padding-right: 0 !important
}

.modal.fixed-right .modal-dialog-vertical {
    margin-left: auto
}

.modal.fixed-right.fade .modal-dialog-vertical {
    transform: translateX(100%)
}

.modal.fixed-right.show .modal-dialog-vertical {
    transform: translateX(0)
}

.nav-link {
    font-weight: 500;
    color: #111
}

.nav-link.active {
    color: #ff6f61
}

.nav-link.active:focus,
.nav-link.active:hover {
    color: #ff6f61
}

.nav-link:hover {
    color: #ff6f61
}

.nav-link.active {
    position: relative
}

.nav-link.active::before {
    content: "";
    position: absolute;
    left: .75rem;
    right: .75rem;
    bottom: .25rem;
    border-top: 1px solid #ff6f61
}

.nav-unstyled .nav-link.active::before {
    display: none
}

.nav-vertical {
    flex-direction: column
}

.nav-vertical .nav-link {
    padding-left: 0;
    padding-right: 0
}

.nav-vertical .nav-link.active::before {
    display: none
}

.nav-divided .nav-item+.nav-item {
    position: relative
}

.nav-divided .nav-item+.nav-item::before {
    content: "";
    position: absolute;
    top: 50%;
    height: .75rem;
    border-width: 0 0 0 1px;
    border-style: solid;
    border-color: #e5e5e5;
    transform: translateY(-50%)
}

.nav.nav-tabs .nav-link {
    padding: .875rem 0
}

.nav.nav-tabs .nav-link+.nav-link {
    margin-left: 2rem
}

.nav.nav-tabs .nav-link::before {
    left: 0;
    right: 0;
    bottom: -1px !important
}

.nav-overflow {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 1px
}

.nav-overflow::-webkit-scrollbar {
    display: none
}

.nav-overflow .nav-link {
    white-space: nowrap
}

.navbar-brand {
    font-weight: 600
}

@media (max-width:991.98px) {
    .navbar-brand {
        font-size: 1.5rem
    }
}

.navbar-nav .nav-link {
    font-weight: 500;
    outline: 0;
    box-shadow: none
}

.navbar-nav .dropdown-menu [class*=container] {
    display: block
}

.navbar-nav>.dropdown.position-static>.dropdown-menu::before {
    content: '';
    position: absolute;
    bottom: 100%;
    height: calc(1.5rem + 1px);
    width: 100%
}

[data-cart-items] {
    position: relative;
    display: inline-block
}

[data-cart-items]::before {
    content: attr(data-cart-items);
    position: absolute;
    top: -.5rem;
    right: -.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 19px;
    font-size: 11px;
    font-weight: 500;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: #ff6f61;
    color: #fff
}

.navbar-topbar {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: .875rem
}

.navbar-topbar.navbar-light .nav-link {
    color: #111
}

.navbar-topbar.navbar-light .nav-link:focus,
.navbar-topbar.navbar-light .nav-link:hover {
    color: #303030
}

.navbar-fashion {
    padding-top: 0;
    padding-bottom: 0
}

@media (min-width:576px) {
    .navbar-fashion .navbar-form {
        min-width: 300px
    }
}

.navbar-fashion .navbar-form .btn,
.navbar-fashion .navbar-form .form-control {
    height: 4rem;
    background-color: #303030;
    border: 0
}

.navbar-fashion .navbar-form .form-control {
    padding-right: 0;
    color: #fff
}

.navbar-fashion .navbar-form .form-control::-webkit-input-placeholder {
    color: #bababa
}

.navbar-fashion .navbar-form .form-control::-moz-placeholder {
    color: #bababa
}

.navbar-fashion .navbar-form .form-control:-ms-input-placeholder {
    color: #bababa
}

.navbar-fashion .navbar-form .form-control::-ms-input-placeholder {
    color: #bababa
}

.navbar-fashion .navbar-form .form-control::placeholder {
    color: #bababa
}

.navbar-fashion .navbar-form .btn:focus,
.navbar-fashion .navbar-form .btn:hover {
    background-color: #303030
}

.navbar-fashion.navbar-dark .nav-link {
    color: #ccc
}

.navbar-fashion.navbar-dark .nav-link:focus,
.navbar-fashion.navbar-dark .nav-link:hover {
    color: #fff
}

.navbar-fashion.navbar-dark .nav-link.active {
    color: #fff
}

.navbar-fashion.navbar-dark .nav-link::before {
    border-color: currentColor !important
}

@media (max-width:575.98px) {
    .navbar-fashion .navbar-collapse {
        margin-left: -1rem;
        margin-right: -1rem
    }
}

.navbar-boxed {
    padding-top: 0;
    padding-bottom: 0
}

.navbar-boxed .container {
    align-items: stretch
}

.navbar-boxed .navbar-form {
    padding-left: 0;
    padding-right: 0
}

.navbar-boxed .dropdown-collapse {
    display: block !important;
    width: 100%;
    border: 0
}

@media (max-width:-1px) {
    .navbar.navbar-expand-xs .navbar-collapse::before {
        content: '';
        display: block;
        margin-top: .5rem
    }

    .navbar.navbar-expand-xs .navbar-collapse .navbar-nav.flex-row>.nav-item+.nav-item {
        margin-left: 1rem
    }

    .navbar.navbar-expand-xs .navbar-collapse .navbar-nav>.nav-item>.nav-link.active::before {
        display: none
    }

    .navbar.navbar-expand-xs .navbar-collapse .nav-divided>.nav-item::before {
        display: none
    }

    .navbar.navbar-expand-xs .navbar-collapse .dropdown.position-static {
        position: relative !important
    }

    .navbar.navbar-expand-xs .navbar-collapse .dropdown-menu {
        min-width: 0 !important;
        border: 0;
        background-color: transparent
    }

    .navbar.navbar-expand-xs .navbar-collapse .dropdown-item {
        padding-left: .625rem;
        padding-right: .625rem;
        border: 0
    }

    .navbar.navbar-expand-xs .navbar-collapse .dropdown .dropdown-menu .card-body {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .navbar.navbar-expand-xs .navbar-collapse .dropdown:not(.position-static) .dropdown-menu .card-body {
        padding: 1rem
    }

    .navbar.navbar-expand-xs.navbar-fashion .navbar-collapse {
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: #2b2b2b
    }

    .navbar.navbar-expand-xs.navbar-fashion .navbar-collapse::after {
        content: '';
        display: block;
        margin-top: .5rem
    }

    .navbar.navbar-expand-xs.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card {
        background-color: transparent;
        color: #fff
    }

    .navbar.navbar-expand-xs.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link {
        color: #ccc
    }

    .navbar.navbar-expand-xs.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link.active {
        color: #fff
    }

    .navbar.navbar-expand-xs.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link:focus,
    .navbar.navbar-expand-xs.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link:hover {
        color: #fff
    }

    .navbar.navbar-expand-xs.navbar-boxed .dropdown-collapse {
        top: 0
    }

    .navbar.navbar-expand-xs.navbar-boxed .dropdown-collapse .card {
        box-shadow: inset 0 0 0 1px #e5e5e5 !important
    }
}

.navbar:not(.navbar-vertical).navbar-expand-xs .navbar-nav:first-child {
    margin-left: -1rem
}

.navbar:not(.navbar-vertical).navbar-expand-xs .navbar-nav:last-child {
    margin-right: -1rem
}

.navbar-vertical.navbar-expand-xs {
    width: 335px
}

.navbar-vertical.navbar-expand-xs>.container-fluid,
.navbar-vertical.navbar-expand-xs>.container-lg,
.navbar-vertical.navbar-expand-xs>.container-md,
.navbar-vertical.navbar-expand-xs>.container-sm,
.navbar-vertical.navbar-expand-xs>.container-xl {
    flex-direction: column;
    align-items: flex-start
}

.navbar-vertical.navbar-expand-xs>.container-fluid>*,
.navbar-vertical.navbar-expand-xs>.container-lg>*,
.navbar-vertical.navbar-expand-xs>.container-md>*,
.navbar-vertical.navbar-expand-xs>.container-sm>*,
.navbar-vertical.navbar-expand-xs>.container-xl>* {
    min-width: 100%
}

.navbar-vertical.navbar-expand-xs .navbar-collapse {
    flex-direction: column
}

.navbar-vertical.navbar-expand-xs .navbar-collapse>* {
    min-width: 100%
}

.navbar-vertical.navbar-expand-xs .navbar-nav {
    flex-direction: column
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link.active::before {
    display: none
}

@media (max-width:575px) {
    .navbar.navbar-expand-sm .navbar-collapse::before {
        content: '';
        display: block;
        margin-top: .5rem
    }

    .navbar.navbar-expand-sm .navbar-collapse .navbar-nav.flex-row>.nav-item+.nav-item {
        margin-left: 1rem
    }

    .navbar.navbar-expand-sm .navbar-collapse .navbar-nav>.nav-item>.nav-link.active::before {
        display: none
    }

    .navbar.navbar-expand-sm .navbar-collapse .nav-divided>.nav-item::before {
        display: none
    }

    .navbar.navbar-expand-sm .navbar-collapse .dropdown.position-static {
        position: relative !important
    }

    .navbar.navbar-expand-sm .navbar-collapse .dropdown-menu {
        min-width: 0 !important;
        border: 0;
        background-color: transparent
    }

    .navbar.navbar-expand-sm .navbar-collapse .dropdown-item {
        padding-left: .625rem;
        padding-right: .625rem;
        border: 0
    }

    .navbar.navbar-expand-sm .navbar-collapse .dropdown .dropdown-menu .card-body {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .navbar.navbar-expand-sm .navbar-collapse .dropdown:not(.position-static) .dropdown-menu .card-body {
        padding: 1rem
    }

    .navbar.navbar-expand-sm.navbar-fashion .navbar-collapse {
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: #2b2b2b
    }

    .navbar.navbar-expand-sm.navbar-fashion .navbar-collapse::after {
        content: '';
        display: block;
        margin-top: .5rem
    }

    .navbar.navbar-expand-sm.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card {
        background-color: transparent;
        color: #fff
    }

    .navbar.navbar-expand-sm.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link {
        color: #ccc
    }

    .navbar.navbar-expand-sm.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link.active {
        color: #fff
    }

    .navbar.navbar-expand-sm.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link:focus,
    .navbar.navbar-expand-sm.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link:hover {
        color: #fff
    }

    .navbar.navbar-expand-sm.navbar-boxed .dropdown-collapse {
        top: 0
    }

    .navbar.navbar-expand-sm.navbar-boxed .dropdown-collapse .card {
        box-shadow: inset 0 0 0 1px #e5e5e5 !important
    }
}

@media (min-width:576px) {
    .navbar:not(.navbar-vertical).navbar-expand-sm .navbar-nav:first-child {
        margin-left: -1rem
    }

    .navbar:not(.navbar-vertical).navbar-expand-sm .navbar-nav:last-child {
        margin-right: -1rem
    }
}

@media (min-width:576px) {
    .navbar-vertical.navbar-expand-sm {
        width: 335px
    }

    .navbar-vertical.navbar-expand-sm>.container-fluid,
    .navbar-vertical.navbar-expand-sm>.container-lg,
    .navbar-vertical.navbar-expand-sm>.container-md,
    .navbar-vertical.navbar-expand-sm>.container-sm,
    .navbar-vertical.navbar-expand-sm>.container-xl {
        flex-direction: column;
        align-items: flex-start
    }

    .navbar-vertical.navbar-expand-sm>.container-fluid>*,
    .navbar-vertical.navbar-expand-sm>.container-lg>*,
    .navbar-vertical.navbar-expand-sm>.container-md>*,
    .navbar-vertical.navbar-expand-sm>.container-sm>*,
    .navbar-vertical.navbar-expand-sm>.container-xl>* {
        min-width: 100%
    }

    .navbar-vertical.navbar-expand-sm .navbar-collapse {
        flex-direction: column
    }

    .navbar-vertical.navbar-expand-sm .navbar-collapse>* {
        min-width: 100%
    }

    .navbar-vertical.navbar-expand-sm .navbar-nav {
        flex-direction: column
    }

    .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link {
        padding-left: 0;
        padding-right: 0
    }

    .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link.active::before {
        display: none
    }
}

@media (max-width:767px) {
    .navbar.navbar-expand-md .navbar-collapse::before {
        content: '';
        display: block;
        margin-top: .5rem
    }

    .navbar.navbar-expand-md .navbar-collapse .navbar-nav.flex-row>.nav-item+.nav-item {
        margin-left: 1rem
    }

    .navbar.navbar-expand-md .navbar-collapse .navbar-nav>.nav-item>.nav-link.active::before {
        display: none
    }

    .navbar.navbar-expand-md .navbar-collapse .nav-divided>.nav-item::before {
        display: none
    }

    .navbar.navbar-expand-md .navbar-collapse .dropdown.position-static {
        position: relative !important
    }

    .navbar.navbar-expand-md .navbar-collapse .dropdown-menu {
        min-width: 0 !important;
        border: 0;
        background-color: transparent
    }

    .navbar.navbar-expand-md .navbar-collapse .dropdown-item {
        padding-left: .625rem;
        padding-right: .625rem;
        border: 0
    }

    .navbar.navbar-expand-md .navbar-collapse .dropdown .dropdown-menu .card-body {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .navbar.navbar-expand-md .navbar-collapse .dropdown:not(.position-static) .dropdown-menu .card-body {
        padding: 1rem
    }

    .navbar.navbar-expand-md.navbar-fashion .navbar-collapse {
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: #2b2b2b
    }

    .navbar.navbar-expand-md.navbar-fashion .navbar-collapse::after {
        content: '';
        display: block;
        margin-top: .5rem
    }

    .navbar.navbar-expand-md.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card {
        background-color: transparent;
        color: #fff
    }

    .navbar.navbar-expand-md.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link {
        color: #ccc
    }

    .navbar.navbar-expand-md.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link.active {
        color: #fff
    }

    .navbar.navbar-expand-md.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link:focus,
    .navbar.navbar-expand-md.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link:hover {
        color: #fff
    }

    .navbar.navbar-expand-md.navbar-boxed .dropdown-collapse {
        top: 0
    }

    .navbar.navbar-expand-md.navbar-boxed .dropdown-collapse .card {
        box-shadow: inset 0 0 0 1px #e5e5e5 !important
    }
}

@media (min-width:768px) {
    .navbar:not(.navbar-vertical).navbar-expand-md .navbar-nav:first-child {
        margin-left: -1rem
    }

    .navbar:not(.navbar-vertical).navbar-expand-md .navbar-nav:last-child {
        margin-right: -1rem
    }
}

@media (min-width:768px) {
    .navbar-vertical.navbar-expand-md {
        width: 335px
    }

    .navbar-vertical.navbar-expand-md>.container-fluid,
    .navbar-vertical.navbar-expand-md>.container-lg,
    .navbar-vertical.navbar-expand-md>.container-md,
    .navbar-vertical.navbar-expand-md>.container-sm,
    .navbar-vertical.navbar-expand-md>.container-xl {
        flex-direction: column;
        align-items: flex-start
    }

    .navbar-vertical.navbar-expand-md>.container-fluid>*,
    .navbar-vertical.navbar-expand-md>.container-lg>*,
    .navbar-vertical.navbar-expand-md>.container-md>*,
    .navbar-vertical.navbar-expand-md>.container-sm>*,
    .navbar-vertical.navbar-expand-md>.container-xl>* {
        min-width: 100%
    }

    .navbar-vertical.navbar-expand-md .navbar-collapse {
        flex-direction: column
    }

    .navbar-vertical.navbar-expand-md .navbar-collapse>* {
        min-width: 100%
    }

    .navbar-vertical.navbar-expand-md .navbar-nav {
        flex-direction: column
    }

    .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
        padding-left: 0;
        padding-right: 0
    }

    .navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active::before {
        display: none
    }
}

@media (max-width:991px) {
    .navbar.navbar-expand-lg .navbar-collapse::before {
        content: '';
        display: block;
        margin-top: .5rem
    }

    .navbar.navbar-expand-lg .navbar-collapse .navbar-nav.flex-row>.nav-item+.nav-item {
        margin-left: 1rem
    }

    .navbar.navbar-expand-lg .navbar-collapse .navbar-nav>.nav-item>.nav-link.active::before {
        display: none
    }

    .navbar.navbar-expand-lg .navbar-collapse .nav-divided>.nav-item::before {
        display: none
    }

    .navbar.navbar-expand-lg .navbar-collapse .dropdown.position-static {
        position: relative !important
    }

    .navbar.navbar-expand-lg .navbar-collapse .dropdown-menu {
        min-width: 0 !important;
        border: 0;
        background-color: transparent
    }

    .navbar.navbar-expand-lg .navbar-collapse .dropdown-item {
        padding-left: .625rem;
        padding-right: .625rem;
        border: 0
    }

    .navbar.navbar-expand-lg .navbar-collapse .dropdown .dropdown-menu .card-body {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .navbar.navbar-expand-lg .navbar-collapse .dropdown:not(.position-static) .dropdown-menu .card-body {
        padding: 1rem
    }

    .navbar.navbar-expand-lg.navbar-fashion .navbar-collapse {
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: #2b2b2b
    }

    .navbar.navbar-expand-lg.navbar-fashion .navbar-collapse::after {
        content: '';
        display: block;
        margin-top: .5rem
    }

    .navbar.navbar-expand-lg.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card {
        background-color: transparent;
        color: #fff
    }

    .navbar.navbar-expand-lg.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link {
        color: #ccc
    }

    .navbar.navbar-expand-lg.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link.active {
        color: #fff
    }

    .navbar.navbar-expand-lg.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link:focus,
    .navbar.navbar-expand-lg.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link:hover {
        color: #fff
    }

    .navbar.navbar-expand-lg.navbar-boxed .dropdown-collapse {
        top: 0
    }

    .navbar.navbar-expand-lg.navbar-boxed .dropdown-collapse .card {
        box-shadow: inset 0 0 0 1px #e5e5e5 !important
    }
}

@media (min-width:992px) {
    .navbar:not(.navbar-vertical).navbar-expand-lg .navbar-nav:first-child {
        margin-left: -1rem
    }

    .navbar:not(.navbar-vertical).navbar-expand-lg .navbar-nav:last-child {
        margin-right: -1rem
    }
}

@media (min-width:992px) {
    .navbar-vertical.navbar-expand-lg {
        width: 335px
    }

    .navbar-vertical.navbar-expand-lg>.container-fluid,
    .navbar-vertical.navbar-expand-lg>.container-lg,
    .navbar-vertical.navbar-expand-lg>.container-md,
    .navbar-vertical.navbar-expand-lg>.container-sm,
    .navbar-vertical.navbar-expand-lg>.container-xl {
        flex-direction: column;
        align-items: flex-start
    }

    .navbar-vertical.navbar-expand-lg>.container-fluid>*,
    .navbar-vertical.navbar-expand-lg>.container-lg>*,
    .navbar-vertical.navbar-expand-lg>.container-md>*,
    .navbar-vertical.navbar-expand-lg>.container-sm>*,
    .navbar-vertical.navbar-expand-lg>.container-xl>* {
        min-width: 100%
    }

    .navbar-vertical.navbar-expand-lg .navbar-collapse {
        flex-direction: column
    }

    .navbar-vertical.navbar-expand-lg .navbar-collapse>* {
        min-width: 100%
    }

    .navbar-vertical.navbar-expand-lg .navbar-nav {
        flex-direction: column
    }

    .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 0;
        padding-right: 0
    }

    .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.active::before {
        display: none
    }
}

@media (max-width:1199px) {
    .navbar.navbar-expand-xl .navbar-collapse::before {
        content: '';
        display: block;
        margin-top: .5rem
    }

    .navbar.navbar-expand-xl .navbar-collapse .navbar-nav.flex-row>.nav-item+.nav-item {
        margin-left: 1rem
    }

    .navbar.navbar-expand-xl .navbar-collapse .navbar-nav>.nav-item>.nav-link.active::before {
        display: none
    }

    .navbar.navbar-expand-xl .navbar-collapse .nav-divided>.nav-item::before {
        display: none
    }

    .navbar.navbar-expand-xl .navbar-collapse .dropdown.position-static {
        position: relative !important
    }

    .navbar.navbar-expand-xl .navbar-collapse .dropdown-menu {
        min-width: 0 !important;
        border: 0;
        background-color: transparent
    }

    .navbar.navbar-expand-xl .navbar-collapse .dropdown-item {
        padding-left: .625rem;
        padding-right: .625rem;
        border: 0
    }

    .navbar.navbar-expand-xl .navbar-collapse .dropdown .dropdown-menu .card-body {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .navbar.navbar-expand-xl .navbar-collapse .dropdown:not(.position-static) .dropdown-menu .card-body {
        padding: 1rem
    }

    .navbar.navbar-expand-xl.navbar-fashion .navbar-collapse {
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: #2b2b2b
    }

    .navbar.navbar-expand-xl.navbar-fashion .navbar-collapse::after {
        content: '';
        display: block;
        margin-top: .5rem
    }

    .navbar.navbar-expand-xl.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card {
        background-color: transparent;
        color: #fff
    }

    .navbar.navbar-expand-xl.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link {
        color: #ccc
    }

    .navbar.navbar-expand-xl.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link.active {
        color: #fff
    }

    .navbar.navbar-expand-xl.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link:focus,
    .navbar.navbar-expand-xl.navbar-fashion.bg-dark .navbar-collapse .dropdown-menu .card .list-styled-link:hover {
        color: #fff
    }

    .navbar.navbar-expand-xl.navbar-boxed .dropdown-collapse {
        top: 0
    }

    .navbar.navbar-expand-xl.navbar-boxed .dropdown-collapse .card {
        box-shadow: inset 0 0 0 1px #e5e5e5 !important
    }
}

@media (min-width:1200px) {
    .navbar:not(.navbar-vertical).navbar-expand-xl .navbar-nav:first-child {
        margin-left: -1rem
    }

    .navbar:not(.navbar-vertical).navbar-expand-xl .navbar-nav:last-child {
        margin-right: -1rem
    }
}

@media (min-width:1200px) {
    .navbar-vertical.navbar-expand-xl {
        width: 335px
    }

    .navbar-vertical.navbar-expand-xl>.container-fluid,
    .navbar-vertical.navbar-expand-xl>.container-lg,
    .navbar-vertical.navbar-expand-xl>.container-md,
    .navbar-vertical.navbar-expand-xl>.container-sm,
    .navbar-vertical.navbar-expand-xl>.container-xl {
        flex-direction: column;
        align-items: flex-start
    }

    .navbar-vertical.navbar-expand-xl>.container-fluid>*,
    .navbar-vertical.navbar-expand-xl>.container-lg>*,
    .navbar-vertical.navbar-expand-xl>.container-md>*,
    .navbar-vertical.navbar-expand-xl>.container-sm>*,
    .navbar-vertical.navbar-expand-xl>.container-xl>* {
        min-width: 100%
    }

    .navbar-vertical.navbar-expand-xl .navbar-collapse {
        flex-direction: column
    }

    .navbar-vertical.navbar-expand-xl .navbar-collapse>* {
        min-width: 100%
    }

    .navbar-vertical.navbar-expand-xl .navbar-nav {
        flex-direction: column
    }

    .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link {
        padding-left: 0;
        padding-right: 0
    }

    .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link.active::before {
        display: none
    }
}

@media (min-width:1200px) {

    .navbar-nav .dropdown>.dropdown-menu,
    .navbar-nav .dropright>.dropdown-menu {
        display: block !important;
        visibility: hidden;
        transform-origin: top center;
        opacity: 0
    }

    .navbar-nav .dropdown>.dropdown-menu {
        left: 50%;
        transform: translate(-50%, .5rem)
    }

    .navbar-nav .dropright>.dropdown-menu {
        transform: translateY(.5rem)
    }

    .navbar-nav .hovered>.dropdown-menu {
        transition: all .2s ease-in-out;
        transition-property: visibility, transform, opacity
    }

    .navbar-nav .dropdown.show>.dropdown-menu,
    .navbar-nav .dropright.show>.dropdown-menu {
        visibility: visible;
        transform: translateY(0);
        opacity: 1
    }

    .navbar-nav .dropdown.show>.dropdown-menu {
        left: 50%;
        transform: translate(-50%, 0)
    }

    .navbar-nav .dropright.show>.dropdown-menu {
        transform: translateY(0)
    }
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .navbar.sticky-left {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        overflow-y: auto
    }

    .navbar.sticky-left .navbar-collapse:not(.show):not(.collapsing) {
        align-items: flex-start;
        height: 100vh
    }
}

.pagination {
    margin-bottom: 0;
    font-weight: 500
}

.pagination-sm {
    font-size: .9375rem
}

.pagination .page-link {
    border-width: 0 0 1px 0
}

.pagination .page-link-arrow {
    border-width: 0
}

a {
    transition: all .1s ease-in-out;
    transition-property: color
}

.table thead th {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    font-size: .9375rem;
    font-weight: 500;
    vertical-align: middle;
    border-width: 1px
}

.table tbody th {
    font-size: .9375rem;
    font-weight: 500
}

.table tbody td,
.table tbody th {
    transition: all .1s ease-in-out
}

.table-lg thead th {
    padding: 1.5rem 2rem
}

.table-lg tbody td {
    padding: 2rem
}

.table-sm thead th {
    padding: 1rem 1.25rem;
    font-size: .875rem
}

.table-sm tbody th {
    font-size: .875rem
}

.table-sm tbody td {
    font-size: .9375rem
}

@font-face {
    font-family: Jost;
    src: url(/fonts/Jost-400-Book.woff2) format("woff2"), url(/fonts/Jost-400-Book.woff) format("woff"), url(/fonts/Jost-400-Book.ttf) format("truetype")
}

@font-face {
    font-family: Jost;
    font-style: italic;
    src: url(/fonts/Jost-400-BookItalic.woff2) format("woff2"), url(/fonts/Jost-400-BookItalic.woff) format("woff"), url(/fonts/Jost-400-BookItalic.ttf) format("truetype")
}

@font-face {
    font-family: Jost;
    font-weight: 500;
    src: url(/fonts/Jost-500-Medium.woff2) format("woff2"), url(/fonts/Jost-500-Medium.woff) format("woff"), url(/fonts/Jost-500-Medium.ttf) format("truetype")
}

@font-face {
    font-family: Jost;
    font-weight: 600;
    src: url(/fonts/Jost-600-Semi.woff2) format("woff2"), url(/fonts/Jost-600-Semi.woff) format("woff"), url(/fonts/Jost-600-Semi.ttf) format("truetype")
}

@media (max-width:767.98px) {

    .h1,
    h1 {
        font-size: 2.25rem
    }

    .h2,
    h2 {
        font-size: 1.875rem
    }

    .h3,
    h3 {
        font-size: 1.75rem
    }

    .h4,
    h4 {
        font-size: 1.5rem
    }

    .h5,
    h5 {
        font-size: 1.375rem
    }

    .display-1 {
        font-size: 4.875rem
    }

    .display-4 {
        font-size: 2.75rem
    }
}

.heading-xxxs {
    font-size: .6875rem;
    font-weight: 500;
    letter-spacing: .02em;
    text-transform: uppercase
}

.heading-xxs {
    font-size: .8125rem;
    font-weight: 500;
    letter-spacing: .02em;
    text-transform: uppercase
}

small {
    font-weight: inherit
}

strong {
    font-weight: 500
}

.blockquote>p {
    font-style: italic
}

.blockquote>p::before {
    content: open-quote
}

.blockquote>p::after {
    content: close-quote
}

.blockquote-footer::before {
    display: none
}

.blockquote-2 {
    padding: 1.25rem 2rem;
    border-left: 3px solid #ff6f61
}

.blockquote-2>p {
    font-size: 1.25rem
}

.blockquote-2>p::before {
    content: open-quote
}

.blockquote-2>p::after {
    content: close-quote
}

.list-styled {
    list-style-type: none;
    padding-left: 0
}

.list-styled-item+.list-styled-item {
    margin-top: .75em
}

.list-styled-link {
    display: block;
    color: #525252
}

.list-styled-link:focus,
.list-styled-link:hover {
    color: #111
}

.list-styled-link.active {
    color: #111
}

.link-underline {
    position: relative;
    display: inline-block;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-weight: 500;
    color: #111
}

.link-underline::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    border-bottom: 1px solid;
    transform-origin: left center;
    transition: all .2s ease-in-out;
    transition-property: transform
}

.link-underline:hover {
    color: #111
}

.link-underline:hover::before {
    transform-origin: right center;
    transform: scaleX(0)
}

dl,
ol,
ul {
    margin-bottom: 1.5rem
}

.bg-hover {
    transition: all .2s ease-in-out
}

.bg-cover {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important
}

.bg-cover.bg-hover:hover {
    background-position: calc(50% - .75rem) center !important
}

.bg-w-100 {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: 100% auto !important
}

.bg-h-100 {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: auto 100% !important
}

.bg-left {
    background-position: center left !important
}

.bg-clip-content {
    background-clip: content-box !important
}

.bg-pattern {
    background-image: url(/img/patterns/pattern-1.svg) !important
}

.bg-white-90 {
    background-color: rgba(255, 255, 255, .9) !important
}

.bg-dark-0 {
    background-color: rgba(31, 31, 31, 0) !important
}

.bg-dark-0.bg-hover:hover {
    background-color: rgba(31, 31, 31, .1) !important
}

.bg-dark-5 {
    background-color: rgba(31, 31, 31, .05) !important
}

.bg-dark-5.bg-hover:hover {
    background-color: rgba(31, 31, 31, .15) !important
}

.bg-dark-10 {
    background-color: rgba(31, 31, 31, .1) !important
}

.bg-dark-20 {
    background-color: rgba(31, 31, 31, .2) !important
}

.bg-dark-40 {
    background-color: rgba(31, 31, 31, .4) !important
}

.bg-primary-soft {
    background-color: #fff1ef !important
}

.bg-secondary-soft {
    background-color: #f6f6f6 !important
}

.bg-success-soft {
    background-color: #eaf8f1 !important
}

.bg-info-soft {
    background-color: #ebf3ff !important
}

.bg-warning-soft {
    background-color: #fff5eb !important
}

.bg-danger-soft {
    background-color: #fff1ef !important
}

.bg-light-soft {
    background-color: #fefefe !important
}

.bg-dark-soft {
    background-color: #e9e9e9 !important
}

.bg-none {
    background: 0 0 !important
}

.border-xs {
    border: 1px solid #e5e5e5 !important
}

.border-top-xs {
    border-top: 1px solid #e5e5e5 !important
}

.border-right-xs {
    border-right: 1px solid #e5e5e5 !important
}

.border-bottom-xs {
    border-bottom: 1px solid #e5e5e5 !important
}

.border-left-xs {
    border-left: 1px solid #e5e5e5 !important
}

.border-xs-0 {
    border: 0 !important
}

.border-top-xs-0 {
    border-top: 0 !important
}

.border-right-xs-0 {
    border-right: 0 !important
}

.border-bottom-xs-0 {
    border-bottom: 0 !important
}

.border-left-xs-0 {
    border-left: 0 !important
}

@media (min-width:576px) {
    .border-sm {
        border: 1px solid #e5e5e5 !important
    }

    .border-top-sm {
        border-top: 1px solid #e5e5e5 !important
    }

    .border-right-sm {
        border-right: 1px solid #e5e5e5 !important
    }

    .border-bottom-sm {
        border-bottom: 1px solid #e5e5e5 !important
    }

    .border-left-sm {
        border-left: 1px solid #e5e5e5 !important
    }

    .border-sm-0 {
        border: 0 !important
    }

    .border-top-sm-0 {
        border-top: 0 !important
    }

    .border-right-sm-0 {
        border-right: 0 !important
    }

    .border-bottom-sm-0 {
        border-bottom: 0 !important
    }

    .border-left-sm-0 {
        border-left: 0 !important
    }
}

@media (min-width:768px) {
    .border-md {
        border: 1px solid #e5e5e5 !important
    }

    .border-top-md {
        border-top: 1px solid #e5e5e5 !important
    }

    .border-right-md {
        border-right: 1px solid #e5e5e5 !important
    }

    .border-bottom-md {
        border-bottom: 1px solid #e5e5e5 !important
    }

    .border-left-md {
        border-left: 1px solid #e5e5e5 !important
    }

    .border-md-0 {
        border: 0 !important
    }

    .border-top-md-0 {
        border-top: 0 !important
    }

    .border-right-md-0 {
        border-right: 0 !important
    }

    .border-bottom-md-0 {
        border-bottom: 0 !important
    }

    .border-left-md-0 {
        border-left: 0 !important
    }
}

@media (min-width:992px) {
    .border-lg {
        border: 1px solid #e5e5e5 !important
    }

    .border-top-lg {
        border-top: 1px solid #e5e5e5 !important
    }

    .border-right-lg {
        border-right: 1px solid #e5e5e5 !important
    }

    .border-bottom-lg {
        border-bottom: 1px solid #e5e5e5 !important
    }

    .border-left-lg {
        border-left: 1px solid #e5e5e5 !important
    }

    .border-lg-0 {
        border: 0 !important
    }

    .border-top-lg-0 {
        border-top: 0 !important
    }

    .border-right-lg-0 {
        border-right: 0 !important
    }

    .border-bottom-lg-0 {
        border-bottom: 0 !important
    }

    .border-left-lg-0 {
        border-left: 0 !important
    }
}

@media (min-width:1200px) {
    .border-xl {
        border: 1px solid #e5e5e5 !important
    }

    .border-top-xl {
        border-top: 1px solid #e5e5e5 !important
    }

    .border-right-xl {
        border-right: 1px solid #e5e5e5 !important
    }

    .border-bottom-xl {
        border-bottom: 1px solid #e5e5e5 !important
    }

    .border-left-xl {
        border-left: 1px solid #e5e5e5 !important
    }

    .border-xl-0 {
        border: 0 !important
    }

    .border-top-xl-0 {
        border-top: 0 !important
    }

    .border-right-xl-0 {
        border-right: 0 !important
    }

    .border-bottom-xl-0 {
        border-bottom: 0 !important
    }

    .border-left-xl-0 {
        border-left: 0 !important
    }
}

.border-gray-700 {
    border-color: #303030 !important
}

.border-2 {
    border-width: 2px !important
}

.opacity-10 {
    opacity: .1
}

.opacity-20 {
    opacity: .2
}

.opacity-30 {
    opacity: .3
}

.opacity-40 {
    opacity: .4
}

.opacity-50 {
    opacity: .5
}

.opacity-60 {
    opacity: .6
}

.opacity-70 {
    opacity: .7
}

.opacity-80 {
    opacity: .8
}

.opacity-90 {
    opacity: .9
}

.opacity-100 {
    opacity: 1
}

.font-size-lg {
    font-size: 1.125rem !important
}

.font-size-base {
    font-size: 1rem !important
}

.font-size-sm {
    font-size: .9375rem !important
}

.font-size-xs {
    font-size: .875rem !important
}

.font-size-xxs {
    font-size: .8125rem !important
}

.font-size-xxxs {
    font-size: .6875rem !important
}

.font-size-h1 {
    font-size: 2.75rem !important
}

.font-size-h2 {
    font-size: 2.25rem !important
}

.font-size-h3 {
    font-size: 2rem !important
}

.font-size-h4 {
    font-size: 1.75rem !important
}

.font-size-h5 {
    font-size: 1.5rem !important
}

.font-size-h6 {
    font-size: 1.25rem !important
}

@media (max-width:767.98px) {
    .font-size-h1 {
        font-size: 2.25rem !important
    }

    .font-size-h2 {
        font-size: 1.875rem !important
    }

    .font-size-h3 {
        font-size: 1.75rem !important
    }

    .font-size-h4 {
        font-size: 1.5rem !important
    }

    .font-size-h5 {
        font-size: 1.375rem !important
    }
}

.letter-spacing-lg {
    letter-spacing: .02em !important
}

.letter-spacing-xl {
    letter-spacing: .05em !important
}

.text-gray-300 {
    color: #bababa !important
}

.text-gray-300[href]:hover {
    color: #a6a6a6 !important
}

.text-gray-350 {
    color: #a6a6a6 !important
}

.text-gray-350[href]:hover {
    color: #909090 !important
}

.text-gray-400 {
    color: #909090 !important
}

.text-gray-400[href]:hover {
    color: #767676 !important
}

.text-gray-450 {
    color: #767676 !important
}

.text-gray-450[href]:hover {
    color: #525252 !important
}

.text-gray-500 {
    color: #525252 !important
}

.text-gray-500[href]:hover {
    color: #3e3e3e !important
}

.text-body[href]:focus,
.text-body[href]:hover {
    color: #303030 !important
}

.text-muted[href]:focus,
.text-muted[href]:hover {
    color: #525252 !important
}

.text-decoration-underline {
    text-decoration: underline !important
}

a.text-decoration-underline:focus,
a.text-decoration-underline:hover {
    text-decoration: none !important
}

.text-decoration-line-through {
    text-decoration: line-through !important
}

.font-style-normal {
    font-style: normal !important
}

.line-height-base {
    line-height: 1.5 !important
}

.line-height-fixed {
    line-height: 1.5rem !important
}

.minw-25 {
    min-width: 25% !important
}

.minh-25 {
    min-height: 25% !important
}

.minw-50 {
    min-width: 50% !important
}

.minh-50 {
    min-height: 50% !important
}

.minw-75 {
    min-width: 75% !important
}

.minh-75 {
    min-height: 75% !important
}

.minw-100 {
    min-width: 100% !important
}

.minh-100 {
    min-height: 100% !important
}

.minw-auto {
    min-width: auto !important
}

.minh-auto {
    min-height: auto !important
}

.minw-0 {
    min-width: 0 !important
}

.minh-0 {
    min-height: 0 !important
}

.minw-125 {
    min-width: 125% !important
}

.minh-125 {
    min-height: 125% !important
}

.minw-150 {
    min-width: 150% !important
}

.minh-150 {
    min-height: 150% !important
}

.minw-175 {
    min-width: 175% !important
}

.minh-175 {
    min-height: 175% !important
}

.minw-200 {
    min-width: 200% !important
}

.minh-200 {
    min-height: 200% !important
}

@media (min-width:576px) {
    .w-sm-25 {
        width: 25% !important
    }

    .h-sm-25 {
        height: 25% !important
    }

    .minw-sm-25 {
        min-width: 25% !important
    }

    .minh-sm-25 {
        min-height: 25% !important
    }

    .w-sm-50 {
        width: 50% !important
    }

    .h-sm-50 {
        height: 50% !important
    }

    .minw-sm-50 {
        min-width: 50% !important
    }

    .minh-sm-50 {
        min-height: 50% !important
    }

    .w-sm-75 {
        width: 75% !important
    }

    .h-sm-75 {
        height: 75% !important
    }

    .minw-sm-75 {
        min-width: 75% !important
    }

    .minh-sm-75 {
        min-height: 75% !important
    }

    .w-sm-100 {
        width: 100% !important
    }

    .h-sm-100 {
        height: 100% !important
    }

    .minw-sm-100 {
        min-width: 100% !important
    }

    .minh-sm-100 {
        min-height: 100% !important
    }

    .w-sm-auto {
        width: auto !important
    }

    .h-sm-auto {
        height: auto !important
    }

    .minw-sm-auto {
        min-width: auto !important
    }

    .minh-sm-auto {
        min-height: auto !important
    }

    .w-sm-0 {
        width: 0 !important
    }

    .h-sm-0 {
        height: 0 !important
    }

    .minw-sm-0 {
        min-width: 0 !important
    }

    .minh-sm-0 {
        min-height: 0 !important
    }

    .w-sm-125 {
        width: 125% !important
    }

    .h-sm-125 {
        height: 125% !important
    }

    .minw-sm-125 {
        min-width: 125% !important
    }

    .minh-sm-125 {
        min-height: 125% !important
    }

    .w-sm-150 {
        width: 150% !important
    }

    .h-sm-150 {
        height: 150% !important
    }

    .minw-sm-150 {
        min-width: 150% !important
    }

    .minh-sm-150 {
        min-height: 150% !important
    }

    .w-sm-175 {
        width: 175% !important
    }

    .h-sm-175 {
        height: 175% !important
    }

    .minw-sm-175 {
        min-width: 175% !important
    }

    .minh-sm-175 {
        min-height: 175% !important
    }

    .w-sm-200 {
        width: 200% !important
    }

    .h-sm-200 {
        height: 200% !important
    }

    .minw-sm-200 {
        min-width: 200% !important
    }

    .minh-sm-200 {
        min-height: 200% !important
    }
}

@media (min-width:768px) {
    .w-md-25 {
        width: 25% !important
    }

    .h-md-25 {
        height: 25% !important
    }

    .minw-md-25 {
        min-width: 25% !important
    }

    .minh-md-25 {
        min-height: 25% !important
    }

    .w-md-50 {
        width: 50% !important
    }

    .h-md-50 {
        height: 50% !important
    }

    .minw-md-50 {
        min-width: 50% !important
    }

    .minh-md-50 {
        min-height: 50% !important
    }

    .w-md-75 {
        width: 75% !important
    }

    .h-md-75 {
        height: 75% !important
    }

    .minw-md-75 {
        min-width: 75% !important
    }

    .minh-md-75 {
        min-height: 75% !important
    }

    .w-md-100 {
        width: 100% !important
    }

    .h-md-100 {
        height: 100% !important
    }

    .minw-md-100 {
        min-width: 100% !important
    }

    .minh-md-100 {
        min-height: 100% !important
    }

    .w-md-auto {
        width: auto !important
    }

    .h-md-auto {
        height: auto !important
    }

    .minw-md-auto {
        min-width: auto !important
    }

    .minh-md-auto {
        min-height: auto !important
    }

    .w-md-0 {
        width: 0 !important
    }

    .h-md-0 {
        height: 0 !important
    }

    .minw-md-0 {
        min-width: 0 !important
    }

    .minh-md-0 {
        min-height: 0 !important
    }

    .w-md-125 {
        width: 125% !important
    }

    .h-md-125 {
        height: 125% !important
    }

    .minw-md-125 {
        min-width: 125% !important
    }

    .minh-md-125 {
        min-height: 125% !important
    }

    .w-md-150 {
        width: 150% !important
    }

    .h-md-150 {
        height: 150% !important
    }

    .minw-md-150 {
        min-width: 150% !important
    }

    .minh-md-150 {
        min-height: 150% !important
    }

    .w-md-175 {
        width: 175% !important
    }

    .h-md-175 {
        height: 175% !important
    }

    .minw-md-175 {
        min-width: 175% !important
    }

    .minh-md-175 {
        min-height: 175% !important
    }

    .w-md-200 {
        width: 200% !important
    }

    .h-md-200 {
        height: 200% !important
    }

    .minw-md-200 {
        min-width: 200% !important
    }

    .minh-md-200 {
        min-height: 200% !important
    }
}

@media (min-width:992px) {
    .w-lg-25 {
        width: 25% !important
    }

    .h-lg-25 {
        height: 25% !important
    }

    .minw-lg-25 {
        min-width: 25% !important
    }

    .minh-lg-25 {
        min-height: 25% !important
    }

    .w-lg-50 {
        width: 50% !important
    }

    .h-lg-50 {
        height: 50% !important
    }

    .minw-lg-50 {
        min-width: 50% !important
    }

    .minh-lg-50 {
        min-height: 50% !important
    }

    .w-lg-75 {
        width: 75% !important
    }

    .h-lg-75 {
        height: 75% !important
    }

    .minw-lg-75 {
        min-width: 75% !important
    }

    .minh-lg-75 {
        min-height: 75% !important
    }

    .w-lg-100 {
        width: 100% !important
    }

    .h-lg-100 {
        height: 100% !important
    }

    .minw-lg-100 {
        min-width: 100% !important
    }

    .minh-lg-100 {
        min-height: 100% !important
    }

    .w-lg-auto {
        width: auto !important
    }

    .h-lg-auto {
        height: auto !important
    }

    .minw-lg-auto {
        min-width: auto !important
    }

    .minh-lg-auto {
        min-height: auto !important
    }

    .w-lg-0 {
        width: 0 !important
    }

    .h-lg-0 {
        height: 0 !important
    }

    .minw-lg-0 {
        min-width: 0 !important
    }

    .minh-lg-0 {
        min-height: 0 !important
    }

    .w-lg-125 {
        width: 125% !important
    }

    .h-lg-125 {
        height: 125% !important
    }

    .minw-lg-125 {
        min-width: 125% !important
    }

    .minh-lg-125 {
        min-height: 125% !important
    }

    .w-lg-150 {
        width: 150% !important
    }

    .h-lg-150 {
        height: 150% !important
    }

    .minw-lg-150 {
        min-width: 150% !important
    }

    .minh-lg-150 {
        min-height: 150% !important
    }

    .w-lg-175 {
        width: 175% !important
    }

    .h-lg-175 {
        height: 175% !important
    }

    .minw-lg-175 {
        min-width: 175% !important
    }

    .minh-lg-175 {
        min-height: 175% !important
    }

    .w-lg-200 {
        width: 200% !important
    }

    .h-lg-200 {
        height: 200% !important
    }

    .minw-lg-200 {
        min-width: 200% !important
    }

    .minh-lg-200 {
        min-height: 200% !important
    }
}

@media (min-width:1200px) {
    .w-xl-25 {
        width: 25% !important
    }

    .h-xl-25 {
        height: 25% !important
    }

    .minw-xl-25 {
        min-width: 25% !important
    }

    .minh-xl-25 {
        min-height: 25% !important
    }

    .w-xl-50 {
        width: 50% !important
    }

    .h-xl-50 {
        height: 50% !important
    }

    .minw-xl-50 {
        min-width: 50% !important
    }

    .minh-xl-50 {
        min-height: 50% !important
    }

    .w-xl-75 {
        width: 75% !important
    }

    .h-xl-75 {
        height: 75% !important
    }

    .minw-xl-75 {
        min-width: 75% !important
    }

    .minh-xl-75 {
        min-height: 75% !important
    }

    .w-xl-100 {
        width: 100% !important
    }

    .h-xl-100 {
        height: 100% !important
    }

    .minw-xl-100 {
        min-width: 100% !important
    }

    .minh-xl-100 {
        min-height: 100% !important
    }

    .w-xl-auto {
        width: auto !important
    }

    .h-xl-auto {
        height: auto !important
    }

    .minw-xl-auto {
        min-width: auto !important
    }

    .minh-xl-auto {
        min-height: auto !important
    }

    .w-xl-0 {
        width: 0 !important
    }

    .h-xl-0 {
        height: 0 !important
    }

    .minw-xl-0 {
        min-width: 0 !important
    }

    .minh-xl-0 {
        min-height: 0 !important
    }

    .w-xl-125 {
        width: 125% !important
    }

    .h-xl-125 {
        height: 125% !important
    }

    .minw-xl-125 {
        min-width: 125% !important
    }

    .minh-xl-125 {
        min-height: 125% !important
    }

    .w-xl-150 {
        width: 150% !important
    }

    .h-xl-150 {
        height: 150% !important
    }

    .minw-xl-150 {
        min-width: 150% !important
    }

    .minh-xl-150 {
        min-height: 150% !important
    }

    .w-xl-175 {
        width: 175% !important
    }

    .h-xl-175 {
        height: 175% !important
    }

    .minw-xl-175 {
        min-width: 175% !important
    }

    .minh-xl-175 {
        min-height: 175% !important
    }

    .w-xl-200 {
        width: 200% !important
    }

    .h-xl-200 {
        height: 200% !important
    }

    .minw-xl-200 {
        min-width: 200% !important
    }

    .minh-xl-200 {
        min-height: 200% !important
    }
}

.vw-50 {
    width: 50vw !important
}

.embed-responsive-item-text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center
}

.shadow-border {
    box-shadow: 0 0 0 1px #e5e5e5 !important
}

.shadow-border-inset {
    box-shadow: inset 0 0 0 1px #e5e5e5 !important
}

.shadow-hover {
    transition: all .2s ease-in-out;
    transition-property: box-shadow
}

.shadow-hover:hover {
    box-shadow: 0 .25rem .25rem rgba(17, 17, 17, .02), 0 .75rem 2.25rem rgba(17, 17, 17, .04) !important
}

.lift {
    transition: all .2s ease-in-out;
    transition-property: transform, box-shadow
}

.lift:focus,
.lift:hover {
    transform: translateY(-.25rem)
}

.video-512 {
    -o-object-fit: cover;
    object-fit: cover
}

@supports ((-o-object-fit:cover) or (object-fit:cover)) and (not (-ms-ime-align:auto)) {
    .video-512 {
        width: 100%;
        height: 512px
    }
}

.cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.zindex-1 {
    z-index: 1 !important
}

.zindex-fixed {
    z-index: 1030 !important
}

.footer-payment {
    height: 1.5rem;
    width: auto
}

.rating {
    display: inline-flex;
    margin-bottom: 0
}

.rating:not([data-value]) .rating-item {
    color: #ccc
}

.rating[data-value="1"] .rating-item:nth-child(n+2) {
    color: #ccc
}

.rating[data-value="2"] .rating-item:nth-child(n+3) {
    color: #ccc
}

.rating[data-value="3"] .rating-item:nth-child(n+4) {
    color: #ccc
}

.rating[data-value="4"] .rating-item:nth-child(n+5) {
    color: #ccc
}

.rating[data-value="5"] .rating-item:nth-child(n+6) {
    color: #ccc
}

.rating-form {
    position: relative;
    display: inline-flex
}

.rating-input {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0
}

.brand {
    display: block;
    padding: 2rem 2rem;
    background-color: #fff;
    box-shadow: 0 .25rem .25rem rgba(17, 17, 17, .02)
}

.brand-img {
    max-height: 1.5rem;
    max-width: 100%;
    width: auto
}

.line {
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    display: block;
    width: 200px;
    margin-left: 2rem;
    margin-right: 2rem;
    border-top: 1px solid;
    color: #ff6f61
}

.line::after {
    content: "";
    position: inherit;
    top: inherit;
    transform: inherit;
    width: 11px;
    height: 11px;
    background-color: currentColor;
    border-radius: 50%
}

.line-left {
    right: 100%
}

.line-left::after {
    left: 0
}

.line-right {
    left: 100%
}

.line-right::after {
    right: 0
}

.avatar {
    position: relative;
    display: inline-block;
    width: 1.875rem;
    height: 1.875rem;
    font-size: .625rem
}

.avatar:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-image: url(/img/masks/avatar-status.svg), url(/img/masks/avatar-group.svg), url(/img/masks/avatar-group-hover.svg), url(/img/masks/avatar-group-hover-last.svg)
}

.avatar-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.avatar-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    color: #ccc
}

.avatar-offline::before,
.avatar-online::before {
    content: '';
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 20%;
    height: 20%;
    border-radius: 50%
}

.avatar-offline .avatar-img,
.avatar-online .avatar-img {
    -webkit-mask-image: url(/img/masks/avatar-status.svg);
    mask-image: url(/img/masks/avatar-status.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%
}

.avatar-online::before {
    background-color: #28bb74
}

.avatar-offline::before {
    background-color: #909090
}

.avatar-xs {
    width: 1rem;
    height: 1rem;
    font-size: .33333rem
}

.avatar-sm {
    width: 1.5rem;
    height: 1.5rem;
    font-size: .5rem
}

.avatar-lg {
    width: 2.25rem;
    height: 2.25rem;
    font-size: .75rem
}

.avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 1.33333rem
}

.avatar-xxl {
    width: 4rem;
    height: 4rem;
    font-size: 1.33333rem
}

@media (min-width:768px) {
    .avatar-xxl {
        width: 6.25rem;
        height: 6.25rem;
        font-size: 2.08333rem
    }
}

.avatar.avatar-4by3 {
    width: 2.5rem
}

.avatar-xs.avatar-4by3 {
    width: 1.33333rem
}

.avatar-sm.avatar-4by3 {
    width: 2rem
}

.avatar-lg.avatar-4by3 {
    width: 3rem
}

.avatar-xl.avatar-4by3 {
    width: 5.33333rem
}

.avatar-xxl.avatar-4by3 {
    width: 8.33333rem
}

.avatar-group {
    display: inline-flex
}

.avatar-group .avatar+.avatar {
    margin-left: -.46875rem
}

.avatar-group .avatar-xs+.avatar-xs {
    margin-left: -.25rem
}

.avatar-group .avatar-sm+.avatar-sm {
    margin-left: -.375rem
}

.avatar-group .avatar-lg+.avatar-lg {
    margin-left: -.5625rem
}

.avatar-group .avatar-xl+.avatar-xl {
    margin-left: -1rem
}

.avatar-group .avatar-xxl+.avatar-xxl {
    margin-left: -1.5625rem
}

.avatar-group .avatar:not(:last-child) {
    -webkit-mask-image: url(/img/masks/avatar-group.svg);
    mask-image: url(/img/masks/avatar-group.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%
}

.avatar-group .avatar:hover {
    -webkit-mask-image: none;
    mask-image: none;
    z-index: 1
}

.avatar-group .avatar:hover+.avatar {
    -webkit-mask-image: url(/img/masks/avatar-group-hover.svg);
    mask-image: url(/img/masks/avatar-group-hover.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%
}

.avatar-group .avatar:hover+.avatar:last-child {
    -webkit-mask-image: url(/img/masks/avatar-group-hover-last.svg);
    mask-image: url(/img/masks/avatar-group-hover-last.svg)
}

.rate-item {
    color: #111;
    white-space: nowrap
}

.rate-item:hover {
    color: #303030
}

.rate-item::after {
    content: attr(data-count)
}

.rate-item+.rate-item {
    margin-left: 1rem
}

.review {
    position: relative;
    padding-left: 2.5rem
}

.review:not(.review-child) {
    padding-right: 2.5rem;
    border: 1px solid #e5e5e5
}

.review-child {
    border-top: 1px solid #e5e5e5
}

.review-child::before {
    content: "\e946";
    position: absolute;
    top: 4.5rem;
    left: 0;
    font-family: Feather;
    font-size: 1.125rem;
    transform: translateY(-50%)
}

@media (min-width:768px) {
    .review-child::before {
        top: 5.625rem
    }
}

.review+.review {
    margin-top: 1.25rem
}

.review-body {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
}

.flickity-viewport {
    cursor: auto !important
}

.flickity::after {
    display: none;
    content: 'flickity'
}

.flickity-none::after {
    display: none;
    content: ''
}

@media (min-width:576px) {
    .flickity-sm::after {
        content: 'flickity'
    }

    .flickity-sm-none::after {
        content: ''
    }
}

@media (min-width:768px) {
    .flickity-md::after {
        content: 'flickity'
    }

    .flickity-md-none::after {
        content: ''
    }
}

@media (min-width:992px) {
    .flickity-lg::after {
        content: 'flickity'
    }

    .flickity-lg-none::after {
        content: ''
    }
}

@media (min-width:1200px) {
    .flickity-xl::after {
        content: 'flickity'
    }

    .flickity-xl-none::after {
        content: ''
    }
}

.flickity-page-dots {
    position: relative;
    bottom: 0;
    margin-top: 3rem
}

.flickity-page-dots .dot {
    width: .5rem;
    height: .5rem;
    margin-left: 3px;
    margin-right: 3px;
    background-color: #d8d8d8;
    opacity: 1;
    transition: all .1s ease-in-out
}

.flickity-page-dots .dot:hover {
    background-color: #ccc
}

.flickity-page-dots .dot.is-selected {
    background-color: #ff6f61
}

.flickity-page-dots-progress .flickity-page-dots {
    display: flex;
    width: 100%;
    max-width: 570px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto
}

.flickity-page-dots-progress .flickity-page-dots .dot {
    flex: 1;
    height: .25rem;
    margin: 0;
    border-radius: 0;
    background-color: #ff6f61
}

.flickity-page-dots-progress .flickity-page-dots .dot.is-selected~.dot {
    background-color: #e5e5e5
}

.flickity-page-dots-inner .flickity-page-dots {
    position: absolute;
    bottom: 1.5rem
}

.flickity-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 0;
    background-color: #fff;
    z-index: 1;
    transition: all .1s ease-in-out
}

.flickity-button:active,
.flickity-button:focus,
.flickity-button:hover {
    color: #a6a6a6;
    box-shadow: none;
    opacity: 1
}

@media (max-width:991.98px) {
    .flickity-button {
        display: none
    }
}

.flickity-button::before {
    font-family: Feather;
    font-size: 1.125rem;
    line-height: 1
}

.flickity-button>svg {
    display: none
}

.flickity-button.previous {
    left: 0
}

.flickity-button.previous::before {
    content: "\e92f"
}

.flickity-button.next {
    right: 0
}

.flickity-button.next::before {
    content: "\e930"
}

.flickity-buttons-lg .flickity-button {
    background-color: transparent;
    color: #bababa
}

.flickity-buttons-lg .flickity-button:hover {
    background-color: transparent;
    color: #111
}

.flickity-buttons-lg .flickity-button::before {
    font-size: 2rem
}

.flickity-buttons-offset .flickity-button {
    transform: translateY(-125%)
}

.flickity-buttons-bottom-left .flickity-button {
    top: auto;
    bottom: 0;
    transform: none
}

.flickity-buttons-bottom-left .flickity-button.previous {
    left: 0
}

.flickity-buttons-bottom-left .flickity-button.next {
    left: 42px
}

.flickity-nav .flickity-slider>* {
    opacity: .8
}

.flickity-nav .is-nav-selected {
    opacity: 1
}

.flickity-nav .is-nav-selected::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    padding: inherit;
    background-color: #111;
    background-clip: content-box
}

.row.no-gutters>.flickity-viewport>.flickity-slider>[class*=col] {
    padding-left: 0;
    padding-right: 0
}

.flickity-vertical {
    height: 100% !important
}

.flickity-vertical .flickity-viewport {
    position: relative !important;
    height: 100% !important
}

.flickity-vertical .flickity-slider {
    position: relative;
    transform: none !important
}

.flickity-vertical .flickity-slider>* {
    position: relative !important;
    left: 0 !important
}

.flickity-light .flickity-button:active,
.flickity-light .flickity-button:focus,
.flickity-light .flickity-button:hover {
    color: #fff
}

.fancybox-button {
    background-color: #111
}

.fancybox-bg {
    background-color: #111;
    transition: opacity .2s ease-in-out;
    transition-property: opacity
}

.fancybox-is-open .fancybox-bg {
    opacity: .9
}

.fe {
    display: inline-block;
    vertical-align: -.1em
}

[data-simplebar] {
    overflow-y: auto
}

.simplebar-track.simplebar-vertical {
    width: 2px;
    background-color: #ddd
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar {
    right: 0;
    width: inherit
}

.simplebar-scrollbar {
    background-color: #1f1f1f
}