.banner-lgpd-consent-container {
    position: fixed;
    left: 0;
    bottom: 10px;
    display: flex;
    width: 100vw;
    justify-content: center;
    z-index: 29999999;
}

.banner-lgpd-consent {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 0;
    width: calc(100vw - 20px);
    max-width: 1326px;
    height: 70px;
    padding: 27px;
    background-color: rgba(64,64,64,.9);
    box-shadow: 0 8px 6px 0 rgba(0,0,0,.41);
    border-radius: 5px;
    overflow: hidden;
    font-family: Arial,Helvetica,sans-serif;
    text-align: center;
}

.banner-lgpd-consent--show {
    animation: show 1s both;
}

.banner-lgpd-consent__column {
    padding: 0 54px;
    margin: 0 -30px;
    color: #fff !important;
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 16px;
    display: block !important;
}

.banner-lgpd-consent__link {
    color: inherit;
    text-decoration: underline;
}

.banner-lgpd-consent__accept {
    position: relative;
    display: block;
    width: 118px;
    height: 36px;
    line-height: 34px;
    background: #e9b425;
    border-radius: 1px;
    cursor: pointer;
    color: #000 !important;
    border: none;
    box-shadow: inset 0 -2px 0 0 rgba(0,0,0,.4);
    transition: all .3s;
}