.pargan{

    .navbar-customer-finder{
        color:white;
    }

    .navbar-main,
    #navbar-main{
        background-color: rgb(31, 31, 31);
    }

    #navbar-fashion{
        background-color:#8a0505;
    }

    .navbar-categories{
        background-color: #8a0505 !important;
    }

    .navbar-fashion .navbar-form .btn, .navbar-fashion .navbar-form .form-control {
        height: 4rem;
        background-color:#8a0505;
        color: white!important;
        border-top:1px solid #1f1f1f!important;
        border-bottom:1px solid #1f1f1f!important;
        border: 0;
    }
    .navbar-fashion .navbar-form .btn, .navbar-fashion .navbar-form .form-control::placeholder{
        color: white!important;
    }

    .navbar-fashion .navbar-form .btn:focus, .navbar-fashion .navbar-form .btn:hover {
        background-color: #eee;
    }

    .navbar-fashion.navbar-dark .nav-link:hover{
        color:#ff6f61;
    }

    .navbar-fashion.navbar-dark .nav-link:focus{
        color:#f83c2b;
    }

    #navbar-main .nav-link{
        color: white !important;
    }

    ul.navbar-nav .nav-link{
        color: white !important;
    }

    section.home__section:nth-child(even) {
        background-color: black;
    }

    section.home__section:nth-child(even) .card,
    section.home__section:nth-child(even) .card-body,
    section.home__section:nth-child(even) .card-footer{
        background-color: black !important;
    }

    section.home__section:nth-child(even) h2,
    section.home__section:nth-child(even) .text-body,
    section.home__section:nth-child(even) .link-underline{
        color: white !important;
    }
}

/*! CSS Used from: https://lanamaria.justisecure.com.br/static/css/main.5da88c2c.chunk.css */

@media only screen and (max-width:280px){
.item-product-line-modal img{height:200px!important;}
}

@media only screen and (max-width:480px){
.item-product-line-modal img{height:200px!important;}
.scroll-itens-product{display:none!important;}
}

@media only screen and (max-width: 990px){
.item-product-line-modal img{height:6.5rem;}
.scroll-itens-product{padding:0 0 5px 25px !important; width:max-content;}
 }

@media only screen and (min-width: 989px) and (max-width:1200px){
.scroll-itens-product{padding:0 0 5px 30px !important; width:max-content;}
.item-product-line-modal img{height:7.5rem;}
}

@media only screen and (min-width:1200px) and (max-width:1366px){
.scroll-itens-product{padding:0 0 0.6rem 1.5rem}
}

@media only screen and (min-width:1366px){
.scroll-itens-product{padding:0 0 10px 4.5vw}
}


.scroll-itens-product{cursor:pointer;float:left;overflow-y:auto;height:auto;width:100%; align-items:center;display:flex;}
.item-product-line-modal {color:#fff;font-size:30px;-webkit-transform-origin:top;transform-origin:top;-webkit-transform:scaleY(1);transform:scaleY(1);-webkit-transform-style:flat;transform-style:flat; }
.item-product-line-modal img{max-height: 10rem; margin-bottom: 0.2rem;}
.item-product-line-modal:hover{cursor:pointer;}
.group-pallete-color-img{display:block;}
.ball-color-pallete:hover{cursor:pointer;transition:all 2.5s;border-radius:50%;height:30px;width:30px;}

.dot {
    cursor: pointer;
    text-align: center;
    height: 0.55rem;
    width: 0.15rem;
    font-size: 2px;
    margin-left: 15px;
    border-radius: 50%;
    border: 0.3px solid #fda69d;
    background-color: #fda69d!important;
    box-shadow: 0 0.2px 0.2px mistyrose;
    
}

.dot:disabled{
    background-color: #ff6f61!important;
}

.dot-group {
    margin: 15px 3px 2px;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    //box-sizing: border-box;
}

    //display:flex;
    // flex-direction: row;
    //justify-content: center;
    //align-items: center;

