.skenn{
    .navbar-main,
    #navbar-main{
        background-color: rgb(255 236 233);
    }

    #navbar-fashion{
        background-color:#1f1f1f;
    }

    .navbar-categories{
        background-color: #1f1f1f!important;;
    }

    .navbar-fashion .navbar-form .btn, .navbar-fashion .navbar-form .form-control {
        height: 4rem;
        background-color:white;
        color: #1f1f1f!important;
        border-top:1px solid #1f1f1f!important;
        border-bottom:1px solid #1f1f1f!important;
        border: 0;
    }
    .navbar-fashion .navbar-form .btn, .navbar-fashion .navbar-form .form-control::placeholder{
        color: #5f5f5f!important;
    }

    .navbar-fashion .navbar-form .btn:focus, .navbar-fashion .navbar-form .btn:hover {
        background-color: #eee;
    }

    .navbar-fashion.navbar-dark .nav-link:hover{
        color:#ff6f61;
    }

    .navbar-fashion.navbar-dark .nav-link:focus{
        color:#f83c2b;
    }

    #navbar-main .nav-link{
        color: black !important;
    }
    
    ul.navbar-nav .nav-link{
        color: white !important;
    }
}